import axiosInstance, { SERVER } from "../axiosInstance";
import { prefix } from "./prefixsVariables";

export const feedbackAPI = {
  getFeedback: async (id = null) => {
    return await axiosInstance.get(`${prefix.feedback}/getFeedback${id ? `/${id}` : ""}`);
  },
  getStatistics: async () => {
    return await axiosInstance.get(`${prefix.feedback}/getStatistics`);
  },
  getClientMessages: async (id = null) => {
    return await axiosInstance.get(`${prefix.feedback}/get_client_messages${id ? `/${id}` : ""}`);
  },
};

export const ecommRoutes = {
  category: {
    CREATE: "create",
    UPDATE: "update",
    GET_ALL: "get",
    DELETE: "delete",
    GET_BY_ID: "by_id",
    CHANGE_STATUS: "change_status",
    SEARCH: "search",
    CHANGE_PARENT: "change_parent",
    DELETE_IMAGE: "delete_image",
    SET_PRIMARY_IMAGE: "set_primary_image",
  },
  product: {
    CREATE: "create",
    UPDATE: "update",
    GET_ALL: "get",
    GET_BY_ID: "by_id",
    DELETE_IMAGE: "delete_image",
    CHANGE_STATUS: "change_status",
    SEARCH: "search",
    CHANGE_PARENT: "change_parent",
    DELETE: "delete",
    SET_PRIMARY_IMAGE: "set_primary_image",
  },
  offer: {
    CREATE: "create",
    GET: "get",
    DELETE: "delete",
  },
};

import React from "react";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import Divider from "@mui/material/Divider";
import PropTypes from "prop-types";

const FeedbackResponseList = ({ responses }) => (
  <MDBox>
    {responses?.map((x, index) => (
      <MDBox key={index} display={"flex"} flexDirection={"column"} width="100%">
        <MDBox display={"flex"} justifyContent={"space-between"}>
          <MDTypography variant={"button"} textTransform={"capitalize"}>
            {x.with_question.name}
          </MDTypography>
          <MDTypography variant={"h6"} fontWeight={"medium"}>
            {x.response}
          </MDTypography>
        </MDBox>
        <Divider sx={{ m: 1 }} />
      </MDBox>
    ))}
  </MDBox>
);

FeedbackResponseList.defaultProps = {
  responses: [],
};

FeedbackResponseList.propTypes = {
  responses: PropTypes.array.isRequired,
};

export default FeedbackResponseList;

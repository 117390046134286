import React from "react";
import SideCard from "../../../components/SideCard";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import MDBox from "../../../components/MDBox";
import PropTypes from "prop-types";
import { deleteItems } from "../utils/apiHelpers";
import { utils } from "../../../assets/assetsFunctions";
import { useNavigate } from "react-router-dom";
import AlertDialog from "../../../components/dialog/dialog";
import { Icon } from "@mui/material";

const DeleteItem = ({ id, itemType }) => {
  const navigate = useNavigate();
  async function onDelete() {
    const response = await deleteItems(id, itemType);
    if (utils.checkApiResponse(response)) {
      navigate(`/${itemType}/view`);
    }
  }
  return (
    <SideCard>
      <MDTypography variant="h6" color="dark" fontWeight="bold">
        Danger zone
      </MDTypography>
      <MDBox display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <MDTypography color={"text"} variant={"caption"} fontWeight={"light"}>
          Delete for ever
        </MDTypography>

        <AlertDialog
          onAgree={onDelete}
          triggerText={"Delete"}
          title={"This action is irreversible"}
          disagreeText={"Cancel"}
          content={`Do you want to permanently delete this item ?`}
          agreeText={"delete"}
          icon={
            <MDButton variant={"outlined"} size={"large"} color={"error"}>
              delete
            </MDButton>
          }
        />
      </MDBox>
    </SideCard>
  );
};

export default DeleteItem;
DeleteItem.propTypes = {
  itemType: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

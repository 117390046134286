import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";
import { useEffect } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectCheckmarks({
  label,
  properties,
  onChange,
  valueInput,
  optionData,
  name,
}) {
  const [options, setOptions] = React.useState(optionData);
  const [personName, setPersonName] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    // Determine the new value (split the string or use the array)
    const newValue = typeof value === "string" ? value.split(",") : value;

    // Update the state with the new value
    setPersonName(newValue);

    // Call onChange with the updated value
    onChange({
      target: {
        name: name,
        value: newValue,
      },
    });
  };
  return (
    <Select
      multiple
      sx={{
        width: "100%",
        "& > div:first-of-type": {
          padding: "0.86rem !important", // Apply padding to the first child
        },
      }}
      value={personName}
      onChange={handleChange}
      input={<OutlinedInput label={label} />}
      renderValue={(selected) => selected?.map((s) => s.name) + ","}
      MenuProps={MenuProps}
    >
      {optionData &&
        optionData.map((name) => (
          <MenuItem key={name.id} value={name}>
            {/*<Checkbox checked={personName.includes(name.id)} />*/}
            <ListItemText primary={name.name} />
          </MenuItem>
        ))}
    </Select>
  );
}

MultipleSelectCheckmarks.propTypes = {
  label: PropTypes.string, // Changed from `any` to `string`
  name: PropTypes.string, // Changed from `any` to `string`
  properties: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  valueInput: PropTypes.any.isRequired,
  optionData: PropTypes.array.isRequired,
};

import axiosInstance from "../axiosInstance";
import { prefix } from "./prefixsVariables";

export const appSettingsApi = {
  createOrUpdate: async (data) => {
    return await axiosInstance.post(`${prefix.brand}/create`, data);
  },
  getDataAPI: async () => {
    return await axiosInstance.get(`${prefix.brand}/get`);
  },
};

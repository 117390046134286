import axiosInstance from "../axiosInstance";
import { prefix } from "./prefixsVariables";

export const roomsAPI = {
  setDepartment: async (data) => {
    return await axiosInstance.post(`${prefix.rooms}/create_or_update_department`, data);
  },
  getRooms: async () => {
    return await axiosInstance.get(`${prefix.rooms}/get`);
  },
  getRoomTypes: async () => {
    return await axiosInstance.get(`${prefix.rooms}/dashboard/get_room_types`);
  },
  createOrUpdate: async (data) => {
    return await axiosInstance.post(`${prefix.rooms}/dashboard/create_or_update_room_types`, data);
  },
  changeRoomStatus: async (data, id) => {
    return await axiosInstance.post(`${prefix.rooms}/dashboard/change_room_status/${id}`, data);
  },
  setRoom: async (data) => {
    return await axiosInstance.post(`${prefix.rooms}/dashboard/add`, data);
  },
  setRatesAvailability: async (data) => {
    return await axiosInstance.post(`${prefix.rooms}/dashboard/rates`, data);
  },
  getRatesAvailability: async (data, id) => {
    return await axiosInstance.post(
      `${prefix.rooms}/dashboard/get_rooms_rate${
        id ? `/${id}` : "/1a6a9526-04a8-4492-8af8-9b852f7a879b"
      }`,
      data
    );
  },
  delete: async (id) => {
    return await axiosInstance.get(`${prefix.rooms}/delete/${id}`);
  },
};

import React from "react";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";

const MdContainer = ({ children }) => {
  return (
    <Container maxWidth={"lg"} sx={{ paddingX: "3 !important" }}>
      {children}
    </Container>
  );
};

export default MdContainer;
MdContainer.propTypes = {
  children: PropTypes.any,
};

import React, { useState } from "react";

const CategoryHooksStates = () => {
  const [namesInputs, setNameInputs] = useState();
  const [data, setData] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [files, setFiles] = useState();
  return {
    namesInputs,
    setNameInputs,
    data,
    setData,
    isLoading,
    setLoading,
    files,
    setFiles,
  };
};

export default CategoryHooksStates;

import React, { createContext, useContext, useEffect, useState } from "react";
import { brandAPI } from "../assets/prefixApis/brandAPI";
import { utils } from "../assets/assetsFunctions";
import { useMaterialUIController } from "./index";
import { pushMessages } from "../hooks/pushMessages";
import { SetNotificationClass } from "../assets/messageNotification";
import { api_services } from "../assets/api_services";
import moment from "moment";

const PaymentContext = createContext(null);
//eslint-disable-next-line
const BrandPaymentContextProvider = ({ children }) => {
  const [brands, setBrands] = useState();
  const [userTransactions, setUserTransactions] = useState();
  const { showMessage } = pushMessages();

  function getUserBrand() {
    const alert = new SetNotificationClass();
    brandAPI
      .getDataAPI()
      .then((res) => setBrands(utils.returnObjectFromArray(res.data.data)))
      .catch((e) => showMessage(alert.SOMETHING_WRONG()));
    api_services
      .getUserTransactions({ today: utils.dateLocalization(moment()) })
      .then((res) => setUserTransactions(res.data))
      .catch((e) => showMessage(alert.SOMETHING_WRONG()));
  }
  useEffect(() => {
    getUserBrand();
  }, []);
  return (
    <PaymentContext.Provider value={{ brands, userTransactions }}>
      {children}
    </PaymentContext.Provider>
  );
};

export default BrandPaymentContextProvider;
export const usePaymentContext = () => {
  return useContext(PaymentContext);
};

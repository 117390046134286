import React from "react";
import LayoutHeader from "../../../components/layoutHeader/layoutHeader";
import Container from "@mui/material/Container";
import View from "../components/view";

const Home = () => {
  return (
    <Container sx={{ padding: 1 }}>
      <LayoutHeader
        title={"View Subscription"}
        subtitle={"Quickly register new category and optimize your setup."}
      />
      <View />
    </Container>
  );
};
export default Home;

import React, { useEffect, useState } from "react";
import { commerceAPI } from "../../../../assets/prefixApis/commerceAPI";
import { utils } from "../../../../assets/assetsFunctions";
import { handleInputChange, transformFields } from "../../utils/formUtils";
import { SetNotificationClass } from "../../../../assets/messageNotification";
import EditCategoryHooksState from "./editCategoryHooksState";
import { pushMessages } from "../../../../hooks/pushMessages";

const EditCategoryHooks = () => {
  const {
    cat,
    setCat,
    categoryStatus,
    setCategoryStatus,
    images,
    setImages,
    fields,
    setFields,
    fieldsData,
    setFieldsData,
    files,
    setFiles,
  } = EditCategoryHooksState();
  const { showMessage, showHideLoader } = pushMessages();
  const [isLoading, setLoading] = useState(true);
  const handleChange = handleInputChange(setFieldsData);

  const formFields = [
    {
      name: "Category name",
      fields: {
        onChange: "handleChange",
        inputType: "input",
        hasParentKey: "name",
        name: "room_name",
        label: "Room Name",
        required: false,
        type: "text",
      },
    },
    {
      name: "Category descriptions",
      fields: {
        onChange: "handleChange",
        inputType: "editor",
        hasParentKey: "description",
        name: "room_description",
        label: "Room Description",
        required: false,
        type: "textArea",
      },
    },
  ];

  const createDataFields = (data) => {
    const result = Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        value.reduce((acc, item) => {
          acc[item?.language?.name] = item.description;
          return acc;
        }, {}),
      ])
    );
    setFieldsData((prev) => ({ ...prev, ...result }));
  };

  function handleDataFromServer(response) {
    let data = response.data;
    setCategoryStatus({ id: data.id, status: data.disabled });
    setImages(data?.images);
    setFieldsData(data?.translationsG);

    let m = transformFields(response.languages, formFields);
    setFields(m);
    setFieldsData({ id: data.id });
    createDataFields(data?.translationsG);
  }

  const getDataFromServer = (id) => {
    commerceAPI.category
      .getById(id)
      .then((res) => {
        if (utils.checkApiResponse(res)) {
          setCat(res.data.data);
          handleDataFromServer(res.data);
        }
      })
      .finally(() => setLoading(false));
  };
  async function submit(e) {
    let alert = new SetNotificationClass();
    e.preventDefault();
    showHideLoader(true);
    const formData = new FormData();
    formData.append("data", JSON.stringify(fieldsData));
    files?.forEach((file, index) => {
      formData.append(`images[${index}]`, file);
    });
    commerceAPI.category
      .update(formData)
      .then((res) => {
        if (utils.checkApiResponse(res)) {
          showMessage(alert.SUCCESS_OPERATION());
        }
      })
      .finally(() => showHideLoader(false));
  }
  useEffect(() => {
    const id = utils.getParamsFromUrl("id");
    if (id) getDataFromServer(id);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(fieldsData);
  };

  return {
    isLoading,
    cat,
    categoryStatus,
    images,
    fields,
    fieldsData,
    submit,
    handleChange,
    files,
    setFiles,
  };
};

export default EditCategoryHooks;

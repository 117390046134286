import React, { useEffect } from "react";
import MDBox from "../../components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";
import { contentLoader } from "../../components/loader/loader";
import EditUserForm from "./components/editUserForm";
import GetUserHook from "./components/getUserHook";
import LayoutHeader from "../../components/layoutHeader/layoutHeader";
import Container from "@mui/material/Container";

const EditUser = () => {
  const { user, localLoader, userId } = GetUserHook();
  const renderContent = () => {
    if (localLoader) {
      return contentLoader();
    }
    if (user) {
      return <EditUserForm user={user} userId={userId} />;
    }
    return (
      <MDTypography
        variant="h6"
        fontWeight="medium"
        sx={{ alignSelf: "center" }}
        textTransform="capitalize"
      >
        No user found or disabled
      </MDTypography>
    );
  };
  return (
    <Container>
      <MDBox pb={3}>
        <LayoutHeader title={"Edit User"} subtitle={"Update and modify user details."} />
        <Card sx={{ marginTop: 2 }}>
          <MDBox pt={3}></MDBox>
          {renderContent()}
        </Card>
      </MDBox>
    </Container>
  );
};

export default EditUser;

import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import PropTypes from "prop-types";

const Editor = ({ handleChange, inputProps, user }) => {
  // const [value, setValue] = useState("");
  function generateEvent(name, value) {
    return handleChange({
      target: { name: name, value: value, dataset: { prop: inputProps?.hasParentKey } },
    });
  }
  let value = "";

  if (user) {
    if (!inputProps.hasParentKey) {
      // When hasParentKey is false or not provided
      value =
        user.hasOwnProperty(inputProps.name) && inputProps.type !== "file"
          ? user[inputProps.name]
          : "";
    } else {
      // When hasParentKey exists
      value =
        user.hasOwnProperty(inputProps.hasParentKey) &&
        user[inputProps.hasParentKey].hasOwnProperty(inputProps.name)
          ? user[inputProps.hasParentKey][inputProps.name]
          : "";
    }
  }

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", { color: [] }],
      ["clean"],
      [
        {
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
        },
      ],
    ],
  };

  return (
    <div>
      <ReactQuill
        style={{
          overflow: "hidden",
          position: "relative",
          height: `400px`,
          // minHeight: "150px", // Minimum height for the editor
          // maxHeight: "600px", // Optional: Set a maximum height
          backgroundColor: "white",
        }}
        modules={modules}
        theme="snow"
        value={value}
        onChange={(e) => generateEvent(inputProps.name, e)}
        name={inputProps.name}
      />
    </div>
  );
};
export default Editor;
Editor.propTypes = {
  handleChange: PropTypes.func,
  user: PropTypes.any,
  inputProps: PropTypes.any.isRequired,
};

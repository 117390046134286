import React from "react";
import LayoutHeader from "../../../../../components/layoutHeader/layoutHeader";
import Container from "@mui/material/Container";
import AddCategory from "../../components/addCategory";
import MdContainer from "../../../../../components/Container/mdContainer";

const Add = () => {
  return (
    <MdContainer>
      <LayoutHeader
        title={"Add Category"}
        subtitle={"Quickly register new category and optimize your setup."}
      />
      <AddCategory />
    </MdContainer>
  );
};

export default Add;

import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import { brandAPI, departmentAPI, otherAPI, roomsAPI, usersAPI } from "../../assets/apiFunctions";
import MDTypography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { commerceAPI } from "../../assets/prefixApis/commerceAPI";

const MdSelect = ({ label, properties, handleChange, value, optionData }) => {
  const [options, setOptions] = React.useState(optionData);
  const [selectedContractType, setSelectedContractType] = React.useState("");

  //this function to get options from server
  const getOptions = {
    contract: () => otherAPI.contractTypes().then((res) => setOptions(res.data.data)),
    departments: () => departmentAPI.getDepartmentAPI().then((res) => setOptions(res.data.data)),
    roomTypes: () => roomsAPI.getRoomTypes().then((res) => setOptions(res.data.data)),
    brands: () => brandAPI.getDataAPI().then((res) => setOptions(res.data.data)),
    users: () => usersAPI.getUsers().then((res) => setOptions(res.data.data)),
    categories: () => commerceAPI.getCategories().then((res) => setOptions(res.data)),
  };

  useEffect(() => {
    let d = properties?.options;
    if (d && getOptions[d]) {
      getOptions[d](); // Call the function associated with the provided option
    }
  }, []);
  if (!options) return null;
  return (
    <>
      {/*{label}*/}
      <TextField
        required={properties?.required}
        name={properties?.name}
        sx={{
          width: "100%",
          "& > div:first-of-type": {
            padding: "0.86rem !important", // Apply padding to the first child
          },
        }}
        onChange={(e) => {
          handleChange(e);
          setSelectedContractType(e.target.value);
        }}
        id={properties?.name}
        select
        value={value}
        label={properties?.label}
        // defaultValue="1"
        // slotProps={{
        //   select: {
        //     native: true,
        //   },
        // }}
      >
        {/*<MenuItem value={"1"}>default</MenuItem>*/}
        {options.length > 0 &&
          options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name || option?.type}
            </MenuItem>
          ))}
      </TextField>
    </>
  );
};

export default MdSelect;
MdSelect.defaultValue = {
  optionData: [],
};
MdSelect.propTypes = {
  label: PropTypes.any,
  properties: PropTypes.any,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  optionData: PropTypes.array,
};

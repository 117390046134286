/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from "react";
import MDBox from "../../../../components/MDBox";
// import MDAvatar from "../../components/MDAvatar";
import MDTypography from "../../../../components/MDTypography";
// import MDBadge from "../../components/MDBadge";
import { Button, Icon } from "@mui/material";
import { feedbackAPI, usersAPI } from "../../../../assets/apiFunctions";
import _FeedbackTable from "./_feedbackTablejs";

const UsersTable = () => {
  const [feedback, setFeedback] = useState(null);
  useEffect(() => {
    feedbackAPI.getFeedback().then((res) => setFeedback(res.data.data));
  }, []);
  const users = null;
  const [search, setSearch] = useState("");
  return (
    <MDBox>
      {/*<MDBox width="12rem" ml="auto">*/}
      {/*  <MDInput*/}
      {/*    placeholder="Search..."*/}
      {/*    value={search}*/}
      {/*    size="small"*/}
      {/*    fullWidth*/}
      {/*    onChange={(e) => {*/}
      {/*      setSearch(e.target.value);*/}
      {/*      // onSearchChange(e.target.value);*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</MDBox>*/}
      <_FeedbackTable
        users={
          search === ""
            ? feedback
            : feedback.filter((user) => user.name?.toLowerCase().includes(search.toLowerCase()))
        }
      />
    </MDBox>
  );
};

export default React.memo(UsersTable);

import React from "react";
import { Navigate, Route, Routes, matchPath, useHref } from "react-router-dom";
// import View from "./view";
import ViewProfile from "./components/viewProfile";
import LazyLoading from "../../components/lazyLoading";

const View = React.lazy(() => import("./components/view"));
const Edit = React.lazy(() => import("./components/edit"));
const Career = React.lazy(() => import("./components/addJob"));

const Index = () => {
  return (
    <>
      <LazyLoading>
        <Routes>
          <Route index element={<View />} />
          <Route path="add" element={<Career />} />
          <Route path="edit" element={<Edit />} />
          <Route path="applicable-profile" element={<ViewProfile />} />
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </LazyLoading>
    </>
  );
};

export default Index;

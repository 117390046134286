import React, { useContext, useEffect, useState } from "react";
import { DatePicker, TimePicker } from "../../components/datePicker";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Button from "@mui/material/Button";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import { Icon, Paper } from "@mui/material";
import Card from "@mui/material/Card";
import { usersAPI, workingHoursAPI } from "../../assets/apiFunctions";
import { v4 as uuidv4 } from "uuid";
import { setLoader, setNotification, useMaterialUIController } from "../../context";
import messageNotification from "../../assets/messageNotification";
import { isArray } from "chart.js/helpers";
import { utils } from "../../assets/assetsFunctions";
import { GetHoursBetweenDates } from "./components/getHoursBetweenDates";
import { getGlobalContext } from "../../context/globalContext";
import LayoutHeader from "../../components/layoutHeader/layoutHeader";
import WorkHoursHooks from "./workHoursHooks";
import WorkingHoursComponent from "./test";

const WorkHours = () => {
  const {
    workingTimes,
    setWorkingTimes,
    handleTimeInput,
    total,
    submit,
    checkFromMinTime,
    deleteHour,
    edit,
    day,
    setDay,
  } = WorkHoursHooks();
  return (
    <>
      {/*for admins*/}
      <MDBox pt={6} pb={3}>
        <Card sx={{ marginTop: 2 }}>
          <LayoutHeader title={"Set your working hours"} />
          <MDBox pt={3}></MDBox>
          <Paper sx={{ padding: "20px" }}>
            <Grid container spacing={2} direction={"row"} lg={6}>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  value={day}
                  onChange={(e) =>
                    // setDate(e);
                    setDay(utils.handleChangeFormInputs(e, true, "startDate").target.value)
                  }
                />
              </Grid>
              {/*<Grid item xs={12} sm={6}>*/}
              {/*  {users && (*/}
              {/*    <MultipleSelectPlaceholder*/}
              {/*      options={users}*/}
              {/*      handleChange={selectUser}*/}
              {/*      users={selectedUser}*/}
              {/*      // placeHolder={"home user"}*/}
              {/*    />*/}
              {/*  )}*/}
              {/*</Grid>*/}
            </Grid>
            <form onSubmit={submit}>
              <Grid container direction={"row"} lg={6} spacing={1} my={1}>
                {workingTimes?.hours.map((d, index) => (
                  <Grid item key={d.id}>
                    <Grid container spacing={1}>
                      <Grid item xs={5} sm={"auto"}>
                        <TimePicker
                          label={"from"}
                          onChange={(value) => handleTimeInput("from", value, index)}
                          defaultValue={""}
                          minTime={checkFromMinTime(index)}
                          value={(d.from && moment(d.from, "HH:mm")) || null}
                        />
                      </Grid>
                      <Grid item xs={5} sm={"auto"}>
                        <TimePicker
                          label={"To"}
                          onChange={(value) => handleTimeInput("to", value, index)}
                          defaultValue={""}
                          minTime={utils.minTime(d)}
                          value={(d.to && moment(d.to, "HH:mm")) || null}
                        />
                      </Grid>
                      <Grid item xs={2} sm={"auto"}>
                        <IconButton aria-label="delete" onClick={() => deleteHour(index)}>
                          <DeleteIcon color={"error"} />
                        </IconButton>
                      </Grid>
                      <Grid item xs={12}>
                        <MDTypography variant="caption" color="warning" fontWeight={"regular"}>
                          {d?.error && d?.error}
                        </MDTypography>{" "}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                }}
                mt={2}
                gap={1}
              >
                <Button
                  onClick={() => {
                    edit();
                    setWorkingTimes({
                      ...workingTimes,
                      hours: [...workingTimes.hours, { id: uuidv4(), from: "", to: "" }],
                    });
                  }}
                  variant={"outlined"}
                >
                  <MDTypography
                    variant="h7"
                    fontWeight="medium"
                    textTransform="capitalize"
                    color={"dark"}
                  >
                    Add a Period
                  </MDTypography>
                </Button>
                <Button type={"submit"} variant={"contained"}>
                  <MDTypography variant="h7" fontWeight="medium" color={"white"}>
                    Submit
                  </MDTypography>
                </Button>
                {/*<Button*/}
                {/*  onClick={() => copyText()}*/}
                {/*  variant={"text"}*/}
                {/*  endIcon={<Icon fontSize="small">content_copy</Icon>}*/}
                {/*>*/}
                {/*  <MDTypography variant="h7" fontWeight="medium" color={"secondary"}>*/}
                {/*    send Via Whatsapp*/}
                {/*  </MDTypography>*/}
                {/*</Button>*/}
              </Stack>
            </form>
            <MDTypography py={2} variant="h6" fontWeight="medium" textTransform="capitalize">
              {`Total Hours:${total && utils.minutes_to_hhmm(total)}`}
            </MDTypography>
          </Paper>
        </Card>
      </MDBox>
      <GetHoursBetweenDates getPending={true} isUser={true} />
    </>
  );
};

export default WorkHours;

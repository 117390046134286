/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from "react";

// import MDAvatar from "../../components/MDAvatar";

// import MDBadge from "../../components/MDBadge";
import { Icon } from "@mui/material";
import DataTable from "../../../examples/Tables/DataTable";
import { TBText } from "../../../components/tableComponents/tableComponents";
import MDTypography from "../../../components/MDTypography";
import AlertDialog from "../../../components/dialog/dialog";
import MDBox from "../../../components/MDBox";
import { utils } from "../../../assets/assetsFunctions";

const List = ({ data }) => {
  const Employed = ({ employed }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {employed || "01-01-2022"}
    </MDTypography>
  );
  const Department = ({ department }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {department}
    </MDTypography>
  );
  const rows =
    data &&
    data.length > 0 &&
    data.map((c, index) => ({
      author: c.email,
      verify: c?.email_verifications?.verified ? "verified" : "Unverified",
      created: (
        <TBText
          subText={utils.removeSeconds(c.created_at)}
          text={utils.monthYearFormat(c.created_at)}
        />
      ),
      // action: <ActionMenu text={utils.monthYearFormat(c.created_at)} />,
    }));

  const columns = [
    { Header: "Name", accessor: "author", align: "left" },
    { Header: "Verified", accessor: "verify", align: "left" },
    { Header: "Created", accessor: "created", align: "left" },
  ];
  if (!rows || !columns) return null;
  return (
    <DataTable
      table={{ rows, columns }}
      isSorted={true}
      canSearch={true}
      entriesPerPage={true}
      showTotalEntries={true}
      noEndBorder
    />
  );
};

export default React.memo(List);

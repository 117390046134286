/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Billing page components
import Transaction from "./Transaction";
import moment from "moment";
import { isArray } from "chart.js/helpers";
import PropTypes from "prop-types";
import { utils } from "../../assets/assetsFunctions";
import RenderTransaction from "../../layouts/transactions/deleteUpdateTransaction/components/RenderTransaction";

function TransactionsComponent({ userTransactions, showDetails, title, isAdmin }) {
  const dates = userTransactions?.dates;
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}&apos;s
        </MDTypography>
        <MDBox display="flex" alignItems="flex-start">
          <MDBox color="text" mr={0.5} lineHeight={0}>
            <Icon color="inherit" fontSize="small">
              date_range
            </Icon>
          </MDBox>
          <MDTypography variant="button" color="text" fontWeight="regular">
            {dates && utils.dateRange(dates)}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox pt={3} pb={2} px={2}>
        <MDBox
          component="ul"
          display="flex"
          gap={1}
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          {userTransactions?.data?.length > 0 &&
            userTransactions?.data
              .sort((x, s) => new Date(s.day) - new Date(x.day))

              .map((d, index) =>
                !isAdmin ? (
                  <RenderTransaction isAdmin={true} transactions={d.transaction} key={index} />
                ) : (
                  <Transaction
                    key={index}
                    color="success"
                    icon="check"
                    data={showDetails ? d.transaction : null}
                    name={d?.brand_name}
                    // description={f(d.day)}
                    value="Successfully"
                  />
                )
              )}
          {/*<Transaction*/}
          {/*  color="success"*/}
          {/*  icon="expand_less"*/}
          {/*  name="Apple"*/}
          {/*  description="27 March 2020, at 04:30 AM"*/}
          {/*  value="+ $ 2,000"*/}
          {/*/>*/}
          {/*</MDBox>*/}
          {/*<MDBox mt={1} mb={2}>*/}
          {/*  <MDTypography variant="caption" color="text" fontWeight="bold" textTransform="uppercase">*/}
          {/*    yesterday*/}
          {/*  </MDTypography>*/}
          {/*</MDBox>*/}
          {/*<MDBox*/}
          {/*  component="ul"*/}
          {/*  display="flex"*/}
          {/*  flexDirection="column"*/}
          {/*  p={0}*/}
          {/*  m={0}*/}
          {/*  sx={{ listStyle: "none" }}*/}
          {/*>*/}
          {/*  <Transaction*/}
          {/*    color="success"*/}
          {/*    icon="expand_less"*/}
          {/*    name="Stripe"*/}
          {/*    description="26 March 2020, at 13:45 PM"*/}
          {/*    value="+ $ 750"*/}
          {/*  />*/}
          {/*  <Transaction*/}
          {/*    color="success"*/}
          {/*    icon="expand_less"*/}
          {/*    name="HubSpot"*/}
          {/*    description="26 March 2020, at 12:30 PM"*/}
          {/*    value="+ $ 1,000"*/}
          {/*  />*/}
          {/*  <Transaction*/}
          {/*    color="success"*/}
          {/*    icon="expand_less"*/}
          {/*    name="Creative Tim"*/}
          {/*    description="26 March 2020, at 08:30 AM"*/}
          {/*    value="+ $ 2,500"*/}
          {/*  />*/}
          {/*  <Transaction*/}
          {/*    color="dark"*/}
          {/*    icon="priority_high"*/}
          {/*    name="Webflow"*/}
          {/*    description="26 March 2020, at 05:00 AM"*/}
          {/*    value="Pending"*/}
          {/*  />*/}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default TransactionsComponent;
TransactionsComponent.propTypes = {
  userTransactions: PropTypes.any.isRequired,
  showDetails: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};
TransactionsComponent.defaultProps = {
  userTransactions: { data: [] },
};

import { transactionsAPI } from "./apiFunctions";

/**
 *
this file contain api function that used in multiple time
 */
export const api_services = {
  getUserTransactions: async (data) => {
    return await transactionsAPI.userTransactions(data);
  },
};

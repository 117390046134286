/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from "react";
import MDBox from "../../../../components/MDBox";
// import MDAvatar from "../../components/MDAvatar";
import MDTypography from "../../../../components/MDTypography";
// import MDBadge from "../../components/MDBadge";
import { Button, Icon } from "@mui/material";
import { roomsAPI, usersAPI } from "../../../../assets/apiFunctions";
import MDButton from "../../../../components/MDButton";
import CircularProgress from "@mui/material/CircularProgress";
import DataTable from "../../../../examples/Tables/DataTable";
import Status from "./status";
import { useNavigate, useNavigation } from "react-router-dom";
import { SERVER_IMAGES } from "../../../../assets/axiosInstance";

const RoomsTable = ({ onUserChange }) => {
  const navigate = useNavigate();
  const [loadingButton, setLoadingButton] = useState(null);
  const [data, setData] = useState(null);
  useEffect(() => {
    roomsAPI.getRoomTypes().then((res) => setData(res.data.data));
  }, []);
  // function handleChangeUserStatus(status, id) {
  //   setLoadingButton(id);
  //   usersAPI
  //     .disableUser({ status: status }, id)
  //     .then((res) => {
  //       const data = { status: !status };
  //       onUserChange("update", data, id);
  //     })
  //     .finally(() => setLoadingButton(null));
  // }
  //
  // function handleDelete(id) {
  //   usersAPI
  //     .deleteUser(id)
  //     .then((res) => {
  //       onUserChange("delete", {}, id);
  //     })
  //     .catch((e) => console.log(e));
  // }
  const Author = ({ name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="contained" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="contained">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );
  const Type = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left" display={"flex"} columnGap={5}>
      <MDTypography display="block" variant="contained" color="text" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );

  const Text = ({ q }) => (
    <MDTypography
      sx={{ width: 200, overflow: "hidden", textOverflow: "clip" }}
      component="div"
      variant="contained"
      color="text"
      fontWeight="medium"
    >
      {q}
    </MDTypography>
  );
  const RoomImage = ({ src }) => (
    <MDBox
      sx={{ width: "100px", height: "100px", overflow: "hidden" }}
      variant="contained"
      color="text"
      fontWeight="medium"
    >
      <img
        src={`${SERVER_IMAGES}/${src}`}
        style={{ objectFit: "cover", maxWidth: "100%", height: "100%" }}
      />
    </MDBox>
  );
  const Action = ({ action, id }) => (
    <MDBox display={"flex"} gap={1} alignItems={"center"}>
      {/*<AlertDialog*/}
      {/*  onAgree={(e) => handleDelete(id)}*/}
      {/*  id={id}*/}
      {/*  triggerText={"Delete"}*/}
      {/*  title={"name"}*/}
      {/*  disagreeText={"Cancel"}*/}
      {/*  content={`Are you sure you want to delete " ${name}"?`}*/}
      {/*  agreeText={"delete"}*/}
      {/*/>*/}
      <MDTypography
        variant="contained"
        color="text"
        fontWeight="medium"
        onClick={() => navigate(`/room-types/edit?id=${id}`)}
      >
        {action}
      </MDTypography>
    </MDBox>
  );

  const rows =
    data &&
    data.length > 0 &&
    data?.map((room) => ({
      type: <Type title={room.type} />,
      qty: <Text q={room?.capacity} />,
      desc: <Text q={room?.room_description} />,
      status: <Status status={room.disabled} id={room.id} />,
      space: <Text q={room?.space} />,
      image: <RoomImage src={room.room_img} />,
      action: <Action action="Edit" id={room.id} />,
    }));

  const columns = [
    { Header: "room Type", accessor: "type", align: "left" },
    { Header: "qty", accessor: "qty", align: "center" },
    { Header: "Image", accessor: "image", align: "center" },
    { Header: "desc", accessor: "desc", align: "center" },
    { Header: "status", accessor: "status", align: "center" },
    { Header: "space", accessor: "space", align: "center" },
    { Header: "Action", accessor: "action", align: "center" },
  ];
  if (!rows || !columns) return null;
  return (
    <DataTable
      table={{ rows, columns }}
      isSorted={false}
      canSearch={true}
      entriesPerPage={true}
      showTotalEntries={false}
      noEndBorder
    />
  );
};

export default React.memo(RoomsTable);

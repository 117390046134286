/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { utils } from "../../assets/assetsFunctions";
import { useEffect, useState } from "react";
import { statisticsAPI, workingHoursAPI } from "../../assets/apiFunctions";
import edit from "../users/edit";
import { isArray } from "chart.js/helpers";
import moment from "moment";
import WeeklyPlan from "../../components/weeklyPlan/weeklyPlan";
import axios from "axios";
import ManagerDashboard from "./components/managerDashboard";
import { getAllMaterialVariables, useMaterialUIController } from "../../context";
import Payments from "../payments";
import Statistics from "./components/statistics";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const { calculateHours, minutes_to_hhmm } = utils;
  const { roles } = getAllMaterialVariables();

  // for working hours char
  const [chartData, setChartData] = useState(null);
  const [data, setData] = useState(null);
  const createChar = async () => {
    //eslint-disable-next-lines
    let salesByDay = {};
    for (const day of data.working_days) {
      const date = new Date(day.date.date_day);
      const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "short" });
      const hours = minutes_to_hhmm(calculateHours(day.hours), "number");
      salesByDay[dayOfWeek] = hours;
    }
    const labels = Object.keys(salesByDay);
    const datasets = {
      label: "Hours",
      data: Object.values(salesByDay),
    };

    return { labels: labels, datasets: datasets };
  };
  // useEffect(() => {
  //   const currentDate = moment();
  //   const startDate = moment(currentDate).subtract(7, "days");
  //   workingHoursAPI
  //     .getHoursBetweenDates({ start_date: startDate, end_date: currentDate })
  //     .then((res) => {
  //       function compareFrom(a, b) {
  //         if (a.date.date_day < b.date.date_day) {
  //           return -1;
  //         } else if (a.date.date_day > b.date.date_day) {
  //           return 1;
  //         } else {
  //           return 0;
  //         }
  //       }
  //       const working_days = res.data.working_days.sort(compareFrom);
  //       setData(() => ({
  //         total_hours: res.data.total_hours,
  //         working_days,
  //       }));
  //     })
  //     .catch((e) => setData(null));
  // }, []);

  async function fetchData() {
    try {
      const salesByDay = await createChar();
      setChartData(salesByDay);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  if (roles?.r?.find((role) => [1000].includes(role))) return <Payments />;
  return (
    <MDBox py={3}>
      <Grid container spacing={3}>
        <Statistics />
        {/*<Grid item xs={12} md={6} lg={3}>*/}
        {/*  <MDBox mb={1.5}>*/}
        {/*    <ComplexStatisticsCard*/}
        {/*      color="primary"*/}
        {/*      icon="person_add"*/}
        {/*      title="Followers"*/}
        {/*      count="+91"*/}
        {/*      percentage={{*/}
        {/*        color: "success",*/}
        {/*        amount: "",*/}
        {/*        label: "Just updated",*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </MDBox>*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12}>*/}
        {/*  <ManagerDashboard />*/}
        {/*</Grid>*/}
      </Grid>
      <MDBox mt={5}>
        <Grid container spacing={3}>
          {/*<Grid item xs={12} md={6} lg={4}>*/}
          {/*  <MDBox mb={3}>*/}
          {/*    {chartData && (*/}
          {/*      <ReportsBarChart*/}
          {/*        color="info"*/}
          {/*        title="Weekly Report"*/}
          {/*        description="Last week working hours"*/}
          {/*        // date="campaign sent 2 days ago"*/}
          {/*        chart={chartData}*/}
          {/*      />*/}
          {/*    )}*/}
          {/*    <ReportsBarChart*/}
          {/*      color="info"*/}
          {/*      title="website views"*/}
          {/*      description="Last Campaign Performance"*/}
          {/*      date="campaign sent 2 days ago"*/}
          {/*      chart={reportsBarChartData}*/}
          {/*    />*/}
          {/*  </MDBox>*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12} sm={9}>*/}
          {/*  <MDBox mb={3}>*/}
          {/*    <ReportsLineChart*/}
          {/*      color="success"*/}
          {/*      title="daily revenue"*/}
          {/*      description={*/}
          {/*        <>*/}
          {/*          (<strong>+15%</strong>) increase in today sales.*/}
          {/*        </>*/}
          {/*      }*/}
          {/*      date="updated 4 min ago"*/}
          {/*      chart={sales}*/}
          {/*    />*/}
          {/*  </MDBox>*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12} md={6} lg={4}>*/}
          {/*  <MDBox mb={3}>*/}
          {/*    <ReportsLineChart*/}
          {/*      color="dark"*/}
          {/*      title="completed tasks"*/}
          {/*      description="Last Campaign Performance"*/}
          {/*      date="just updated"*/}
          {/*      chart={tasks}*/}
          {/*    />*/}
          {/*  </MDBox>*/}
          {/*</Grid>*/}
        </Grid>
      </MDBox>
      <MDBox>
        <Grid container spacing={3}>
          {/*<Grid item xs={12} md={6} lg={8}>*/}
          {/*  <Projects />*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12} md={6} lg={9}>*/}
          {/*  /!*<ManagerDashboard />*!/*/}
          {/*  <WeeklyPlan />*/}
          {/*  /!*<OrdersOverview />*!/*/}
          {/*</Grid>*/}
        </Grid>
      </MDBox>
    </MDBox>
  );
}
{
  /*<Footer />*/
}
export default Dashboard;

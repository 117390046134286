import React from "react";
import LazyLoading from "../../components/lazyLoading";
import { Route, Routes } from "react-router-dom";

const Home = React.lazy(() => import("./_index"));
const Details = React.lazy(() => import("./reservationDetails"));
/**
 * reservation index page
 * */
const Index = () => {
  return (
    <LazyLoading>
      <Routes>
        <Route index element={<Home />} />
        <Route path="details" element={<Details />} />
      </Routes>
    </LazyLoading>
  );
};

export default Index;

import React, { useEffect, useState } from "react";
import offersHooksStates from "./offersHooksStates";
import { pushMessages } from "../../../../../hooks/pushMessages";
import { handleInputChange, transformFields } from "../../../utils/formUtils";
import { submitOffer } from "../../../utils/submitFunctions";
import { fetchLanguages } from "../../../utils/apiHelpers";

const UseOffersHooks = () => {
  const { namesInputs, setNameInputs, data, setData, isLoading, setLoading, files, setFiles } =
    offersHooksStates();
  const { showHideLoader, showMessage } = pushMessages();
  const submit = submitOffer(files, data, showHideLoader, showMessage);
  const handleChange = handleInputChange(setData);
  const [progress, setProgress] = useState(10);
  let formFields = [
    {
      name: "Offer title",
      fields: {
        onChange: "handleChange",
        inputType: "input",
        hasParentKey: "name",
        name: "room_name",
        label: "room name",
        value: "room_name",
        required: false,
        type: "text",
      },
    },
    {
      name: "Offer subtitle",
      fields: {
        onChange: "handleChange",
        inputType: "input",
        hasParentKey: "description",
        name: "room_name",
        label: "room name",
        value: "room_name",
        required: false,
        type: "textArea",
      },
    },
  ];
  let extraInput = {
    name: "Offer link",
    fields: [
      {
        onChange: "handleChange",
        inputType: "input",
        name: "url",
        label: "offer link to",
        value: "url",
        required: false,
        placeHolder: "can be empty...",
        type: "text",
      },
    ],
  };
  useEffect(() => {
    const fetchInputs = async () => {
      try {
        const languages = await fetchLanguages(); // Replace with your API endpoint
        const inputs = await transformFields(languages, formFields);
        // const data = await response.json();
        inputs.push(extraInput);

        setNameInputs(inputs);
      } catch (error) {
        console.error("Failed to fetch category inputs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInputs();
  }, []);

  return { namesInputs, handleChange, submit, data, setFiles, isLoading, progress };
};

export default UseOffersHooks;

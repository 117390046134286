import axiosInstance from "../axiosInstance";
import { prefix } from "./prefixsVariables";
import { ecommRoutes } from "../ecommRoutes";
export const commerceAPI = {
  category: {
    delete: async (id) => {
      return await axiosInstance.get(`${prefix.category}/${ecommRoutes.category.DELETE}/${id}`);
    },
    create: async (data, callback) => {
      return await axiosInstance.post(
        `${prefix.category}/${ecommRoutes.category.CREATE}`,
        data,
        callback
      );
    },
    update: async (data) => {
      return await axiosInstance.post(`${prefix.category}/${ecommRoutes.category.UPDATE}`, data);
    },

    getAll: async () => {
      return await axiosInstance.get(`${prefix.category}/${ecommRoutes.category.GET_ALL}`);
    },
    getById: async (id) => {
      return await axiosInstance.get(`${prefix.category}/${ecommRoutes.category.GET_BY_ID}/${id}`);
    },
    changeStatus: async (id) => {
      return await axiosInstance.get(
        `${prefix.category}/${ecommRoutes.category.CHANGE_STATUS}/${id}`
      );
    },
    search: async (isProduct, str) => {
      return await axiosInstance.get(
        `${prefix.category}/${ecommRoutes.category.SEARCH}/${isProduct}/${str}`
      );
    },
    changeParent: async (data) => {
      return await axiosInstance.post(
        `${prefix.category}/${ecommRoutes.category.CHANGE_PARENT}`,
        data
      );
    },
    deleteProductImage: async (id) => {
      return await axiosInstance.get(
        `${prefix.category}/${ecommRoutes.category.DELETE_IMAGE}/${id}`
      );
    },
    setPrimaryImage: async (id) => {
      return await axiosInstance.get(
        `${prefix.category}/${ecommRoutes.category.SET_PRIMARY_IMAGE}/${id}`
      );
    },
  },
  product: {
    create: async (data, callback) => {
      return await axiosInstance.post(
        `${prefix.product}/${ecommRoutes.product.CREATE}`,
        data,
        callback
      );
    },
    update: async (data) => {
      return await axiosInstance.post(`${prefix.product}/${ecommRoutes.product.UPDATE}`, data);
    },

    getAll: async () => {
      return await axiosInstance.get(`${prefix.product}/${ecommRoutes.product.GET_ALL}`);
    },
    getById: async (id) => {
      return await axiosInstance.get(`${prefix.product}/${ecommRoutes.product.GET_BY_ID}/${id}`);
    },
    changeStatus: async (id) => {
      return await axiosInstance.get(
        `${prefix.product}/${ecommRoutes.product.CHANGE_STATUS}/${id}`
      );
    },
    search: async (str) => {
      return await axiosInstance.get(`${prefix.product}/${ecommRoutes.product.SEARCH}/${str}`);
    },
    changeParent: async (data) => {
      return await axiosInstance.post(
        `${prefix.product}/${ecommRoutes.category.CHANGE_PARENT}`,
        data
      );
    },
    deleteProductImage: async (id) => {
      return await axiosInstance.get(`${prefix.product}/${ecommRoutes.product.DELETE_IMAGE}/${id}`);
    },
    delete: async (id) => {
      return await axiosInstance.get(`${prefix.product}/${ecommRoutes.product.DELETE}/${id}`);
    },
    setPrimaryImage: async (id) => {
      return await axiosInstance.get(
        `${prefix.product}/${ecommRoutes.product.SET_PRIMARY_IMAGE}/${id}`
      );
    },
  },
  offer: {
    create: async (data) => {
      return await axiosInstance.post(`${prefix.offer}/${ecommRoutes.offer.CREATE}`, data);
    },
    get: async () => {
      return await axiosInstance.get(`${prefix.offer}/${ecommRoutes.offer.GET}`);
    },
    delete: async (id) => {
      return await axiosInstance.get(`${prefix.offer}/${ecommRoutes.offer.DELETE}/${id}`);
    },
  },

  getLanguages: async () => {
    return await axiosInstance.get(`${prefix.commerce}/get_languages`);
  },
};

/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import CurrencyText from "../../../components/MDTypography/currency";
import Grid from "@mui/material/Grid";
import { utils } from "../../../assets/assetsFunctions";

function Transaction({ color, icon, name, description, value, data }) {
  return (
    <MDBox key={name} component="li">
      <Grid container>
        {data &&
          data
            // .sort((s, f) => f.day.localeCompare(s.day))
            .sort((x, s) => x.sort_index - s.sort_index)
            .map((d, index) => {
              return (
                <Grid
                  item
                  display="flex"
                  flexDirection="row"
                  key={d.id}
                  justifyContent={"space-between"}
                  bgcolor={index % 2 === 0 ? "lightgray" : ""}
                  width={"100%"}
                  p={1}
                >
                  <Grid item alignItems={"center"} gap={1} xs={4}>
                    <MDBox display={"flex"} alignItems={"center"} gap={1}>
                      <Icon sx={{ fontWeight: "bold" }} color={"success"}>
                        {icon}
                      </Icon>
                      <MDTypography
                        variant="caption"
                        color="dark"
                        fontWeight="bold"
                        textTransform={"capitalize"}
                      >
                        {d?.brand_name}
                      </MDTypography>{" "}
                    </MDBox>
                  </Grid>
                  <TransactionDetailsComponent details={d} />
                </Grid>
              );
            })}
      </Grid>
    </MDBox>
  );
}

export default Transaction;
const TransactionDetailsComponent = ({ details }) => {
  const currencyIcon = {
    dollar: "usd",
    dinar: "Iqd",
  };
  let d = details;
  if (!d.amount)
    return (
      <MDTypography variant="caption" color="error" fontWeight="bold">
        Data not set
      </MDTypography>
    );
  return (
    <>
      <React.Fragment>
        <Grid item xs={4} display={"flex"} alignItems={"start"} justifyContent={"center"}>
          <MDTypography
            variant="caption"
            color="dark"
            fontWeight="medium"
            textTransform={"capitalize"}
          >
            {d.day}
          </MDTypography>{" "}
        </Grid>
        <Grid item xs={4} display={"flex"} justifyContent={"end"}>
          <MDBox display={"flex"} flexDirection={"column"} alignItems={"end"}>
            <CurrencyText
              value={d.amount}
              currencySymbol={currencyIcon[d.currency_name]}
              variant="h6"
              color="info"
              fontWeight="medium"
            />
            <MDTypography
              variant="caption"
              color="text"
              fontWeight="medium"
              textTransform={"capitalize"}
            >
              {d.user_name}
            </MDTypography>
          </MDBox>
        </Grid>
      </React.Fragment>
    </>
  );
};
// Typechecking props of the Transaction
Transaction.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]).isRequired,
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.string.isRequired,
  data: PropTypes.array,
};
TransactionDetailsComponent.propTypes = {
  details: PropTypes.any.isRequired,
};

import React, { useCallback } from "react";

import ProductsMedia from "../../components/productsMedia";
import ParentCategory from "../../components/parentCategory";
import ProductStatus from "../../components/productStatus";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Stack } from "@mui/material";
import NoDataFound from "../../../../../components/NoDataFound";
import { contentLoader } from "../../../../../components/loader/loader";
import editCategoryHooks from "../../hooks/editProductHooks";
import SubmitForm from "../../../../../components/forms/submitForm";
import DropZone from "../../../../../components/DropZone";
import LayoutHeader from "../../../../../components/layoutHeader/layoutHeader";
import DeleteCategory from "../../../components/deleteItem";
import MdContainer from "../../../../../components/Container/mdContainer";
import Card from "@mui/material/Card";
const Edit = () => {
  const {
    isLoading,
    cat,
    status,
    images,
    fields,
    fieldsData,
    submit,
    handleChange,
    files,
    setFiles,
  } = editCategoryHooks();
  const memoizedSetFiles = useCallback(
    (files) => {
      setFiles(files);
    },
    [files]
  );
  if (isLoading) return contentLoader();
  if (cat === null || cat === undefined) return <NoDataFound />;
  return (
    <MdContainer>
      <LayoutHeader
        title={"Edit Product"}
        subtitle={"Quickly register new category and optimize your setup."}
      />
      <Grid container direction={{ xs: "column-reverse", lg: "row" }} gap={2} nowrap>
        <Grid item xs={12} lg={7}>
          <Card>
            <SubmitForm
              isAfter={true}
              inputs={fields}
              user={fieldsData}
              handleChange={handleChange}
              submit={submit}
              inputProps={{ md: 12, lg: 12 }}
            >
              <DropZone uploadFiles={memoizedSetFiles} />
              <ProductsMedia images={images} />
            </SubmitForm>
          </Card>
          {/*<AddProduct />*/}
        </Grid>
        <Grid item xs={12} lg={4} gap={1}>
          <Stack gap={1}>
            <ProductStatus status={status} />
            <ParentCategory parent={cat} />
            <DeleteCategory id={cat?.id} itemType={"product"} />
          </Stack>
        </Grid>
      </Grid>
    </MdContainer>
  );
};

export default Edit;

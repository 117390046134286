/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import MDAvatar from "../../components/MDAvatar";

// import MDBadge from "../../components/MDBadge";
import { Icon } from "@mui/material";
import { usersAPI } from "../../../../assets/apiFunctions";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import AlertDialog from "../../../../components/dialog/dialog";
import { TBDate, TBText } from "../../../../components/tableComponents/tableComponents";
import DataTable from "../../../../examples/Tables/DataTable";
import MDButton from "../../../../components/MDButton";
import CircularProgress from "@mui/material/CircularProgress";
import { utils } from "../../../../assets/assetsFunctions";

const _FeedbackTable = ({ users }) => {
  const navigate = useNavigate();
  const [loadingButton, setLoadingButton] = useState(null);
  // function handleChangeUserStatus(status, id) {
  //   setLoadingButton(id);
  //   usersAPI
  //     .disableUser({ status: status }, id)
  //     .then((res) => {
  //       const data = { status: !status };
  //       onUserChange("update", data, id);
  //     })
  //     .finally(() => setLoadingButton(null));
  // }

  // function handleDelete(id) {
  //   usersAPI
  //     .deleteUser(id)
  //     .then((res) => {
  //       onUserChange("delete", {}, id);
  //     })
  //     .catch((e) => console.log(e));
  // }
  const Author = ({ name, email }) => (
    <MDBox display="flex" alignItems="center">
      <MDBox>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name || "Unknown"}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );
  const Job = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left" display={"flex"}>
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography
        component="a"
        href={`tel:${title}`}
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        <Icon color={"success"} fontSize={"small"}>
          phone
        </Icon>
      </MDTypography>
    </MDBox>
  );

  const Status = ({ status, id }) => (
    <MDBox ml={-1}>
      <MDButton
        color={status === 0 ? "success" : "light"}
        size={"small"}
        // onClick={() => handleChangeUserStatus(status, id)}
        disabled={loadingButton && loadingButton === id ? true : false}
      >
        {loadingButton && loadingButton === id ? (
          <CircularProgress color={"secondary"} size={"20px"} />
        ) : status === 0 ? (
          "Enabled"
        ) : (
          "Disabled"
        )}
      </MDButton>
    </MDBox>
  );

  const Employed = ({ employed }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {employed || "01-01-2022"}
    </MDTypography>
  );
  const Department = ({ department }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {department}
    </MDTypography>
  );

  const Action = ({ action, id, name }) => (
    <MDBox display={"flex"} gap={1} alignItems={"center"}>
      <MDTypography
        variant="a"
        component="a"
        color="info"
        fontWeight="medium"
        sx={{ cursor: "pointer" }}
        onClick={() => navigate(`view?id=${id}`)}
      >
        {action}
      </MDTypography>
    </MDBox>
  );

  const rows =
    users &&
    users.length > 0 &&
    users?.map((user) => ({
      author: <Author image={user.image} name={user.name} email={user.email} />,
      room: <TBText text={user?.room_number} />,
      day: <TBDate employed={user?.date} />,
      // message: <TBText text={user?.message} />,

      phone: <TBText text={user?.phone} />,
      // type: <TBText text={utils.returnObjectFromArray(user?.roles)?.name} />,
      // status: <Status status={user.disabled} id={user.id} />,
      // employed: <Employed employed={user.employed} />,
      action: <Action action="View" id={user.id} name={user.name} />,
    }));

  const columns = [
    { Header: "Name", accessor: "author", width: "20%", align: "left" },
    { Header: "Message", accessor: "day", align: "center" },
    // { Header: "room", accessor: "room", align: "center" },
    { Header: "Phone", accessor: "phone", align: "left" },
    // { Header: "Department", accessor: "department", align: "center" },
    // { Header: "Status", accessor: "status", align: "center" },
    { Header: "Action", accessor: "action", align: "center" },
  ];
  if (!rows || !columns) return null;
  return (
    <DataTable
      table={{ rows, columns }}
      isSorted={false}
      canSearch={false}
      entriesPerPage={false}
      showTotalEntries={true}
    />
  );
};

export default React.memo(_FeedbackTable);

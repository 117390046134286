import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import moment from "moment/moment";
import SubmitForm from "../../../../components/forms/submitForm";
import inputs from "./inputs";
import Card from "@mui/material/Card";
import { usePaymentContext } from "../../../../context/brandPaymentContext";
import MDBox from "../../../../components/MDBox";
import Icon from "@mui/material/Icon";
import MDTypography from "../../../../components/MDTypography";
import Divider from "@mui/material/Divider";
import _usePaymentsHooks from "../../hooks/_usePaymentsHooks";
import InputComponent from "../../../../components/inputsComponent/inputsComponent";
import PropTypes from "prop-types";
import LayoutHeader from "../../../../components/layoutHeader/layoutHeader";

const AddBrandPayment = ({ isAdmin }) => {
  const { brands } = usePaymentContext();
  const brandSelect = {
    onChange: "handleChange",
    required: true,
    inputType: "select",
    name: "fk_brand_id",
    label: "select brand",
    value: "fk_brand_id",
    options: "brands",
  };
  const { data, submit, handleChange } = _usePaymentsHooks();
  if (brands === "undefined" || brands === null) {
    return (
      <DisabledCard
        title={"You don't have connected brands to your profile"}
        description={
          "You cannot make transactionComponent at this time. Please contact management for assistance"
        }
      />
    );
  }
  if (brands?.brand_details?.disabled)
    return (
      <DisabledCard
        title={"This Brand is Disabled"}
        description={
          "You cannot make transactionComponent at this time. Please contact management for assistance"
        }
      />
    );
  return (
    <Card sx={{ marginTop: 2 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SubmitForm
            inputs={inputs}
            handleChange={handleChange}
            user={data}
            submit={submit}
            isAfter={true}
          >
            {isAdmin ? (
              <Grid sx={{ m: 1 }} item xs={12} sm={6} my={2} lg={3} alignItems="center">
                <InputComponent e={brandSelect} user={data} handleChange={handleChange} />
              </Grid>
            ) : null}
          </SubmitForm>
        </Grid>
      </Grid>
    </Card>
  );
};

export default AddBrandPayment;
//eslint-disable-next-line
const DisabledCard = ({ description, title, value }) => {
  return (
    <Card>
      <MDBox
        sx={{ background: "rgba(250,143,6,0.67)" }}
        px={5}
        py={10}
        // mx={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="white"
        width="100%"
        shadow="md"
        borderRadius="lg"
        variant="gradient"
      >
        {/*<MDBox*/}
        {/*  display="grid"*/}
        {/*  justifyContent="center"*/}
        {/*  alignItems="center"*/}
        {/*  bgcolor={"warning"}*/}
        {/*  color="white"*/}
        {/*  width="4rem"*/}
        {/*  height="4rem"*/}
        {/*  shadow="md"*/}
        {/*  borderRadius="lg"*/}
        {/*  variant="gradient"*/}
        {/*>*/}
        {/*  /!*<Icon fontSize="default">{icon}</Icon>*!/*/}
        {/*</MDBox>*/}

        <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
          <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
            {title}
          </MDTypography>
          {description && (
            <MDTypography variant="h6" color="white" fontWeight="regular">
              {description}
            </MDTypography>
          )}
          {description && !value ? null : <Divider />}
          {value && (
            <MDTypography variant="h6" fontWeight="medium" color={"success"}>
              {value}
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
};
AddBrandPayment.default = {
  isAdmin: false,
};
AddBrandPayment.propTypes = {
  isAdmin: PropTypes.bool,
};

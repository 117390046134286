// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Billing page components
import Transaction from "layouts/billing/components/Transaction";
import { usePaymentContext } from "../../../../context/brandPaymentContext";
import moment from "moment";
import { isArray } from "chart.js/helpers";
import TransactionsComponent from "../../../../layoutDependencies/transactionComponent";
import PropTypes from "prop-types";

function Transactions({ title, isAdmin }) {
  const { userTransactions } = usePaymentContext();
  return (
    <Card sx={{ height: "100%" }}>
      <TransactionsComponent
        userTransactions={userTransactions}
        showDetails={true}
        title={title}
        isAdmin={isAdmin}
      />
    </Card>
  );
}

export default Transactions;
Transactions.propTypes = {
  title: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
};

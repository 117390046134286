import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import numeral from "numeral";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";

// import classes from "./common.scss";
// import { getFileIcon } from "./../utilities";

export const FilesList = ({ files, onFileRemove }) => (
  <TableContainer component={Paper} className="mt-3">
    <Table>
      <TableBody>
        {_.map(files, (file, index) => (
          <TableRow key={index}>
            {/* File Icon */}
            {/*<TableCell>*/}
            {/*  <div>/!*<i className={`fa fa-fw fa-2x ${getFileIcon(file)}`} />*!/</div>*/}
            {/*</TableCell>*/}

            {/* File Name */}
            <TableCell>
              <Typography
                title={file.name}
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  display: "block",
                  width: "200px",
                }}
              >
                {file.name}
              </Typography>
            </TableCell>

            {/* File Size */}
            <TableCell>{numeral(file.size).format("0.00a")}B</TableCell>

            {/* Owner */}
            <TableCell>You</TableCell>

            {/* Modified Date */}
            <TableCell>{moment(file.modifiedDate).format("DD-MMM-YYYY, HH:mm")}</TableCell>

            {/* Actions */}
            <TableCell align="right">
              <Tooltip title="Delete File" placement="left">
                <IconButton size="small" onClick={() => onFileRemove(file)}>
                  <DeleteIcon color="error" />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

FilesList.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
      modifiedDate: PropTypes.string,
    })
  ),
  onFileRemove: PropTypes.func.isRequired,
};

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from "react";

// import MDAvatar from "../../components/MDAvatar";

// import MDBadge from "../../components/MDBadge";
import { Icon } from "@mui/material";
import DataTable from "../../../../../examples/Tables/DataTable";
import MDTypography from "../../../../../components/MDTypography";
import MDBox from "../../../../../components/MDBox";
import AlertDialog from "../../../../../components/dialog/dialog";
import { TBText } from "../../../../../components/tableComponents/tableComponents";
import { utils } from "../../../../../assets/assetsFunctions";
import { SERVER_IMAGES } from "../../../../../assets/axiosInstance";
import ActionMenu from "../../../components/actionMenu";

const List = ({ categories }) => {
  // const { navigate } = useUserContext();
  // const { onUserChange } = userHooks();
  const [loadingButton, setLoadingButton] = useState(null);
  // function handleChangeUserStatus(status, id) {
  //   setLoadingButton(id);
  //   usersAPI
  //     .disableUser({ status: status }, id)
  //     .then((res) => {
  //       const data = { status: !status };
  //       onUserChange("update", data, id);
  //     })
  //     .finally(() => setLoadingButton(null));
  // }

  function handleDelete(id) {
    // usersAPI
    //   .deleteUser(id)
    //   .then((res) => {
    //     onUserChange("delete", {}, id);
    //   })
    //   .catch((e) => console.log(e));
  }
  const Image = ({ src }) => (
    <MDBox
      sx={{ width: "100px", height: "100px", overflow: "hidden" }}
      variant="contained"
      color="text"
      fontWeight="medium"
    >
      <img
        src={`${SERVER_IMAGES}/${src}`}
        style={{ objectFit: "cover", maxWidth: "100%", height: "100%" }}
      />
    </MDBox>
  );
  const Author = ({ name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );
  const Job = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left" display={"flex"} columnGap={5}>
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography
        component="a"
        href={`tel:${title}`}
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        <Icon color={"success"} fontSize={"small"}>
          phone
        </Icon>
      </MDTypography>
    </MDBox>
  );

  const Status = ({ status }) => {
    if (status === 0 || status === false) {
      return (
        <MDBox
          // variant="gradient"
          bgcolor={"success"}
          color={"white"}
          coloredShadow={"dark"}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          // width="4rem"
          // height="4rem"
          px={1}
          py={0.5}
          // mt={-3}
        >
          <MDTypography variant="caption" color="white" fontWeight="bold">
            Published
          </MDTypography>
        </MDBox>
      );
    } else {
      return (
        <MDBox
          // variant="gradient"
          bgcolor={"warning"}
          color={"white"}
          coloredShadow={"dark"}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          // width="4rem"
          // height="4rem"
          px={1}
          py={0.5}
          // mt={-3}
        >
          <MDTypography variant="caption" color="white" fontWeight="bold">
            Unpublished
          </MDTypography>
        </MDBox>
      );
    }
  };

  const Employed = ({ employed }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {employed || "01-01-2022"}
    </MDTypography>
  );
  const Department = ({ department }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {department}
    </MDTypography>
  );

  const Action = ({ action, id, name }) => (
    <MDBox display={"flex"} gap={1} alignItems={"center"}>
      <AlertDialog
        onAgree={(e) => handleDelete(id)}
        id={id}
        triggerText={"Delete"}
        title={"Delete user"}
        disagreeText={"Cancel"}
        content={`Are you sure you want to delete " ${name}"?`}
        agreeText={"delete"}
        icon={<Icon color={"error"}>delete</Icon>}
      />
      <MDTypography
        variant="caption"
        color="text"
        fontWeight="medium"
        onClick={() => navigate(`/users/edit/?id=${id}`)}
      >
        {action}
      </MDTypography>
    </MDBox>
  );

  // const rows =
  //   users &&
  //   users.length > 0 &&
  //   users?.map((user) => ({
  //     author: <Author image={user.image} name={user.name} email={user.email} />,
  //     department: <Department department={user?.department?.name} />,
  //     phone: <Job title={user?.phone} />,
  //     type: <TBText text={utils.returnObjectFromArray(user?.roles)?.name} />,
  //     status: <Status status={user.disabled} id={user.id} />,
  //     // employed: <Employed employed={user.employed} />,
  //     action: <Action action="Edit" id={user.id} name={user.name} />,
  //   }));

  const rows =
    categories &&
    categories.length > 0 &&
    categories.map((c, index) => ({
      search: utils.returnObjectFromArray(c?.translations)?.description,
      // author: <TBText text={utils.returnObjectFromArray(c?.translations)?.description} />,
      author: utils.returnObjectFromArray(c?.translations)?.description,
      parent: (
        <TBText text={utils.returnObjectFromArray(c?.parent?.translations)?.description || "-"} />
      ),
      status: <Status status={c?.disabled} />,
      created: utils.monthYearFormat(c.created_at),
      action: <ActionMenu id={c.id} toHref={"/category/edit?id"} />,
    }));

  const columns = [
    { Header: "", accessor: "search", align: "left", hidden: true },
    { Header: "Name", accessor: "author", align: "left" },
    { Header: "parent", accessor: "parent", align: "left" },
    // { Header: "Type", accessor: "type", align: "center" },
    // { Header: "Phone", accessor: "phone", align: "left" },
    // { Header: "Department", accessor: "department", align: "center" },
    { Header: "Status", accessor: "status", align: "left" },
    { Header: "Created", accessor: "created", align: "left" },
    { Header: "Created", accessor: "action", align: "left" },
  ];
  if (!rows || !columns) return null;
  return (
    <DataTable
      table={{ rows, columns }}
      isSorted={true}
      canSearch={true}
      entriesPerPage={true}
      showTotalEntries={true}
      noEndBorder
    />
  );
};

export default React.memo(List);

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";

// Billing page components
import BrandCard from "../BrandCard";
import React, { useEffect, useState } from "react";
import { brandAPI } from "../../../../../assets/apiFunctions";
import CardsContainer from "../BrandCard/cardsContainer";
import AlertDialog from "../../../../../components/dialog/dialog";
import useBranchesHook from "../../../hooks/useBranchesHook";
import { useBranchesContext } from "../../../../../context/brancheContext";
import MDInput from "../../../../../components/MDInput";

function BrandUserInformation() {
  const { brands } = useBranchesContext();
  const [search, setSearch] = useState("");
  return (
    <Card>
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Brands Information
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox width={{ xs: "100%", md: "12rem" }} ml="auto">
          <MDInput
            placeholder="Search..."
            value={search}
            size="small"
            fullWidth
            onChange={(e) => {
              setSearch(e.target.value);
              // onSearchChange(e.target.value);
            }}
          />
        </MDBox>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {brands && (
            <CardsContainer
              brands={
                search === "" ? brands : brands.filter((brand) => brand.name.includes(search))
              }
            />
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BrandUserInformation;

import axios from "axios";

// export const SERVER = "https://srobh.rawad.net/";
// export const SERVER = "https://fog44sc0k0wogoc8sss0o8wc.145.223.82.245.sslip.io/"; //working hours
export const SERVER = "https://y0gc8g488ooksocw84cwc4ws.145.223.82.245.sslip.io";
// export const SERVER = "http://127.0.0.1:8000";
// export const SERVER_IMAGES = "https://srobh.rawad.net/images";
export const SERVER_IMAGES =
  "https://minio-oksgwc4oc8gg4gg0cw8kk8o4.145.223.82.245.sslip.io/ecommerc";
/**
 * make it async await when user redirect to specific route after login
 * will need await to get the token from local storage
 **/
async function getToken() {
  return localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : null;
}
async function createHeaders() {
  const token = await getToken();
  const headers = {
    Authorization: token ? `Bearer ${token}` : undefined,
    "Content-Type": "application/json",
    "Cache-Control": "max-age=3600",
  };
  return headers;
}
const axiosInstance = axios.create({
  baseURL: SERVER,
  headers: createHeaders(),
});

axiosInstance.interceptors.response.use(
  function (res) {
    if (res.headers["roles"]) {
      localStorage.setItem("roles", res.headers["roles"]);
    }
    return res;
  },
  function (error) {
    const res = error.response;
    if (res && res.status === 403) {
      alert("you should logout");
      console.error("403 error - Forbidden");
      if (res.headers["roles"]) {
        localStorage.setItem("roles", res.headers["roles"]);
      }
    }
    // Optionally, handle other status codes or error types here.
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.request.use(async function (conf) {
  if (!conf?.sent) {
    if (conf.headers.Authorization == null || conf.headers.Authorization == undefined) {
      conf.headers.Authorization = await getToken();
    }
  }
  conf.sent = true;
  return conf;
});
export default axiosInstance;

import React, { useEffect, useState } from "react";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import MDTypography from "../../components/MDTypography";
import Divider from "@mui/material/Divider";
import { TinyDonutChart } from "../../components/Charts/tinyDonutChart";
import Box from "@mui/material/Box";
import { feedbackAPI } from "../../assets/prefixApis/feedbackAPI";
import { MessagesTable } from "./components/messagesTable";
import FeedbackResponseList from "./components/feedbackResponseList";
import Container from "@mui/material/Container";
import LayoutHeader from "../../components/layoutHeader/layoutHeader";

const _Index = (props) => {
  const [feedback, setFeedback] = useState(null);
  useEffect(() => {
    feedbackAPI.getStatistics().then((res) => setFeedback(res.data.data));
  }, []);
  return (
    <Container>
      <LayoutHeader title={"Messages"} subtitle={"review messages to improve and grow"} />
      <MDBox>
        <Grid container spacing={1} gap={{ xs: 5, md: 0 }}>
          {/*<Grid item xs={12} lg={4} md={6}>*/}
          {/*  <MDBox display={"flex"} flexDirection={"column"} gap={1}>*/}
          {/*    <MDBox alignItems={"center"} display={"flex"} justifyContent={"center"}>*/}
          {/*      <MDTypography fontWeight={"medium"}>Will come again</MDTypography>*/}
          {/*      <Divider*/}
          {/*        orientation="horizontal"*/}
          {/*        sx={{ ml: -2, mr: 1, display: "flex", width: "auto", flex: "1" }}*/}
          {/*      />*/}
          {/*    </MDBox>*/}
          {/*    <MDTypography variant={"button"}>*/}
          {/*      Will you visit Sipan Hotel when you come back to Erbil*/}
          {/*    </MDTypography>*/}
          {/*    {feedback !== null && <TinyDonutChart data={feedback?.willComeAgain} />}*/}
          {/*  </MDBox>*/}
          {/*</Grid>{" "}*/}
          {/*<Grid item xs={12} lg={4} md={6}>*/}
          {/*  <MDBox display={"flex"} flexDirection={"column"} gap={1}>*/}
          {/*    <MDBox alignItems={"center"} display={"flex"} justifyContent={"center"}>*/}
          {/*      <MDTypography fontWeight={"medium"}>First visiting</MDTypography>*/}

          {/*      <Divider*/}
          {/*        orientation="horizontal"*/}
          {/*        sx={{ ml: -2, mr: 1, display: "flex", width: "auto", flex: "1" }}*/}
          {/*      />*/}
          {/*    </MDBox>*/}
          {/*    <MDTypography variant={"button"}>*/}
          {/*      Is this your first time in Sipan Hotel?*/}
          {/*    </MDTypography>*/}
          {/*    {feedback !== null && <TinyDonutChart data={feedback?.firstStay} />}*/}
          {/*  </MDBox>*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12} lg={4} md={6}>*/}
          {/*  <MDBox display={"flex"} flexDirection={"column"} gap={1}>*/}
          {/*    <MDBox alignItems={"center"} display={"flex"} justifyContent={"center"}>*/}
          {/*      <MDTypography fontWeight={"medium"}>Others</MDTypography>*/}

          {/*      <Divider*/}
          {/*        orientation="horizontal"*/}
          {/*        sx={{ ml: -2, mr: 1, display: "flex", width: "auto", flex: "1" }}*/}
          {/*      />*/}
          {/*      <MDTypography variant={"button"} display={"block"}>*/}
          {/*        Score from 5*/}
          {/*      </MDTypography>*/}
          {/*    </MDBox>*/}
          {/*    {feedback &&*/}
          {/*      feedback?.scores.map((s, index) => (*/}
          {/*        <MDBox display={"flex"} justifyContent={"space-between"} key={index}>*/}
          {/*          <MDTypography textTransform={"capitalize"} variant={"h6"}>*/}
          {/*            {s.name}*/}
          {/*          </MDTypography>*/}
          {/*          <Box*/}
          {/*            sx={(theme) => ({*/}
          {/*              m: 1,*/}
          {/*              px: 1,*/}
          {/*              bgcolor: "success.main",*/}
          {/*              color: "white.main",*/}
          {/*              border: "1px solid",*/}
          {/*              borderColor: "grey.300",*/}
          {/*              borderRadius: 2,*/}
          {/*              fontSize: "0.875rem",*/}
          {/*              fontWeight: "700",*/}
          {/*              ...theme.applyStyles("dark", {*/}
          {/*                bgcolor: "#101010",*/}
          {/*                color: "grey.300",*/}
          {/*                borderColor: "grey.800",*/}
          {/*              }),*/}
          {/*            })}*/}
          {/*          >*/}
          {/*            {s?.value?.average_score}*/}
          {/*          </Box>*/}
          {/*        </MDBox>*/}
          {/*      ))}*/}
          {/*  </MDBox>*/}
          {/*</Grid>*/}
        </Grid>
        <MDBox sx={{ my: 3 }}>
          <MessagesTable />
        </MDBox>
      </MDBox>
    </Container>
  );
};

export default _Index;

import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import PropTypes from "prop-types";
import { deleteItems } from "../utils/apiHelpers";
import { utils } from "../../../assets/assetsFunctions";
import AlertDialog from "../../../components/dialog/dialog";
import MDButton from "../../../components/MDButton";

const ActionMenu = ({ id, toHref, itemType, deleteFun }) => {
  const [menu, setMenu] = useState(null);
  const [deleted, setDeleted] = useState(false);
  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  async function deleteFunction() {
    let response;
    if (deleteFun) {
      response = await deleteFun(id);
    } else {
      response = await deleteItems(id, itemType);
    }

    if (utils.checkApiResponse(response)) {
      setDeleted(true);
      closeMenu();
    }
  }
  if (deleted) {
    return (
      <MDTypography
        width={"100%"}
        variant="text"
        color="error"
        // fontWeight="bold"
      >
        Deleted
      </MDTypography>
    );
  }
  return (
    <>
      <MDBox color="text" px={2}>
        <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
          settings
        </Icon>
      </MDBox>
      <Menu
        id="category_actions"
        anchorEl={menu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(menu)}
        onClose={closeMenu}
      >
        {toHref ? (
          <MenuItem onClick={closeMenu}>
            <MDTypography
              href={`${toHref}=${id}`}
              component={"a"}
              variant="text"
              color="text"
              width={"100%"}
              // fontWeight="bold"
            >
              Edit
            </MDTypography>
          </MenuItem>
        ) : null}

        {/*<MenuItem onClick={closeMenu}>Another action</MenuItem>*/}

        <AlertDialog
          onAgree={deleteFunction}
          triggerText={"Delete"}
          title={"This action is irreversible"}
          disagreeText={"Cancel"}
          content={`Do you want to permanently delete this item ?`}
          agreeText={"delete"}
          icon={
            <MenuItem>
              <MDTypography
                sx={{ textAlign: "start" }}
                variant="text"
                color="error"

                // fontWeight="bold"
              >
                Delete
              </MDTypography>
            </MenuItem>
          }
        />
      </Menu>
    </>
  );
};
export default ActionMenu;
ActionMenu.propTypes = {
  id: PropTypes.string.isRequired,
  toHref: PropTypes.string,
  itemType: PropTypes.string,
  deleteFun: PropTypes.func,
};

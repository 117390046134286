import React from "react";

import { DatePicker } from "../../../components/datePicker";
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import ListView from "./listView";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import workHoursReportHooks from "./workHoursReportHooks";
import { utils } from "../../../assets/assetsFunctions";

const WorkHoursReport = (props) => {
  const { handleChangeDate, submit, date, data } = workHoursReportHooks();
  return (
    <>
      <MDBox p={3}>
        <Stack
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
          }}
          mt={2}
          gap={1}
        >
          <Grid item>
            <DatePicker
              value={date.startDate}
              onChange={(e) => handleChangeDate(utils.handleChangeFormInputs(e, true, "startDate"))}
            />
          </Grid>
          <Grid item>
            <DatePicker
              value={date.endDate}
              onChange={(e) => handleChangeDate(utils.handleChangeFormInputs(e, true, "endDate"))}
            />
          </Grid>
          <Grid item>
            <MDButton fullWidth variant={"outlined"} color={"info"} onClick={() => submit()}>
              Search
            </MDButton>
          </Grid>
        </Stack>
        {/*this to choose multi users*/}
        {/*{users && (*/}
        {/*  <MultipleSelectPlaceholder*/}
        {/*    options={users}*/}
        {/*    handleChange={selectUser}*/}
        {/*    users={selectedUser}*/}
        {/*  />*/}
        {/*)}*/}
        <Divider />
      </MDBox>
      {data && <ListView workingDays={data} totalHours={data?.total_hours} date={date} />}
    </>
  );
};

export default React.memo(WorkHoursReport);

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MDButton from "../MDButton";
import MDBox from "../MDBox";
import { useEffect } from "react";
import MDTypography from "../MDTypography";
import Divider from "@mui/material/Divider";

export default function AlertDialog({
  disagreeText,
  agreeText,
  icon,
  id,
  onAgree,
  triggerText,
  content,
  disable,
  onClose,
  title,
}) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <MDBox>
      <MDBox display={"flex"}>
        {icon && (
          <IconButton
            sx={{ padding: 0, fontSize: "inherit" }}
            size={"small"}
            disabled={disable}
            // style={{ marginRight: "10px" }}
            aria-label="delete"
            onClick={(e) => {
              e.stopPropagation(); // Prevent event propagation
              handleClickOpen();
            }}
          >
            {icon}
          </IconButton>
        )}
      </MDBox>
      <Dialog transitionDuration={0} id={id} open={open} onClose={handleClose}>
        <MDBox>
          <DialogTitle bgcolor={"lightgray"}>
            <MDTypography variant={"span"} fontWeight="medium">
              {title}
            </MDTypography>
          </DialogTitle>
        </MDBox>
        <DialogContent>
          <DialogContentText>
            <MDTypography variant="button" fontWeight="medium" color={"text"}>
              {content}
            </MDTypography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              handleClose();
            }}
          >
            {disagreeText}
          </Button>
          <MDButton
            onClick={(e) => {
              onAgree(e);
              handleClose();
            }}
            color={"error"}
          >
            {agreeText}
          </MDButton>
        </DialogActions>
      </Dialog>
    </MDBox>
  );
}
AlertDialog.default = {
  openDialog: false,
};
AlertDialog.propTypes = {
  triggerText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  disagreeText: PropTypes.string.isRequired,
  agreeText: PropTypes.string.isRequired,
  id: PropTypes.string,
  onAgree: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  icon: PropTypes.node,
  disable: PropTypes.bool,
};

import React from "react";
import { Route, Routes } from "react-router-dom";
import LazyLoading from "../../components/lazyLoading";
import { TransactionsContextProvider } from "../../context/transactionsContext";

// Lazy loading components
const _Index = React.lazy(() => import("./_index"));
const DeleteUpdateTransaction = React.lazy(() => import("./deleteUpdateTransaction"));
const AddTransaction = React.lazy(() => import("./addTransaction"));
const ByUser = React.lazy(() => import("./byUser"));

const Index = () => {
  return (
    <LazyLoading>
      <TransactionsContextProvider>
        <Routes>
          <Route index element={<_Index />} />
          <Route path={"edit"} element={<DeleteUpdateTransaction />} />
          <Route path={"add"} element={<AddTransaction />} />
          <Route path={"byUser"} element={<ByUser />} />
        </Routes>
      </TransactionsContextProvider>
    </LazyLoading>
  );
};

export default Index;

import React, { useEffect, useState } from "react";
import { usersAPI } from "../../../../../assets/apiFunctions";
import PropTypes from "prop-types";
import BrandCard from "./index";
import "@thaddeusjiang/react-sortable-list/dist/index.css";
import { SortableList } from "@thaddeusjiang/react-sortable-list";

const CardsContainer = ({ brands }) => {
  const [users, setUsers] = useState(null);
  useEffect(() => {
    usersAPI.getUsers().then((res) => setUsers(res.data.data));
  }, []);
  useEffect(() => {}, [brands]);
  return (
    <>
      {brands &&
        brands
          .sort((a, b) => a.sort_index - b.sort_index)
          .map((brand) => {
            let manager = brand?.with_manager[0]?.with_user?.name || null;
            return (
              <BrandCard
                brandId={brand.id}
                key={brand.id}
                name={brand.name}
                manager={manager}
                email="oliver@burrito.com"
                users={users}
                isDisabled={brand.disabled}
              />
            );
          })}
    </>
  );
};
export default CardsContainer;
CardsContainer.propTypes = {
  brands: PropTypes.array,
};

import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import { CardContent, Stack } from "@mui/material";

const Index = ({ children }) => {
  return (
    <Card>
      <CardContent>
        <Stack gap={2}>{children}</Stack>
      </CardContent>
    </Card>
  );
};

export default Index;
Index.propTypes = {
  children: PropTypes.any.isRequired,
};

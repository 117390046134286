import React, { useState } from "react";

const EditCategoryHooksState = () => {
  const [cat, setCat] = useState(null);

  const [categoryStatus, setCategoryStatus] = useState({});
  const [images, setImages] = useState([]);
  const [fields, setFields] = useState([]);
  const [fieldsData, setFieldsData] = useState({});
  const [files, setFiles] = useState();
  return {
    cat,
    setCat,

    categoryStatus,
    setCategoryStatus,
    images,
    setImages,
    fields,
    setFields,
    fieldsData,
    setFieldsData,
    files,
    setFiles,
  };
};

export default EditCategoryHooksState;

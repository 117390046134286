import React, { useEffect, useState } from "react";
import TransactionsComponent from "../../../../layoutDependencies/transactionComponent";
import { brandAPI, transactionsAPI } from "../../../../assets/apiFunctions";
import moment from "moment";
import { utils } from "../../../../assets/assetsFunctions";
import Grid from "@mui/material/Grid";

const ManagerDashboard = (props) => {
  const today = moment();
  let oneDayBefore = today.clone().subtract(1, "days");
  oneDayBefore = utils.dateLocalization(oneDayBefore);

  const [date, setDate] = React.useState({
    startDate: oneDayBefore,
    endDate: oneDayBefore,
  });
  const [userTransaction, setUserTransaction] = useState(null);
  useEffect(() => {
    transactionsAPI
      .userTransactions({ ...date, withNullTransactions: true })
      .then((res) => setUserTransaction(res.data));
  }, []);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={8}>
        {userTransaction ? (
          <TransactionsComponent
            userTransactions={userTransaction}
            showDetails={true}
            title={"Yesterday"}
            isAdmin={true}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

export default ManagerDashboard;

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useMemo, useState } from "react";
import DataTable from "../../../../../examples/Tables/DataTable";
import MDTypography from "../../../../../components/MDTypography";
import MDBox from "../../../../../components/MDBox";
import { TBText } from "../../../../../components/tableComponents/tableComponents";
import { utils } from "../../../../../assets/assetsFunctions";
import { SERVER_IMAGES } from "../../../../../assets/axiosInstance";
import ActionMenu from "../../../components/actionMenu";

const List = ({ categories }) => {
  const Image = ({ src }) => (
    <MDBox
      sx={{ width: "100px", height: "100px", overflow: "hidden" }}
      variant="contained"
      color="text"
      fontWeight="medium"
    >
      <img
        src={`${SERVER_IMAGES}/${src}`}
        style={{ objectFit: "cover", maxWidth: "100%", height: "100%" }}
      />
    </MDBox>
  );

  const Status = ({ status }) => {
    if (status === 0 || status === false) {
      return (
        <MDBox
          // variant="gradient"
          bgcolor={"success"}
          color={"white"}
          coloredShadow={"dark"}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          // width="4rem"
          // height="4rem"
          px={1}
          py={0.5}
          // mt={-3}
        >
          <MDTypography variant="caption" color="white" fontWeight="bold">
            Published
          </MDTypography>
        </MDBox>
      );
    } else {
      return (
        <MDBox
          // variant="gradient"
          bgcolor={"warning"}
          color={"white"}
          coloredShadow={"dark"}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          // width="4rem"
          // height="4rem"
          px={1}
          py={0.5}
          // mt={-3}
        >
          <MDTypography variant="caption" color="white" fontWeight="bold">
            Unpublished
          </MDTypography>
        </MDBox>
      );
    }
  };

  const rows =
    categories &&
    categories.length > 0 &&
    categories.map((c, index) => ({
      id: utils.returnObjectFromArray(c?.translations)?.description,
      author: utils.returnObjectFromArray(c?.translations)?.description,

      parent: utils.returnObjectFromArray(c?.parent?.translations)?.description || "-",
      status: <Status status={c?.disabled} />,
      created: <TBText text={utils.monthYearFormat(c.created_at)} />,
      action: <ActionMenu id={c.id} toHref={"/product/edit?id"} itemType={"product"} />,
    }));
  const columns = [
    { Header: "", accessor: "id", align: "left", hidden: true },
    { Header: "Name", accessor: "author", align: "left" },
    { Header: "parent", accessor: "parent", align: "left" },
    // { Header: "Type", accessor: "type", align: "center" },
    // { Header: "Phone", accessor: "phone", align: "left" },
    // { Header: "Department", accessor: "department", align: "center" },
    { Header: "Status", accessor: "status", align: "left" },
    { Header: "Created", accessor: "created", align: "left" },
    { Header: "Created", accessor: "action", align: "left" },
  ];

  if (!rows || !columns) return null;

  return (
    <DataTable
      table={{ rows, columns }}
      isSorted={true}
      canSearch={true}
      entriesPerPage={true}
      showTotalEntries={true}
    />
  );
};

export default React.memo(List);

import React, { useCallback } from "react";

import SubmitForm from "../../../../components/forms/submitForm";
import useOffersHooks from "../../categories/pages/hooks/useOffersHooks";
import DropZone from "../../../../components/DropZone";
import Card from "@mui/material/Card";

const AddOffer = (props) => {
  const { namesInputs, handleChange, submit, data, setFiles, isLoading, progress } =
    useOffersHooks();
  const memoizedSetFiles = useCallback(
    (files) => {
      setFiles(files);
    },
    [setFiles]
  );
  return (
    <Card sx={{ marginY: 2 }}>
      <SubmitForm
        inputs={namesInputs}
        handleChange={handleChange}
        submit={submit}
        user={data}
        isAfter={true}
      >
        <DropZone uploadFiles={memoizedSetFiles} multiple={false} />
      </SubmitForm>
    </Card>
  );
};
export default AddOffer;

import React, { useEffect, useState } from "react";
import { createCategory, fetchLanguages } from "../../utils/apiHelpers";
import { handleInputChange, transformFields } from "../../utils/formUtils";
import categoryHooksStates from "./categoryHooksStates";
import { pushMessages } from "../../../../hooks/pushMessages";
import { submitCategory } from "../../utils/submitFunctions";

const UseCategoryHooks = () => {
  const { namesInputs, setNameInputs, data, setData, isLoading, setLoading, files, setFiles } =
    categoryHooksStates();
  const { showHideLoader, showMessage } = pushMessages();
  const submit = submitCategory(files, data, showHideLoader, showMessage);
  const handleChange = handleInputChange(setData);
  const [progress, setProgress] = useState(10);
  let formFields = [
    {
      name: "Category name",
      fields: {
        onChange: "handleChange",
        inputType: "input",
        hasParentKey: "name",
        required: false,
        type: "text",
      },
    },
    {
      name: "Category descriptions",
      fields: {
        onChange: "handleChange",
        inputType: "editor",
        hasParentKey: "description",
        required: false,
        type: "textArea",
      },
    },
  ];
  useEffect(() => {
    const fetchInputs = async () => {
      try {
        const languages = await fetchLanguages(); // Replace with your API endpoint
        const inputs = transformFields(languages, formFields);
        // const data = await response.json();
        setNameInputs(inputs);
      } catch (error) {
        console.error("Failed to fetch category inputs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInputs();
  }, []);

  return { namesInputs, handleChange, submit, data, setFiles, isLoading, progress };
};

export default UseCategoryHooks;
// "inertiajs/inertia-laravel": "^0.6.3",

import React, { useEffect, useState } from "react";
import { otherAPI } from "../../../assets/apiFunctions";
import List from "./list";

const View = () => {
  const [data, setData] = useState();
  useEffect(() => {
    otherAPI.getSubscribers().then((res) => setData(res.data.data));
  }, []);
  return <List data={data} />;
};

export default View;

import React, { useEffect, useState } from "react";
import LazyLoading from "../../components/lazyLoading";
import _index from "./_index";
import { Route, Routes } from "react-router-dom";

const ClientMessageView = React.lazy(() => import("./pages/view/clientMessageView"));

const Index = () => {
  return (
    <LazyLoading>
      <Routes>
        <Route index path={"/"} element={<_index />} />
        <Route path={"view"} element={<ClientMessageView />} />
      </Routes>
    </LazyLoading>
  );
};
export default Index;

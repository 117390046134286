import React, { useEffect, useState } from "react";
import { SetNotificationClass } from "../../../../assets/messageNotification";
import { utils } from "../../../../assets/assetsFunctions";
import productHooksStates from "./productHooksStates";
import { handleInputChange, transformFields } from "../../utils/formUtils";
import { createCategory, createProduct, fetchLanguages } from "../../utils/apiHelpers";
import { pushMessages } from "../../../../hooks/pushMessages";

const UseProductHooks = () => {
  const { namesInputs, setNameInputs, data, setData, isLoading, setLoading, files, setFiles } =
    productHooksStates();
  const handleChange = handleInputChange(setData);
  const [progress, setProgress] = useState(10);
  const { showHideLoader, showMessage } = pushMessages();
  async function submit(e) {
    let alert = new SetNotificationClass();
    e.preventDefault();
    showHideLoader(true);
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    files?.forEach((file, index) => {
      formData.append(`images[${index}]`, file);
    });
    try {
      const response = await createProduct(formData, {
        onUploadProgress: ({ loaded, total }) => setProgress((loaded / total) * 100),
      });
      const success = utils.checkApiResponse(response);
      if (success) {
        showMessage(alert.SUCCESS_OPERATION());
        // setData({});
        // setFiles([]);
      } else {
        showMessage(alert.FAILED_OPERATION());
      }
    } catch (error) {
      showMessage(alert.FAILED_OPERATION());
    } finally {
      showHideLoader(false);
    }
  }

  let formFields = [
    {
      name: "Product name",
      fields: {
        onChange: "handleChange",
        inputType: "input",
        hasParentKey: "name",
        name: "name",
        label: "",
        value: "",
        required: false,
        type: "text",
      },
    },
    {
      name: "Product descriptions",
      fields: {
        onChange: "handleChange",
        inputType: "editor",
        hasParentKey: "description",
        name: "",
        label: "",
        value: "",
        required: false,
        type: "textArea",
      },
    },
  ];
  useEffect(() => {
    const fetchInputs = async () => {
      try {
        const languages = await fetchLanguages(); // Replace with your API endpoint
        const inputs = transformFields(languages, formFields);
        // const data = await response.json();
        setNameInputs(inputs);
      } catch (error) {
        console.error("Failed to fetch category inputs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInputs();
  }, []);

  return { namesInputs, handleChange, submit, data, setFiles, isLoading, progress };
};

export default UseProductHooks;

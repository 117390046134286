const commonProps = {
  onChange: "handleChange",
  required: false,
  type: "number",
  inputType: "currency",
};

const patientDetailsFields = [
  {
    ...commonProps,
    name: "day",
    label: "Date of transaction",
    value: "day",
    inputType: "date",
    required: true,
  },
  {
    ...commonProps,
    name: "dollar",
    label: "Amount in dollar",
    value: "amount_dollar",
    required: false,
    icon: "USD",
  },
  {
    ...commonProps,
    name: "dinar",
    label: "Amount in Dinar",
    value: "dinar",
    required: false,
    icon: "IQD",
  },
];
const transactionNote = [
  {
    ...commonProps,
    name: "note",
    label: "Add a note",
    value: "note",
    required: false,
    type: "input",
    inputType: "textArea",
  },
];
const formFields = [
  { name: "Transaction", fields: patientDetailsFields },
  { name: "Note", fields: transactionNote },
];

export default formFields;

import React, { useEffect, useState } from "react";
import MDBox from "../../../../components/MDBox";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MDTypography from "../../../../components/MDTypography";
import PropTypes from "prop-types";

const ImagesActionMenu = ({ id, onDelete, setPrimary, forceClose }) => {
  const [menu, setMenu] = useState(null);
  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  useEffect(() => {
    if (menu) {
      setMenu(null);
    }
  }, [forceClose]);
  return (
    <>
      <MDBox color="info" px={2}>
        <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
          settings
        </Icon>
      </MDBox>
      <Menu
        id="category_actions"
        anchorEl={menu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(menu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={setPrimary}>
          <MDTypography
            width={"100%"}
            // href={`${toHref}=${id}`}
            // component={"a"}
            variant="text"
            color="text"
            // fontWeight="bold"
          >
            Make primary
          </MDTypography>
        </MenuItem>
        {/*<MenuItem onClick={closeMenu}>Another action</MenuItem>*/}
        <MenuItem onClick={onDelete}>
          <MDTypography
            width={"100%"}
            variant="text"
            color="error"
            // fontWeight="bold"
          >
            Delete
          </MDTypography>
        </MenuItem>
      </Menu>
    </>
  );
};
export default ImagesActionMenu;
ImagesActionMenu.propTypes = {
  id: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  setPrimary: PropTypes.func.isRequired,
  forceClose: PropTypes.bool,
};

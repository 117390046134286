import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import messageNotification, { SetNotificationClass } from "../../assets/messageNotification";
import { workingHoursAPI } from "../../assets/prefixApis/workingHoursAPI";
import { pushMessages } from "../../hooks/pushMessages";
import { utils } from "../../assets/assetsFunctions";
import moment from "moment/moment";

const WorkHoursHooks = () => {
  const date = moment();
  const [day, setDay] = useState(utils.dateLocalization(date)); //should the day be seperated
  const { showMessage, showHideLoader } = pushMessages();
  const setAlert = new SetNotificationClass();
  const [refresh, setRefresh] = useState(0);
  const [total, setTotal] = useState(0);
  const initial = {
    hours: [{ id: uuidv4(), from: "", to: "" }],
  };
  const [workingTimes, setWorkingTimes] = useState(initial);
  const [selectedUser, setSelectedUser] = useState([]);
  function edit() {
    setTotal(0);
    let totalTime = utils.calculateHours(workingTimes.hours);
    setTotal(totalTime);
    return totalTime;
  }
  async function addWarning(s) {
    if (Array.isArray(s)) {
      const updatedHours = [...workingTimes.hours]; // Create a copy of the hours array
      s.map((x) => {
        const index = updatedHours.findIndex((h) => h.id === x?.record_id);
        if (index !== -1) {
          updatedHours[index] = {
            ...updatedHours[index],
            error: `This period already exists from ${utils.removeSeconds(
              x.from
            )} to ${utils.removeSeconds(x.to)}for user ${selectedUser
              .filter((r) => r.id === x.user_id)
              .map((n) => n.name)}`,
          };
        }
      });
      setWorkingTimes((prev) => ({
        ...prev,
        hours: updatedHours,
      }));
    }
  }
  function handleResponse(res) {
    if (res.data.message === "success" && res?.data?.with_warnings.length == 0) {
      showMessage(setAlert.SUCCESS_OPERATION());
      setWorkingTimes(initial);
      setRefresh((refresh) => refresh + 1);
    } else if (res.data.message === "success" && res?.data?.with_warnings.length > 0) {
      addWarning(res.data.with_warnings);
      showMessage(setAlert.WARNING());
    }
  }
  function submit(e) {
    e.preventDefault();
    let letSubmit = false;
    if (workingTimes?.hours.length > 0) {
      workingTimes?.hours.map((e) => {
        if (!e?.from && !e?.to) {
          showMessage(setAlert.FILED_REQUIRED());
          return;
        }
        letSubmit = true;
      });
    }
    if (letSubmit) {
      edit();
      showHideLoader(true);
      const formData = new FormData();
      const data = { ...workingTimes, day: day };
      formData.append("date", JSON.stringify(data));
      formData.append("users", JSON.stringify(selectedUser?.map((user) => user.id)));
      workingHoursAPI
        .sendWorkingHours(formData)
        .then((res) => {
          handleResponse(res);
        })
        .catch((e) => console.log(e))
        .finally(() => showHideLoader(false));
    }
  }
  function handleTimeInput(name, value, index) {
    let time = moment(value._d).format("HH:mm");
    const arr = workingTimes.hours;
    arr[index] = { ...arr[index], [name]: time };
    setWorkingTimes({ ...workingTimes, hours: arr });
    setRefresh((refresh) => refresh + 1);
  }
  function deleteHour(index) {
    // Create a copy of the workingTimes state variable.
    const workingTimesCopy = workingTimes;
    //
    // // Use the splice() method to remove the object at the specified index from the hours array in the copy.
    workingTimesCopy.hours.splice(index, 1);
    // // Set the workingTimes state variable to the copy.
    setWorkingTimes(workingTimesCopy);
    setRefresh((refresh) => refresh + 1);
  }
  function checkFromMinTime(index) {
    // check if to time is not before the from time
    if (index === 0) {
      return null;
    }
    if (index > 0) {
      let from = workingTimes.hours[index - 1]?.to;
      from = from.split(":");
      return moment().set({ hours: from[0], minutes: from[1] });
    }
  }
  return {
    workingTimes,
    setWorkingTimes,
    refresh,
    handleTimeInput,
    submit,
    checkFromMinTime,
    deleteHour,
    edit,
    setDay,
    day,
  };
};
export default WorkHoursHooks;

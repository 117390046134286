import React, { useEffect, useState } from "react";
import { jobsAPI } from "../../../../assets/prefixApis/jobsAPI";
import {
  TBDelete,
  TBHyperLink,
  TBNameAndEmail,
  TBPhone,
  TBStatus,
  TBText,
} from "../../../../components/tableComponents/tableComponents";
import DataTable from "../../../../examples/Tables/DataTable";
import { roomsAPI } from "../../../../assets/prefixApis/roomsAPI";
import { utils } from "../../../../assets/assetsFunctions";
import { Icon } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MDBox from "../../../../components/MDBox";
import { useNavigate } from "react-router-dom";

const JobsTable = (props) => {
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    jobsAPI.getJob().then((res) => setData(res.data.data));
  }, []);

  // eslint-disable-next-line react/prop-types
  const Status = ({ status, id }) => {
    const [loadingButton, setLoadingButton] = useState(false);
    const [defaultStatus, setDefault] = useState(status);
    function handleChange() {
      setLoadingButton(true);
      jobsAPI
        .changeStatus({ status: defaultStatus }, id)
        .then((res) => {
          let intValue = defaultStatus ? 1 : 0;
          setDefault(!intValue);
          // onUserChange("update", data, id);
        })
        .finally(() => setLoadingButton(null));
    }
    function handleDelete() {
      setLoadingButton(true);
      jobsAPI
        .jobDelete(id)
        .then((res) => {
          if (res.data.code === 200) setData((prev) => prev.filter((d) => d.id !== id));
          // onUserChange("update", data, id);
        })
        .finally(() => setLoadingButton(null));
    }
    return (
      <MDBox sx={{ display: "flex" }}>
        <TBStatus
          defaultStatus={defaultStatus}
          callback={handleChange}
          loadingButton={loadingButton}
        />
        <IconButton
          disabled={loadingButton}
          aria-label="delete"
          onClick={(e) => {
            handleDelete();
          }}
        >
          <Icon color={"error"}>delete</Icon>
        </IconButton>
      </MDBox>
    );
  };
  const rows =
    data &&
    data.length > 0 &&
    data?.map((row) => ({
      author: (
        <TBNameAndEmail
          image={row.image}
          name={row?.job_title}
          email={row?.Applicant_email}
          onClick={() => navigate(`/jobs/edit/?id=${row.id}`)}
        />
      ),
      // department: <Department department={row?.department_name} />,
      department: <TBText text={row.department} />,
      status: <Status id={row.id} status={row.disabled} />,
      posted: <TBText text={utils.monthYearFormat(row.created_at)} />,
      endDate: <TBText text={utils.monthYearFormat(row.end_date)} />,
      // resume: <TBHyperLink text={"download Cv"} id={data.id} callback={() => getPdf(row.id)} />,
      // action: <Action action="Edit" id={row.id} />,
    }));

  const columns = [
    { Header: "Name", accessor: "author", width: "20%", align: "left" },
    { Header: "Department", accessor: "department", align: "left" },
    { Header: "Posted", accessor: "posted", align: "center" },
    { Header: "End Date", accessor: "endDate", align: "center" },
    { Header: "Status", accessor: "status", align: "center" },
  ];
  if (!rows || !columns) return null;
  return (
    <DataTable
      table={{ rows, columns }}
      isSorted={false}
      canSearch={true}
      entriesPerPage={true}
      showTotalEntries={false}
      noEndBorder
    />
  );
};

export default JobsTable;

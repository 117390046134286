import React from "react";
import MDBox from "../../../../../components/MDBox";
import Card from "@mui/material/Card";
import LayoutHeader from "../../../../../components/layoutHeader/layoutHeader";
import Calendar from "./calendar";
import { RatesAvailabilityContextProvider } from "../../../../../context/ratesAvailability";
import Grid from "@mui/material/Grid";
/**
rates And Availability
 */
const Index = () => {
  return (
    <RatesAvailabilityContextProvider>
      <MDBox pt={6} pb={3}>
        <Card sx={{ marginTop: 2 }}>
          <LayoutHeader title={"Edit Rates"} />
          <MDBox pt={3}></MDBox>
          <Grid container display="flex">
            <Grid item sm={12} lg={12}>
              <Calendar />
            </Grid>
          </Grid>
        </Card>
      </MDBox>
    </RatesAvailabilityContextProvider>
  );
};
export default Index;

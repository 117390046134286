import React, { Suspense } from "react";
import { contentLoader } from "../loader/loader";
import ErrorBoundary from "./errorHandling";
import PropTypes from "prop-types";

const LazyLoading = ({ children }) => {
  return (
    <ErrorBoundary>
      <Suspense fallback={contentLoader()}>{children}</Suspense>
    </ErrorBoundary>
  );
};
export default LazyLoading;
LazyLoading.propTypes = {
  children: PropTypes.any,
};

// src/utils/formUtils.js
export const handleInputChange = (setData) => (e) => {
  if (e.target?.dataset?.prop) {
    const { prop } = e.target.dataset;
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [prop]: {
        ...(prevData[prop] || {}),
        [name]: value,
      },
    }));
  } else {
    setData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  }
};

export const transformFields = (languages, baseData) =>
  baseData.map((m) => ({
    name: m.name,
    fields: languages.map((language) => ({
      ...m.fields,
      name: language.name,
      label: language.name,
      value: language.id,
      required: m.fields.name === "name" && language.name === "english" ? true : false, //this will make english name input is required
    })),
  }));

import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Container,
  Typography,
  Box,
  IconButton,
  ButtonGroup,
  Button,
  Badge,
  Divider,
} from "@mui/material";
import { CloudUpload, ViewModule, ViewList } from "@mui/icons-material";
import { FilesGrid } from "./files";
import { FilesList } from "./fileList";
import PropTypes from "prop-types";
// import FilesGrid from "./components/FilesGrid";
// import FilesList from "./components/FilesList";

const Dropzone = ({ uploadFiles, multiple = true }) => {
  const [isOver, setIsOver] = useState(false);
  const [files, setFiles] = useState([]);
  const [listStyle, setListStyle] = useState("list");

  const onDrop = (acceptedFiles) => {
    const updatedFiles = [...files, ...acceptedFiles];
    setFiles(updatedFiles);
    uploadFiles(updatedFiles);
    setIsOver(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: multiple,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
      "image/webp": [],
      "video/*": [],
    },
    onDropRejected: () => alert("file type is not accepted"),
    onDragEnter: () => setIsOver(true),
    onDragLeave: () => setIsOver(false),
    onDrop,
  });

  const removeFile = (fileToRemove) => {
    const updatedFiles = (files) => files.filter((file) => file !== fileToRemove);
    setFiles(updatedFiles);
    uploadFiles(updatedFiles);
    setIsOver(false);
  };

  return (
    <Box sx={{ mt: 4 }} width={"100%"}>
      <Typography variant="h4" gutterBottom>
        Upload Photos
      </Typography>
      {/*<Typography variant="subtitle1" gutterBottom>*/}
      {/*  Simple HTML5-compliant drag-and-drop zone for files built with React.js and Material-UI.*/}
      {/*</Typography>*/}

      {/* Dropzone */}
      <Box
        {...getRootProps()}
        sx={{
          border: (theme) =>
            `2px dashed ${isOver ? theme.palette.common.black : theme.palette.grey[400]}`,
          borderRadius: 2,
          p: 3,
          textAlign: "center",
          cursor: "pointer",
          backgroundColor: isOver ? "error" : "transparent",
        }}
      >
        <CloudUpload sx={{ fontSize: 60, mb: 2 }} />
        <Typography variant="h6">Upload Your Files</Typography>
        <Typography variant="body2" sx={{ mb: 1 }}>
          Drag a file here or <span style={{ color: "#1976d2", cursor: "pointer" }}>browse</span>{" "}
          for a file to upload.
        </Typography>
        <Typography variant="caption">
          JPG, JPEG , PNG and Webp. Please choose files under 150 MB for upload. File sizes are
          400x300px.
        </Typography>
        <input {...getInputProps()} />
      </Box>

      {/* Files List */}
      {files.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
            <Box display="flex" alignItems="center">
              <Divider orientation="vertical" flexItem />
              <Typography variant="h6" sx={{ ml: 2 }}>
                Attachments
                <Badge badgeContent={files.length} color="secondary" sx={{ ml: 1 }} />
              </Typography>
            </Box>
            <ButtonGroup size="small">
              <Button
                onClick={() => setListStyle("list")}
                variant={listStyle === "list" ? "contained" : "outlined"}
              >
                <ViewList />
              </Button>
              <Button
                onClick={() => setListStyle("grid")}
                variant={listStyle === "grid" ? "contained" : "outlined"}
              >
                <ViewModule />
              </Button>
            </ButtonGroup>
          </Box>

          {listStyle === "grid" ? (
            <FilesGrid files={files} onFileRemove={removeFile} />
          ) : (
            <FilesList files={files} onFileRemove={removeFile} />
          )}
        </Box>
      )}
    </Box>
  );
};

export default Dropzone;
Dropzone.propTypes = {
  uploadFiles: PropTypes.func,
  multiple: PropTypes.bool,
};

import React from "react";
import { contentLoader } from "../loader/loader";
import PropTypes from "prop-types";
import NoDataFound from "../NoDataFound";

const ConditionalRenderer = ({ data, isLoading, component }) => {
  const hasData = Array.isArray(data)
    ? data.length > 0
    : data && typeof data === "object" && Object.keys(data).length > 0;

  if (isLoading) return contentLoader();
  return hasData ? component : <NoDataFound />;
};

export default ConditionalRenderer;
ConditionalRenderer.propTypes = {
  data: PropTypes.any,
  isLoading: PropTypes.bool.isRequired,
  component: PropTypes.node.isRequired,
};

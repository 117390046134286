import React from "react";
import PropTypes from "prop-types";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import Divider from "@mui/material/Divider";

const LayoutHeader = ({ title, subtitle }) => {
  return (
    <MDBox

    // variant="gradient"
    // bgcolor="info"
    // borderRadius="lg"
    // coloredShadow="info"
    // sx={{
    //   position: "relative",
    // }}
    >
      <MDTypography
        variant="h3"
        fontWeight={"regular"}
        color="dark"
        sx={{
          "&:hover ~.desc": {
            opacity: 1,
          },
          width: "max-content",
        }}
      >
        {title}
      </MDTypography>

      <MDTypography
        variant="button"
        fontWeight={"regular"}
        color="text"
        sx={{
          "&:hover ~.desc": {
            opacity: 1,
          },
          width: "max-content",
        }}
      >
        {subtitle}
      </MDTypography>
      <Divider sx={{ m: 1 }} />
      {/*<MDTypography*/}
      {/*  variant="contained"*/}
      {/*  mt={1}*/}
      {/*  sx={{*/}
      {/*    background: "white",*/}
      {/*    width: "max-content",*/}
      {/*    opacity: 0,*/}
      {/*    transition: "all 1s ease",*/}
      {/*    zIndex: 2,*/}
      {/*    "&:before": {*/}
      {/*      content: `""`,*/}
      {/*      position: "absolute",*/}
      {/*      width: "20px",*/}
      {/*      top: "-18%",*/}
      {/*      left: "50%",*/}
      {/*      transform: `rotate(45deg)`,*/}
      {/*      // height: "20px",*/}
      {/*      background: "white",*/}
      {/*    },*/}
      {/*  }}*/}
      {/*  className={"desc"}*/}
      {/*  p={1}*/}
      {/*  bgcolor={"white"}*/}
      {/*>*/}
      {/*  {title}*/}
      {/*</MDTypography>*/}
    </MDBox>
  );
};

export default LayoutHeader;
LayoutHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

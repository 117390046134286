import { SetNotificationClass } from "../../../assets/messageNotification";
import { createCategory, createOffer } from "./apiHelpers";
import { utils } from "../../../assets/assetsFunctions";

const handleSubmission = async (apiCall, files, data, showHideLoader, showMessage, type) => {
  const alert = new SetNotificationClass();

  try {
    showHideLoader(true);

    // Prepare form data
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));

    files?.forEach((file, index) => {
      formData.append(`images[${index}]`, file);
    });

    // Await API response
    const response = await apiCall(formData);

    // Check API response success
    if (utils.checkApiResponse(response)) {
      showMessage(alert.SUCCESS_OPERATION());
      // Optionally reset state here if needed
    } else {
      showMessage(alert.FAILED_OPERATION());
    }
  } catch (error) {
    // Log and handle API or network error
    console.error(`Error while creating ${type}:`, error);
    showMessage(alert.FAILED_OPERATION());
  } finally {
    // Always hide the loader after the process is complete
    showHideLoader(false);
  }
};

export const submitCategory = (files, data, showHideLoader, showMessage) => async (e) => {
  e.preventDefault(); // Prevent default form submission behavior
  await handleSubmission(createCategory, files, data, showHideLoader, showMessage, "category");
};

export const submitOffer = (files, data, showHideLoader, showMessage) => async (e) => {
  e.preventDefault(); // Prevent default form submission behavior
  await handleSubmission(createOffer, files, data, showHideLoader, showMessage, "offer");
};

import React from "react";
import PropTypes from "prop-types";
import { ImageList, ImageListItem, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CategoryImage from "./categoryImage";

const CategoryMedia = ({ images }) => {
  if (!images) return null;
  return (
    <Box sx={{ p: 1 }}>
      <Typography variant="h4" gutterBottom>
        Photos
      </Typography>
      <ImageList sx={{ height: "auto" }} variant="quilted" cols={4} rowHeight={121}>
        {images?.map((item) => (
          <CategoryImage key={item.id} img={item} />
        ))}
      </ImageList>
    </Box>
  );
};
export default CategoryMedia;

CategoryMedia.propTypes = {
  images: PropTypes.array.isRequired,
};

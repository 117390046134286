import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "./context";
import GlobalContextProvider from "./context/globalContext";
const container = document.getElementById("app");
if (container) {
  document.title = process.env.REACT_APP_APP_NAME;
}
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <GlobalContextProvider>
        <App />
      </GlobalContextProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>
);

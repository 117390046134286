import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import MDBox from "@mui/material/Box";
import MDTypography from "@mui/material/Typography";
import PropTypes from "prop-types";
import ComboBox from "../comboBox/comboBox";
import { FormControl, Input, InputAdornment } from "@mui/material";
import MdSelect from "../MDSelect/MDSelect";
import MDRadio from "../MDRadio/MDRadio";
import { DatePicker } from "../datePicker";
import { utils } from "assets/assetsFunctions";
import MultipleSelectCheckmarks from "./checkMarksInput";
import Editor from "../Editor/editor";

function InputComponent({ e, user, handleChange }) {
  const renderLabel = () => (
    <InputLabel htmlFor={e?.name}>
      <MDTypography textTransform={"capitalize"} variant="caption" fontWeight="normal" color="text">
        {e?.label}
      </MDTypography>
    </InputLabel>
  );

  switch (e.inputType) {
    case "textArea":
      return (
        <Box sx={{ p: 1, width: "100%" }} justifySelf={"self-start"} key={e.name}>
          {renderLabel()}
          <TextField
            key={e.name}
            id={e.name}
            // id="outlined-multiline-flexible"
            aria-describedby="my-helper-text"
            onChange={handleChange}
            required={e.required}
            value={user && user.hasOwnProperty(e.name) ? user[e.name] : ""}
            name={e.name}
            // type={e.type}
            multiline
            rows={8}
            fullWidth
            xs={{ minWidth: "max-content" }}
            helperText={
              user?.error && user.error.hasOwnProperty(e.value) ? (
                <MDTypography variant={"h6"} color={"error"}>
                  {user.error[e.value].message}
                </MDTypography>
              ) : (
                ""
              )
            }
          />
        </Box>
      );
    case "editor":
      return (
        <Box sx={{ p: 1, width: "100%" }} justifySelf={"self-start"} key={e.name}>
          {renderLabel()}
          <Editor handleChange={handleChange} user={user} inputProps={e} />
        </Box>
      );

    case "input":
      let value = "";

      if (user) {
        if (!e.hasParentKey) {
          // When hasParentKey is false or not provided
          value = user.hasOwnProperty(e.name) && e.type !== "file" ? user[e.name] : "";
        } else {
          // When hasParentKey exists
          value =
            user.hasOwnProperty(e.hasParentKey) && user[e.hasParentKey].hasOwnProperty(e.name)
              ? user[e.hasParentKey][e.name]
              : "";
        }
      }
      return (
        <Box sx={{ p: 1, width: "100%" }} justifySelf={"self-start"}>
          {/*{renderLabel()}*/}
          <TextField
            inputProps={{
              "data-prop": e?.hasParentKey ? e.hasParentKey : null, // Conditionally set the data-prop
            }}
            id={e.name}
            inputMode={e?.type === "number" ? "tel" : "text"}
            key={e.name}
            variant="outlined"
            label={e.label}
            // id={e.name}
            // aria-describedby="my-helper-text"
            onChange={handleChange}
            required={e.required}
            // value={user && user.hasOwnProperty(e.name) && e.type !== "file" ? user[e.name] : ""}
            value={value}
            name={e.name}
            type={e.type}
            fullWidth
            sx={{ backgroundColor: "rgba(218,218,218,0.51)" }}
            xs={{ minWidth: "min-content" }}
            helperText={
              user?.error && user.error.hasOwnProperty(e.value) ? (
                <MDTypography variant={"h6"} color={"error"}>
                  {user.error[e.value].message}
                </MDTypography>
              ) : (
                ""
              )
            }
          />
        </Box>
      );
    case "currency":
      return (
        <Box sx={{ m: 1 }} justifySelf={"self-start"} xs={12} sm={9} lg={4}>
          <FormControl fullWidth>
            <InputLabel htmlFor={e.name}> {e.label}</InputLabel>

            <Input
              type={"number"}
              inputMode={"tel"}
              id={e.name}
              name={e.name}
              pattern="[0-9]*"
              value={user && user.hasOwnProperty(e.name) ? user[e.name] : ""}
              onChange={handleChange}
              startAdornment={<InputAdornment position="start">{e?.icon}</InputAdornment>}
            />
          </FormControl>
        </Box>
      );
    case "switch":
      return (
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <Switch checked={true} />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Email me when someone follows me
            </MDTypography>
          </MDBox>
        </MDBox>
      );
    case "combobox":
      return (
        <Box sx={{ p: 1, width: "100%" }} justifySelf={"self-start"}>
          <FormControl fullWidth>
            <ComboBox handleChange={handleChange} user={user} inputProps={e} />
          </FormControl>
        </Box>
      );
    case "select":
      return (
        <MdSelect
          properties={e}
          label={renderLabel()}
          value={user && user.hasOwnProperty(e.name) ? user[e.name] : ""}
          handleChange={handleChange}
        />
      );
    case "radio":
      return (
        <MDRadio
          properties={e}
          label={renderLabel()}
          value={user && user.hasOwnProperty(e.name) ? user[e.name] : ""}
          handleChange={handleChange}
          defaultValue={user && user.hasOwnProperty(e.name) ? user[e.name] : ""}
        />
      );
    case "date":
      return (
        <Box sx={{ m: 1 }} justifySelf={"self-start"} xs={12} sm={9} lg={4}>
          {renderLabel()}
          <DatePicker
            key={e.name}
            // id={e.name}
            onChange={(d) => handleChange(utils.handleChangeFormInputs(d, true, e?.name))}
            value={user && user.hasOwnProperty(e.name) ? user[e.name] : ""}
            name={e.name}
            fullWidth
          />
        </Box>
      );
    case "checkMarks":
      return (
        <Box xs={12}>
          {renderLabel()}
          <MultipleSelectCheckmarks
            onChange={handleChange}
            valueInput={user && user.hasOwnProperty(e.name) ? user[e.name] : ""}
            name={e.name}
            optionData={e?.options}
          />
          {/*  key={e.name}*/}
          {/*  // id={e.name}*/}
          {/*  onChange={(d) => handleChange(utils.handleChangeFormInputs(d, true, e?.name))}*/}
          {/*  value={user && user.hasOwnProperty(e.name) ? user[e.name] : ""}*/}
          {/*  name={e.name}*/}
          {/*  fullWidth*/}
          {/*/>*/}
        </Box>
      );
    default:
      return null;
  }
}
export default React.memo(InputComponent);
InputComponent.propTypes = {
  e: PropTypes.any,
  user: PropTypes.any,
  handleChange: PropTypes.any,
};

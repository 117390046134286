import React, { useState } from "react";

import { SetNotificationClass } from "../../../../assets/messageNotification";
import { utils } from "../../../../assets/assetsFunctions";
import useFormData from "../../../../hooks/useFormData";
import { commerceAPI } from "../../../../assets/prefixApis/commerceAPI";
import { pushMessages } from "../../../../hooks/pushMessages";

const ParentCategoryHooks = (parent) => {
  const { showMessage } = pushMessages();
  const categoryId = parent?.id;
  let alert = new SetNotificationClass();
  const [text, setText] = useState(
    utils.returnObjectFromArray(parent?.parent?.translations)?.label
  );
  const [isParent, setIsParent] = useState(false);
  const [newParent, setParent] = useState(false);
  const [forceClose, setForceClose] = useState(false);
  let input = {
    onChange: "handleChange",
    name: "parent",
    label: "Parent category",
    value: "parent",
    required: false,
    inputType: "combobox",
    options: "categories",
  };
  function f(e) {
    setParent({ [e.target.name]: e.target.value });
  }
  function changeParent() {
    let formData;
    let data;
    if (isParent) {
      formData = useFormData({ name: "data", data: { is_parent: true, cat_id: categoryId } });
    } else {
      if (!newParent) {
        alert.message = "cannot be empty";
        showMessage(alert.FAILED_OPERATION());
        return;
      }
      data = newParent.parent;
      if (data.id === categoryId) {
        alert.message = "Cannot set a parent to itself.";
        showMessage(alert.FAILED_OPERATION());
        return;
      } else {
        data.parent_id = data.id;
        data.cat_id = categoryId;
        formData = useFormData({ name: "data", data: data });
      }
    }
    commerceAPI.category
      .changeParent(formData)
      .then((res) => {
        let success = utils.checkApiResponse(res);
        if (success) {
          if (isParent) {
            setIsParent(false);
            setText("");
          } else {
            setText(data?.label);
          }
          setForceClose(!forceClose);
          showMessage(alert.SUCCESS_OPERATION());
        }
      })
      .catch((e) => {
        alert.message = e.response.data.message;
        showMessage(alert.FAILED_OPERATION());
      });
  }
  function checkBoxChange() {
    setIsParent(!isParent);
  }

  return {
    text,
    newParent,
    isParent,
    checkBoxChange,
    changeParent,
    forceClose,
    input,
    f,
  };
};

export default ParentCategoryHooks;

import React, { useEffect, useState } from "react";
import MDBox from "../../../../../components/MDBox";
import PropTypes from "prop-types";
import { brandAPI, roomsAPI } from "../../../../../assets/apiFunctions";
import useFormData from "../../../../../hooks/useFormData";
import { TBStatus } from "../../../../../components/tableComponents/tableComponents";
import MdSelect from "../../../../../components/MDSelect/MDSelect";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import MDTypography from "../../../../../components/MDTypography";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import MDButton from "../../../../../components/MDButton";
import Box from "@mui/material/Box";

const Actions = ({ users, brandId, onSuccess, isDisabled }) => {
  const [loadingButton, setLoadingButton] = useState(false);
  const [defaultStatus, setDefault] = useState(isDisabled);
  const [openAlert, setOpenAlert] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  function handleResponse(res) {
    if (res.data.code === 207) {
      setOpenAlert(true);
    } else {
      onSuccess({
        name: "manager",
        value: users.filter((e) => e.id === selectedUser)[0].name,
      });
    }
  }
  async function changeBrandManager(event) {
    // if statement is to prevent process when default value is chosen
    if (event.target.value !== 1) {
      setSelectedUser(event.target.value);
    }
  }

  function submitChangeManager(confirmed) {
    let data = null;
    if (!confirmed) {
      data = useFormData({
        name: "data",
        data: { brandId, userId: selectedUser },
      });
    } else if (confirmed) {
      data = useFormData({
        name: "data",
        data: { brandId, userId: selectedUser, updateConfirmed: true },
      });
    }
    brandAPI.setBrandManager(data).then((res) => handleResponse(res));
  }

  useEffect(() => {
    if (selectedUser) {
      submitChangeManager(false);
    }
  }, [selectedUser]);
  function handleChangeBrandStatus() {
    setLoadingButton(true);
    brandAPI
      .changeStatus({ status: defaultStatus }, brandId)
      .then((res) => {
        let intValue = defaultStatus ? 1 : 0;
        setDefault(!intValue);
        onSuccess({ name: "isDisabled", value: !intValue });
        // onUserChange("update", data, id);
      })
      .finally(() => setLoadingButton(null));
  }
  function handleCloseAlert() {
    setOpenAlert(false);
  }
  return (
    <MDBox
      width={"100%"}
      display="flex"
      gap={2}
      alignItems={"center"}
      justifyContent={{ xs: "space-between", md: "end" }}
    >
      <Dialog
        id={brandId}
        open={openAlert}
        onClose={handleCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <MDBox bgcolor={"grey-100"}>
          <DialogTitle>
            <MDTypography variant="h6" fontWeight="medium">
              change brand manager
            </MDTypography>
          </DialogTitle>
        </MDBox>
        <DialogContent>
          <DialogContentText>
            This user is already connected to a brand , do you want to change?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleCloseAlert();
              setSelectedUser(null);
            }}
          >
            cancel
          </Button>
          <MDButton
            onClick={(e) => {
              submitChangeManager(true);
              handleCloseAlert();
            }}
            color={"error"}
          >
            confirm
          </MDButton>
        </DialogActions>
      </Dialog>
      {/*<AlertDialog*/}
      {/*  id={brandId}*/}
      {/*  openDialog={openAlert}*/}
      {/*  title={"Change brand manager"}*/}
      {/*  onAgree={() => submitChangeManager(true)}*/}
      {/*  content={`This user is already connected to a brand , do you want to change?`}*/}
      {/*  triggerText={"do you want to continue"}*/}
      {/*  disagreeText={"cancel"}*/}
      {/*  agreeText={"confirm"}*/}
      {/*  onClose={() => {*/}
      {/*    setOpenAlert(false);*/}
      {/*    setSelectedUser(null);*/}
      {/*  }}*/}
      {/*/>*/}
      <MDBox width={"15rem"}>
        <Box justifySelf={"self-start"} xs={12} sm={9} lg={4}>
          <MdSelect
            properties={{ label: "Change Manager" }}
            optionData={users}
            value={selectedUser ? selectedUser : ""}
            handleChange={(e) => changeBrandManager(e)}
            // inputProps={{
            //   name: "age",
            //   id: { brandId },
            // }}
          />
        </Box>
      </MDBox>
      <MDBox>
        <TBStatus
          defaultStatus={defaultStatus}
          callback={handleChangeBrandStatus}
          loadingButton={loadingButton}
        />
      </MDBox>
      {/*<MDButton variant="text" color={"dark"}>*/}
      {/*  <Icon>edit</Icon>&nbsp;edit*/}
      {/*</MDButton>*/}
    </MDBox>
  );
};

export default Actions;
Actions.propTypes = {
  users: PropTypes.any,
  brandId: PropTypes.string,
  onSuccess: PropTypes.func,
  //sometimes get 0 or 1
  isDisabled: PropTypes.any,
};

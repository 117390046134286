import React, { useState } from "react";
import ImageContainer from "../../components/Media/imageContainer";
import { commerceAPI } from "../../../../assets/prefixApis/commerceAPI";
import { utils } from "../../../../assets/assetsFunctions";
import PropTypes from "prop-types";
import ItemImagesHooks from "../../components/Media/itemImagesHooks";

const CategoryImage = ({ img }) => {
  const { deleted, setDeleted, isPrimary, setIsPrimary, forceClose, setForceClose } =
    ItemImagesHooks();

  function onDelete() {
    commerceAPI.category.deleteProductImage(img.id).then((res) => {
      let success = utils.checkApiResponse(res);
      if (success) {
        setDeleted(true);
      }
    });
  }
  function setPrimary() {
    commerceAPI.category.setPrimaryImage(img.id).then((res) => {
      let success = utils.checkApiResponse(res);
      if (success) {
        setIsPrimary(true);
        setForceClose(!forceClose);
      }
    });
  }
  if (deleted) return null;
  return (
    <ImageContainer
      img={img}
      onDelete={onDelete}
      setPrimary={setPrimary}
      isPrimary={isPrimary}
      forceClose={forceClose}
    />
  );
};

export default CategoryImage;
CategoryImage.propTypes = {
  img: PropTypes.object.isRequired,
};

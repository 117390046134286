import React, { useContext, useEffect, useState } from "react";
import { workingHoursAPI } from "../../../assets/apiFunctions";
import { Paper } from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import WorkingHoursList from "../workingHoursList";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import PropTypes from "prop-types";
import moment from "moment";
import { WorkingHoursContext } from "../../../context/workingHoursContext";
import { utils } from "../../../assets/assetsFunctions";
import { contentLoader } from "../../../components/loader/loader";

export const GetHoursBetweenDates = React.memo((props) => {
  const { userWhMonthly, setUserWhMonthly } = useContext(WorkingHoursContext);
  const date = moment();
  const { data, getPending } = props;
  const beforeMonth = date.clone().subtract(1, "months");

  function getData() {
    if (!data) {
      workingHoursAPI
        .getHoursBetweenDates({
          start_date: beforeMonth.format("D-M-Y"),
          end_date: date.format("D-M-Y"),
        })
        .then((res) => {
          const working_days = res.data.working_days[0].working_days.sort(utils.compareDays);
          setUserWhMonthly(() => ({
            total_hours: res.data.total_hours,
            working_days,
          }));
        })
        .catch(console.log);
    }
  }

  useEffect(() => {
    getData();
  }, []);
  return (
    <Paper sx={{ boxShadow: "none" }}>
      <Divider orientation="vertical" sx={{ mx: 0 }} />
      <Grid item xs={12} xl={4}>
        {data || userWhMonthly ? (
          data?.total_hours !== 0 && userWhMonthly?.total_hours !== 0 ? (
            <WorkingHoursList
              title={`Working hours for the last month`}
              data={data || userWhMonthly}
              shadow={false}
              dateProps={date}
            />
          ) : (
            <MDBox style={{ display: "flex", justifyContent: "center" }}>
              <MDTypography py={2} variant="h6" fontWeight="light">
                No working hours for {date}
              </MDTypography>
            </MDBox>
          )
        ) : (
          contentLoader()
        )}
      </Grid>
    </Paper>
  );
});
GetHoursBetweenDates.propTypes = {
  data: PropTypes.array,
  getPending: PropTypes.bool.isRequired,
  isUser: PropTypes.bool.isRequired,
};

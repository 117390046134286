import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "../../../../components/MDBox";
import ComplexStatisticsCard from "../../../../examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { utils } from "../../../../assets/assetsFunctions";
import moment from "moment/moment";
import { transactionsAPI } from "../../../../assets/apiFunctions";

const Index = () => {
  const [data, setData] = useState(null);
  // useEffect(() => {
  //   transactionsAPI
  //     .getYesterdayTransaction({ today: utils.dateLocalization(moment()) })
  //     .then((res) => setData(res.data));
  // }, []);
  return (
    <>
      <Grid item xs={12} md={6} lg={4}>
        <MDBox mb={1.5}>
          <ComplexStatisticsCard
            color="dark"
            icon="calendar_today"
            title="Brands"
            count={12}
            percentage={{
              color: "success",
              amount: "12",
              label: "Total brands in your group",
            }}
          />
        </MDBox>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <MDBox mb={1.5}>
          <ComplexStatisticsCard
            icon="attach_money_icon"
            color="success"
            title="Total Products"
            count={10}
            percentage={{
              color: "success",
              amount: "Date:",
              label: "Products",
            }}
          />
        </MDBox>
      </Grid>

      {/*{data && (*/}
      {/*  <>*/}
      {/*    <Grid item xs={12} md={6} lg={4}>*/}
      {/*      <MDBox mb={1.5}>*/}
      {/*        <ComplexStatisticsCard*/}
      {/*          icon="attach_money_icon"*/}
      {/*          color="success"*/}
      {/*          title="Yesterday's income (all brands) "*/}
      {/*          count={data.data?.usd}*/}
      {/*          percentage={{*/}
      {/*            color: "success",*/}
      {/*            amount: "Date:",*/}
      {/*            label: data.day,*/}
      {/*          }}*/}
      {/*        />*/}
      {/*      </MDBox>*/}
      {/*    </Grid>{" "}*/}
      {/*    <Grid item xs={12} md={6} lg={4}>*/}
      {/*      <MDBox mb={1.5}>*/}
      {/*        <ComplexStatisticsCard*/}
      {/*          color="warning"*/}
      {/*          icon="money"*/}
      {/*          title="Yesterday's income (all brands) "*/}
      {/*          count={data.data?.iqd}*/}
      {/*          percentage={{*/}
      {/*            color: "success",*/}
      {/*            amount: "Date:",*/}
      {/*            label: data.day,*/}
      {/*          }}*/}
      {/*        />*/}
      {/*      </MDBox>*/}
      {/*    </Grid>*/}
      {/*  </>*/}
      {/*)}*/}
      {/*<Grid item xs={12} md={6} lg={4}>*/}
      {/*  <MDBox mb={1.5}>*/}
      {/*    <ComplexStatisticsCard*/}
      {/*      color="dark"*/}
      {/*      icon="euro"*/}
      {/*      title="Monthly income"*/}
      {/*      count="not enough data"*/}
      {/*      percentage={{*/}
      {/*        color: "success",*/}
      {/*        amount: "+1%",*/}
      {/*        label: "than last month",*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </MDBox>*/}
      {/*</Grid>*/}
    </>
  );
};
export default Index;

import React from "react";
import { PieChart, Pie, Cell } from "recharts";
import MDTypography from "../MDTypography";
import MDBox from "../MDBox";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
// import colors from "./../../../colors";

// const data = [
//   { name: "Group A", value: 200 },
//   { name: "Group B", value: 300 },
//   { name: "Group C", value: 700 },
// ];
const COLORS = [
  "#1D3557",
  "#F1FAEE",
  "#A8DADC",
  "#457B9D",
  "#E63946",
  "#F1C6E8",
  "#FDCB82",
  "#6D8F9C",
  "#9AE3D0",
  "#2A9D8F",
  "#F4A261",
  "#264653",
];

// eslint-disable-next-line react/prop-types
const TinyDonutChart = ({ data }) => {
  function shuffleArray(array) {
    return array.sort(() => Math.random() - 0.5);
  }

  // Shuffling the colors
  const shuffledColors = shuffleArray([...COLORS]);
  let index = 0;

  const total = data.reduce((sum, item) => sum + item.value, 0);
  const dataWithColors = data.map((v) => ({
    ...v,
    color: shuffledColors[index++ % shuffledColors.length],
    percentage: ((v.value / total) * 100).toFixed(1), // 1 decimal place
  }));
  // Calculate percentages and map the data
  return (
    <MDBox
      display={"flex"}
      flexDirection={"column"}
      gap={1}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <MDBox display={"flex"} alignItems={"center"} gap={2}>
        <MDBox display={"flex"}>
          <PieChart width={150} height={150}>
            <Pie
              data={dataWithColors}
              dataKey="value"
              stroke={"white"}
              innerRadius={50} // Increase inner radius
              outerRadius={70} // Increase outer radius
              fill="#8884d8"
            >
              {dataWithColors?.map((entry, index) => (
                <Cell key={index} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </MDBox>
        <MDBox>
          {dataWithColors.map((d, index) => (
            <MDBox key={index} display={"flex"} gap={2}>
              <Icon style={{ color: d.color }}>circleIcon</Icon>
              <MDBox display={"flex"} gap={1}>
                <MDTypography
                  component="p"
                  variant="h6"
                  color="text"
                  display="flex"
                  fontWeight={"bold"}
                >
                  {d.value}
                </MDTypography>{" "}
                <MDTypography
                  component="p"
                  variant="h6"
                  color="text"
                  display="flex"
                  textTransform={"capitalize"}
                >
                  {d.name}
                </MDTypography>
              </MDBox>
            </MDBox>
          ))}
        </MDBox>
      </MDBox>
      <MDBox display={"flex"} gap={2}>
        {dataWithColors.map((d, index) => (
          <MDBox key={index} display={"flex"} gap={1}>
            <Icon style={{ color: d.color }}>circleIcon</Icon>
            <MDBox display={"flex"}>
              <MDTypography
                component="p"
                variant="h6"
                color="text"
                display="flex"
                fontWeight={"bold"}
              >
                %{d.percentage}
              </MDTypography>{" "}
              {/*<MDTypography component="p" variant="h6" color="text" display="flex">*/}
              {/*  {d.name}*/}
              {/*</MDTypography>*/}
            </MDBox>
          </MDBox>
        ))}
      </MDBox>
    </MDBox>
  );
};
TinyDonutChart.defaultValue = {
  data: [],
};
TinyDonutChart.propTypes = {
  data: PropTypes.array,
};
export { TinyDonutChart };

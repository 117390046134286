/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from "react";
import { jobsAPI } from "../../../assets/prefixApis/jobsAPI";
import {
  TBHyperLink,
  TBNameAndEmail,
  TBPhone,
  TBText,
} from "../../../components/tableComponents/tableComponents";
import DataTable from "../../../examples/Tables/DataTable";
import { useNavigate } from "react-router-dom";

const JobApplicantsTable = () => {
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  async function getPdf(id) {
    const response = await jobsAPI.getResume(id);
    const contentDisposition = response.headers["file-name"];
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", contentDisposition); // Set the file name
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  }
  useEffect(() => {
    jobsAPI.getApplication().then((res) => setData(res.data.data));
  }, []);
  // const [loadingButton, setLoadingButton] = useState(null);
  // function handleChangeUserStatus(status, id) {
  //   setLoadingButton(id);
  //   usersAPI
  //     .disableUser({ status: status }, id)
  //     .then((res) => {
  //       const data = { status: !status };
  //       onUserChange("update", data, id);
  //     })
  //     .finally(() => setLoadingButton(null));
  // }
  //
  // function handleDelete(id) {
  //   usersAPI
  //     .deleteUser(id)
  //     .then((res) => {
  //       onUserChange("delete", {}, id);
  //     })
  //     .catch((e) => console.log(e));
  // }

  const rows =
    data &&
    data.length > 0 &&
    data?.map((row) => ({
      author: (
        <TBNameAndEmail
          sx={{ cursor: "pointer" }}
          image={row.image}
          name={row.Applicant_name}
          email={row.Applicant_email}
          onClick={() => navigate(`/jobs/applicable-profile/?id=${row.id}`)}
        />
      ),
      // department: <Department department={row?.department_name} />,
      phone: <TBPhone phone={row.Applicant_telephone} />,
      job: <TBText text={row.with_career.job_title} />,
      expYears: <TBText text={row.Applicant_years_of_exp} />,
      resume: <TBHyperLink text={"download Cv"} id={data.id} callback={() => getPdf(row.id)} />,
      // action: <Action action="Edit" id={row.id} />,
    }));

  const columns = [
    { Header: "Name", accessor: "author", width: "20%", align: "left" },
    { Header: "Phone", accessor: "phone", align: "left" },
    { Header: "Job Title", accessor: "job", align: "center" },
    { Header: "Experience", accessor: "expYears", align: "center" },
    { Header: "Resume", accessor: "resume", align: "center" },
  ];
  if (!rows || !columns) return null;
  return (
    <DataTable
      table={{ rows, columns }}
      isSorted={false}
      canSearch={true}
      entriesPerPage={true}
      showTotalEntries={false}
      noEndBorder
    />
  );
};

export default React.memo(JobApplicantsTable);

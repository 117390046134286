import React, { useState } from "react";
import PropTypes from "prop-types";
import MDBox from "../../../../components/MDBox";
import MDButton from "../../../../components/MDButton";
import CircularProgress from "@mui/material/CircularProgress";
import MDTypography from "../../../../components/MDTypography";
import SideCard from "../../../../components/SideCard";
import { commerceAPI } from "../../../../assets/apiFunctions";
import { utils } from "../../../../assets/assetsFunctions";
import { SetNotificationClass } from "../../../../assets/messageNotification";
import { useCommerceContextVariables } from "../../../../context/CommerceContext";
import { pushMessages } from "../../../../hooks/pushMessages";

const CategoryStatus = ({ status }) => {
  const { showMessage, showHideLoader } = pushMessages();
  const id = status.id;
  const isPublished = status.status;
  const [loadingButton, setLoading] = useState(false);
  const [defaultStatus, setDefault] = useState(isPublished);
  function changeCategoryStatus() {
    let alert = new SetNotificationClass();

    setLoading(true);
    commerceAPI.category
      .changeStatus(id)
      .then((res) => {
        let success = utils.checkApiResponse(res);
        if (success) {
          setDefault(res.data.data);
        } else {
          showMessage(alert.FAILED_OPERATION());
        }
      })
      .catch((e) => showMessage(alert.FAILED_OPERATION()))
      .finally(() => setLoading(false));
  }
  return (
    <SideCard>
      <MDTypography variant="h6" color="dark" fontWeight="bold">
        Product status
      </MDTypography>
      <MDBox display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <MDTypography variant="caption" color="text" fontWeight="regular">
          Status
        </MDTypography>
        <MDBox>
          <MDButton
            size={"small"}
            variant={"text"}
            onClick={changeCategoryStatus}
            disabled={loadingButton}
          >
            {loadingButton ? (
              <CircularProgress color={"secondary"} size={"20px"} />
            ) : defaultStatus === 0 || defaultStatus === false ? (
              <MDBox
                // variant="gradient"
                bgcolor={"success"}
                color={"white"}
                coloredShadow={"dark"}
                borderRadius="xl"
                display="flex"
                justifyContent="center"
                alignItems="center"
                px={1}
                py={0.5}
                // mt={-3}
              >
                <MDTypography variant="caption" color="white" fontWeight="bold">
                  Published
                </MDTypography>
              </MDBox>
            ) : (
              <MDBox
                // variant="gradient"
                bgcolor={"warning"}
                color={"white"}
                coloredShadow={"dark"}
                borderRadius="xl"
                display="flex"
                justifyContent="center"
                alignItems="center"
                px={1}
                py={0.5}
                // mt={-3}
              >
                <MDTypography variant="caption" color="white" fontWeight="bold">
                  Unpublished
                </MDTypography>
              </MDBox>
            )}
          </MDButton>
        </MDBox>
      </MDBox>
    </SideCard>
  );
};
export default CategoryStatus;
CategoryStatus.propTypes = {
  status: PropTypes.object,
};

import React, { useEffect, useState } from "react";
import LayoutHeader from "../../../../../components/layoutHeader/layoutHeader";
import List from "./list";
import { commerceAPI } from "../../../../../assets/apiFunctions";
import LinkButton from "../../../components/linkButton";
import { Stack } from "@mui/material";
import MdContainer from "../../../../../components/Container/mdContainer";

const View = (props) => {
  const [cat, setCat] = useState();
  useEffect(() => {
    commerceAPI.category.getAll().then((res) => setCat(res.data.data));
  }, []);
  return (
    <MdContainer>
      <LayoutHeader
        title={"Add Category"}
        subtitle={"Quickly register new category and optimize your setup."}
      />
      <Stack rowGap={2}>
        <LinkButton href={"/category"} />
        <List categories={cat} />
      </Stack>
    </MdContainer>
  );
};

export default View;

import React, { useEffect, useState } from "react";
import List from "./list";
import { commerceAPI } from "../../../../assets/apiFunctions";

const ViewOffers = (props) => {
  const [offers, setOffers] = useState();
  useEffect(() => {
    commerceAPI.offer.get().then((res) => setOffers(res.data.data));
  }, []);
  return (
    <div>
      <List offers={offers} />
    </div>
  );
};

export default ViewOffers;

import React from "react";
import MDBox from "../../../../components/MDBox";
import MDButton from "../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import MDTypography from "../../../../components/MDTypography";
import PropTypes from "prop-types";
import { utils } from "../../../../assets/assetsFunctions";
import CurrencyTextField from "../../../../components/MDTypography/currency";
import { workingHoursAPI } from "../../../../assets/prefixApis/workingHoursAPI";
import { brandAPI } from "../../../../assets/apiFunctions";
import moment from "moment";
import AlertDialog from "../../../../components/dialog/dialog";

const RenderTransactionComp = ({ transaction, isAdmin }) => {
  const [disable, setDisable] = React.useState(false);
  const [isDeleted, setIsDeleted] = React.useState(false);
  function handleDelete() {
    setDisable(true);
    brandAPI.deleteTransaction(transaction.id).then((res) => {
      if (res.data.code === 200) {
        setIsDeleted(true);
      } else {
        setDisable(false);
      }
    });
  }
  const actions = () => {
    if (transaction.status !== "accepted") {
      return (
        <AlertDialog
          disable={disable}
          onAgree={handleDelete}
          triggerText={"yes"}
          title={"Delete Transaction"}
          disagreeText={"Cancel"}
          agreeText={"delete"}
          content={`Do you want to delete this Transaction?`}
          icon={<Icon color={"error"}>delete</Icon>}
        />
        // <MDButton size={"medium"} disabled={disable} onClick={handleDelete} variant={"text"}>
        //   <Icon color={"error"}>delete</Icon>
        // </MDButton>
      );
    } else {
      return (
        <MDBox display={"flex"} disabled={disable}>
          <MDButton size={"large"} onClick={() => handleUserWorkingHours("pending")}>
            <Icon color={"success"}>restore</Icon>
          </MDButton>{" "}
          {/*<MDButton size={"large"} disabled={disable}>*/}
          {/*  <Icon color={"error"}>delete</Icon>*/}
          {/*</MDButton>*/}
        </MDBox>
      );
    }
  };
  const currencyIcon = {
    dollar: "usd",
    dinar: "Iqd",
  };
  if (isDeleted) return null;
  return (
    <MDBox
      display="flex"
      flexDirection="row"
      alignItems={"center"}
      onDoubleClick={() => alert(transaction.id)}
      gap={1}
      sx={{
        color: "#fff",
        // selected and (selected + hover) states
        "&&:hover": {
          bgcolor: "rgba(196,196,196,0.46)",
          "&, & .MuiListItemIcon-root": {
            color: "gray-200",
          },
        },
      }}
    >
      <MDBox width={"100%"} display="flex" justifyContent={"space-between"} alignItems={"center"}>
        <MDBox width="8rem" display={"flex"} flexDirection={"column"} gap={0.5}>
          <MDTypography variant="caption" color="dark" fontWeight={"bold"}>
            {transaction?.brand_name}
          </MDTypography>{" "}
          <MDTypography color="text" fontWeight={"light"} variant="caption">
            {transaction.user_name}
          </MDTypography>{" "}
        </MDBox>
        <MDBox display="flex" alignItems={"center"} width="6rem">
          <MDTypography color="text" fontWeight={"bold"} variant="caption">
            {/*{utils.convertUTCToLocal(transaction.created_at)}*/}
            {transaction.day}
          </MDTypography>{" "}
          <Icon>calendar</Icon>
        </MDBox>
        <CurrencyTextField
          color="success"
          fontWeight={"bold"}
          variant="caption"
          width="8rem"
          value={transaction.amount}
          currencySymbol={currencyIcon[transaction.currency_name]}
        />
        {utils.callbackFunction(actions(), isAdmin)}
      </MDBox>
    </MDBox>
  );
};

export default RenderTransactionComp;
RenderTransactionComp.propTypes = {
  transaction: PropTypes.any.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};
/**
 *       <AlertDialog
 *         disable={disable ? true : false}
 *         onAgree={(e) => {
 *           handleUserWorkingHours(hour.working_hour_id, "accepted");
 *           // handleDelete(date.id);
 *         }}
 *         id={hour.working_hour_id}
 *         triggerText={"yes"}
 *         title={"name"}
 *         disagreeText={"ss"}
 *         agreeText={"delete"}
 *         content={"here is the content"}
 *         icon={isAdmin && <Icon color={"success"}>check</Icon>}
 *       />{" "}

 */

{
  /*<MDBox>*/
}
{
  /*  <MDTypography*/
}
{
  /*    variant="caption"*/
}
{
  /*    color="text"*/
}
{
  /*    fontWeight={"regular"}*/
}
{
  /*    sx={{ fontStyle: disable && "italic" }}*/
}
{
  /*  >*/
}
{
  /*    {transaction?.status}*/
}
{
  /*  </MDTypography>{" "}*/
}
{
  /*  {updated ? (*/
}
{
  /*    <MDTypography fontSize={"16px"} variant="caption" color="success" fontWeight={"bold"}>*/
}
{
  /*      updated*/
}
{
  /*    </MDTypography>*/
}
{
  /*  ) : null}*/
}
{
  /*</MDBox>*/
}

{
  /*<MDTypography variant="caption" color="text" fontWeight={"bold"}>*/
}
{
  /*  Modified*/
}
{
  /*</MDTypography>*/
}

import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { FormControl } from "@mui/material";
import MDTypography from "../../../components/MDTypography";
import Grid from "@mui/material/Grid";
import { commerceAPI } from "../../../assets/apiFunctions";
import ComboBox from "../../../components/comboBox/comboBox";
import Divider from "@mui/material/Divider";
import PropTypes from "prop-types";

const CategoriesComboBox = ({ handleChange, data, inputProps, disabled, isProduct }) => {
  const [str, setStr] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [c, setC] = React.useState(1);
  useEffect(() => {
    (str !== "") & (str?.length > 2) &&
      commerceAPI.category.search(isProduct, str).then((res) => {
        const data = res.data.data;
        setOptions(data);
        const uniqueData = data.filter(
          (obj, index, self) => index === self.findIndex((o) => o.label === obj.label)
        );
        // setOptions(uniqueData);
      });
  }, [str]);
  function generateEvent(name, value) {
    return handleChange({ target: { name: name, value: value } });
  }
  return (
    <Grid item alignItems="center" xs={12}>
      <MDTypography variant="h6" fontWeight="regular" color={"primary"}>
        Parent category
      </MDTypography>
      <Divider sx={{ width: "100%", margin: 0 }} color={"red"} light={false} textAlign={"center"} />

      <Box sx={{ p: 1, width: "100%" }} justifySelf={"self-start"}>
        <FormControl fullWidth>
          <ComboBox
            required={inputProps?.required}
            disabled={disabled}
            name={inputProps.name}
            textFieldLabel={inputProps?.label}
            options={options}
            value={data && data.hasOwnProperty(inputProps.name) ? data[inputProps.name]?.label : ""}
            setStr={setStr}
            handleChange={generateEvent}
          />
        </FormControl>
      </Box>
    </Grid>
  );
};

export default CategoriesComboBox;
CategoriesComboBox.defaultValue = {
  isProduct: false,
};
CategoriesComboBox.propTypes = {
  handleChange: PropTypes.func,
  data: PropTypes.any,
  inputProps: PropTypes.any,
  disabled: PropTypes.bool,
  isProduct: PropTypes.bool,
};

import { momentHelper } from "./momentHelper";
import { isArray } from "chart.js/helpers";
import MDBox from "../components/MDBox";
import Box from "@mui/material/Box";
import CurrencyText from "../components/MDTypography/currency";
import React from "react";

export const utils = {
  ...momentHelper,
  setToLocalStorage: (data) => {
    localStorage.setItem("name", data?.user?.name);
    localStorage.setItem("token", data?.access_token);
  },
  getParamsFromUrl: (param) => {
    const search = window.location.search;

    // Create a URLSearchParams object from the search string
    const searchParams = new URLSearchParams(search);

    // Get the value of "param1"
    return searchParams.get(param);
  },
  calculateTotalByCurrency: (d) => {
    const totals = d.reduce(
      (acc, curr) => {
        acc.iqd += curr?.transactions?.iqd;
        acc.usd += curr?.transactions?.usd;
        return acc;
      },
      { iqd: 0, usd: 0 }
    );
    return (
      <MDBox display={"flex"} alignItems={"center"} gap={1}>
        <MDBox display={"flex"} alignItems={"center"} gap={1}>
          <Box
            sx={{
              width: 10, // Diameter of the circle
              height: 10, // Diameter of the circle
              backgroundColor: "blue", // Color of the circle
              borderRadius: "50%", // This makes it a circle
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white", // Text color inside the circle
              fontSize: "16px", // Font size for the text
            }}
          />

          <CurrencyText
            value={totals?.usd}
            currencySymbol={"usd"}
            fontWeight={"bold"}
            variant={"h6"}
          />
        </MDBox>{" "}
        <MDBox display={"flex"} alignItems={"center"} gap={1}>
          <Box
            sx={{
              width: 10, // Diameter of the circle
              height: 10, // Diameter of the circle
              backgroundColor: "orange", // Color of the circle
              borderRadius: "50%", // This makes it a circle
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white", // Text color inside the circle
              fontSize: "16px", // Font size for the text
            }}
          />
          <CurrencyText
            value={totals?.iqd}
            currencySymbol={"iqd"}
            variant={"h6"}
            fontWeight={"bold"}
          />
        </MDBox>
      </MDBox>
    );
  },
  callbackFunction: (component, compare) => {
    if (compare && compare == true) return component;
    else return null;
  },
  removeFromLocalStorage: () => {
    localStorage.clear();
  },
  areAllElementsNotNullOrEmpty: (arr) => {
    return arr.every((element) => element !== null && element !== "");
  },

  openGoogleMaps: (placeName) => {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera|chrome Mini/i.test(
      navigator.userAgent
    );

    if (isMobile) {
      if (isIOS()) {
        openGoogleMapsAppWithPlaceNameiOS(placeName);
      } else {
        openGoogleMapsAppWithPlaceName(placeName);
        alert("ios");
      }
    } else {
      openGoogleMapsInBrowser(placeName);
    }
  },
  numberToArray: (number) => {
    // Convert the number to a string
    const numberStr = number.toString();

    // Split the string into an array of characters
    const charArray = numberStr.split("");

    // Convert each character back to a number
    const numArray = charArray.map((char) => parseInt(char));
    return numArray;
  },
  returnNumberOrNull: async (value) => {
    if (!isNaN(value)) {
      return parseFloat(value); // Parse the value as a floating-point number
    }
    return "";
  },
  getClientInfo: () => {
    const unknown = "-";

    const { screen } = window;
    const screenSize = screen.width ? `${screen.width} x ${screen.height}` : "";

    const nVer = navigator.appVersion;
    const nAgt = navigator.userAgent;
    let browser = navigator.appName;
    let version = parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset, verOffset, ix;

    // The rest of your code here...

    const mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

    const cookieEnabled = navigator.cookieEnabled ? true : false;

    if (typeof navigator.cookieEnabled === "undefined" && !cookieEnabled) {
      document.cookie = "testcookie";
    }
    return {
      screen: screenSize,
      browser: browser,
      browserVersion: version,
      browserMajorVersion: majorVersion,
      mobile: mobile,
    };
  },
  handleChangeFormInputs: (event, isDate, inputName) => {
    if (isDate) {
      return { target: { name: inputName, value: utils.dateLocalization(event) } };
    } else {
      return event;
    }
  },
  returnObjectFromArray: (data) => {
    if (data && isArray(data)) {
      if (data.length == 0) return null;
      return data.length === 1 ? data[0] : data;
    } else {
      return data;
    }
  },
  checkApiResponse: (res) => {
    if (res.data.status === "ok") {
      return true;
    } else {
      return false;
    }
  },
};

function openGoogleMapsAppWithPlaceName(placeName) {
  const geoUri = `geo:0,0?q=${encodeURIComponent(placeName)}`;
  window.open(geoUri, "_system");
}

function openGoogleMapsAppWithPlaceNameiOS(placeName) {
  const googleMapsUrl = `comgooglemaps://?q=${encodeURIComponent(placeName)}`;
  const appleMapsUrl = `http://maps.apple.com/?q=${encodeURIComponent(placeName)}`;

  window.location.href = googleMapsUrl;
  setTimeout(() => {
    // Fallback to Apple Maps if Google Maps app is not installed.
    window.location.href = appleMapsUrl;
  }, 500);
}

function openGoogleMapsInBrowser(placeName) {
  const mapsUrl = `https://www.google.com/maps?q=${encodeURIComponent(placeName)}`;
  window.open(mapsUrl, "_blank");
}
function isIOS() {
  return /iPhone|iPad|iPod|safari/i.test(navigator.userAgent);
}

import React, { useContext, useEffect, useState } from "react";
import WorkingHoursProvider from "../../context/workingHoursContext";
import LazyLoading from "../../components/lazyLoading";
import WorkHours from "./_index";
import { Route, Routes } from "react-router-dom";
import CheckUsersWorkingHours from "./checkUsersWhours/checkUsersWorkingHours";

const Index = () => {
  return (
    <>
      {/*for admins*/}
      <LazyLoading>
        <WorkingHoursProvider>
          <Routes>
            <Route index element={<WorkHours />} />
            <Route path={"check"} element={<CheckUsersWorkingHours />} />
          </Routes>
        </WorkingHoursProvider>
      </LazyLoading>
    </>
  );
};

export default Index;

import { createContext, useContext, useEffect, useState } from "react";
import { brandAPI } from "../assets/apiFunctions";

const BrandContext = createContext(null);
//eslint-disable-next-line
export const BranchesContextProvider = ({children}) => {
  const [brands, setBrands] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [data, setData] = useState();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    brandAPI.getBrandWithManager().then((res) => setBrands(res.data.data));
  }, []);
  useEffect(() => {
    brandAPI
      .getDataAPI()
      .then((res) => setData(res.data.data))
      .finally(() => setLoading(false));
  }, []);
  return (
    <BrandContext.Provider value={{ data, setData, isLoading, openAlert, brands, setOpenAlert }}>
      {children}
    </BrandContext.Provider>
  );
};

export const useBranchesContext = () => {
  return useContext(BrandContext);
};

// src/utils/apiHelpers.js
import { commerceAPI } from "../../../assets/prefixApis/commerceAPI";
import { callback } from "chart.js/helpers";

export const fetchLanguages = async () => {
  try {
    const response = await commerceAPI.getLanguages();
    return response.data.data;
  } catch (error) {
    console.error("Failed to fetch languages:", error);
    throw error;
  }
};

export const createCategory = async (formData, callback) => {
  try {
    const response = await commerceAPI.category.create(formData, callback);
    return response;
  } catch (error) {
    console.error("Failed to create category:", error);
    throw error;
  }
};
export const createOffer = async (formData, callback) => {
  try {
    const response = await commerceAPI.offer.create(formData, callback);
    return response;
  } catch (error) {
    console.error("Failed to create category:", error);
    throw error;
  }
};
export const createProduct = async (formData, callback) => {
  try {
    const response = await commerceAPI.product.create(formData, callback);
    return response;
  } catch (error) {
    console.error("Failed to create category:", error);
    throw error;
  }
};
export const deleteItems = async (id, itemType) => {
  try {
    let response;
    if (itemType === "product") {
      response = await commerceAPI.product.delete(id);
    } else {
      response = commerceAPI.category.delete(id);
    }
    return response;
  } catch (error) {
    console.error("Failed to create category:", error);
    throw error;
  }
};

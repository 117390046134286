import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import LazyLoading from "../../components/lazyLoading";

const Edit = lazy(() => import("./pages/edit"));
const Home = lazy(() => import("./pages/home"));
const Index = (props) => {
  return (
    <LazyLoading>
      <Routes>
        <Route index element={<Home />} />
        <Route path={"edit"} element={<Edit />} />
      </Routes>
    </LazyLoading>
  );
};

export default Index;

import React from "react";
import LazyLoading from "../../../components/lazyLoading";
import { Route, Routes } from "react-router-dom";
import Add from "./pages/product/add";
import View from "./pages/product/view";
import Edit from "./pages/product/edit";

const Index = () => {
  return (
    <LazyLoading>
      <Routes>
        <Route index element={<Add />} />
        <Route path={"/view"} element={<View />} />
        <Route path={"/edit"} element={<Edit />} />
      </Routes>
    </LazyLoading>
  );
};

export default Index;

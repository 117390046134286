import React from "react";
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import PropTypes from "prop-types";

const LinkButton = ({ href }) => {
  return (
    <MDBox>
      <MDButton
        component={"a"}
        size={"large"}
        color={"info"}
        variant={"gradient"}
        href={href}
        sx={{ float: "right" }}
      >
        add
      </MDButton>
    </MDBox>
  );
};
export default LinkButton;
LinkButton.propTypes = {
  href: PropTypes.string.isRequired,
};

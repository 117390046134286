import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import LazyLoading from "../../components/lazyLoading";

const View = React.lazy(() => import("./pages/view/view"));
const Add = React.lazy(() => import("./pages/add"));
const Edit = React.lazy(() => import("./pages/edit/edit"));
const RoomTypes = React.lazy(() => import("./pages/roomTypes"));
const RoomRate = React.lazy(() => import("./pages/roomTypes/ratesAvailability"));

const Index = () => {
  return (
    <LazyLoading>
      <Routes>
        <Route index element={<View />} />
        <Route path="add" element={<Add />} />
        <Route path="edit" element={<Edit />} />
        <Route path="room-types" element={<RoomTypes />} />
        <Route path="room-rate" element={<RoomRate />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </LazyLoading>
  );
};

export default Index;

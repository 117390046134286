/**
 * API endpoint prefixes.
 * @type {{user: string, brand: string, transactions: string}}
 */

export const prefix = {
  user: "/api/user",
  workingHours: "/api/workingHours",
  patient: "/api/patient",
  car: "/api/car",
  shift: "/api/shift",
  statistics: "/api/statistics",
  department: "/api/department",
  rooms: "/api/rooms",
  reservations: "/api/reservations",
  emails: "/api/emails",
  jobs: "/api/jobs/dashboard",
  brand: "/api/dashboard/brand",
  transactions: "/api/dashboard/transaction",
  feedback: "/api/feedback/author",
  commerce: "/api/commerce",
  category: "/api/commerce/category",
  product: "/api/commerce/product",
  misc: "/api/misc",
  offer: "/api/commerce/offer",
};

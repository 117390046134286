import { createContext, useContext, useEffect, useState } from "react";
import { brandAPI } from "../assets/prefixApis/brandAPI";

const TransactionsContext = createContext(null);

//eslint-disable-next-line
export const TransactionsContextProvider = ({ children }) => {
  const [brands, setBrands] = useState([]);
  useEffect(() => {
    brandAPI.getDataAPI().then((res) => setBrands(res.data.data));
  }, []);
  return <TransactionsContext.Provider value={{ brands }}>{children}</TransactionsContext.Provider>;
};
export const useTransactionsContext = () => {
  return useContext(TransactionsContext);
};

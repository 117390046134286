import React from "react";
import LayoutHeader from "../../../../../components/layoutHeader/layoutHeader";

import AddProduct from "../../components/addProduct";
import MdContainer from "../../../../../components/Container/mdContainer";

const Add = () => {
  return (
    <MdContainer>
      <LayoutHeader
        title={"Add Product"}
        subtitle={"Quickly register new category and optimize your setup."}
      />

      <AddProduct />
    </MdContainer>
  );
};

export default Add;

import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
// import numeral from "numeral";
import moment from "moment";
import { Grid, Card, CardContent, IconButton, Tooltip, Typography, Box } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";

export const FilesGrid = ({ files, onFileRemove }) => {
  const [imagePreviews, setImagePreviews] = useState({}); // To store previews for all files

  const handleImageUpload = (file, index) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        // Set the preview for the specific file
        setImagePreviews((prev) => ({
          ...prev,
          [index]: reader.result,
        }));
      };
      reader.readAsDataURL(file); // Convert the file to base64
    }
  };

  return (
    <Grid container spacing={4} className="mt-4">
      {_.map(files, (file, index) => (
        <Grid item lg={4} md={6} key={index}>
          <Card className="mb-3">
            {/* File Icon Section */}
            <Box
              // className={classNames(
              //   classes["ph--large"],
              //   "d-flex justify-content-center align-items-center"
              // )}
              sx={{ backgroundColor: "#f5f5f5" }}
            >
              {/*<i className={`fa fa-fw fa-3x ${getFileIcon(file)}`} />*/}
              <i className={`fa fa-fw fa-3x `} />
              <div
                style={{
                  width: "100%",
                  height: "200px",
                  overflow: "hidden",
                }}
              >
                {imagePreviews[index] ? (
                  <img
                    src={imagePreviews[index]}
                    alt={`Preview ${index}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      marginTop: "10px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  handleImageUpload(file, index) || (
                    <p style={{ marginTop: "10px" }}>Loading preview...</p>
                  )
                )}
              </div>
            </Box>

            {/* Card Content */}
            <CardContent>
              {/* File Name and Delete Button */}
              <Box className="d-flex align-items-center mb-2">
                <Typography
                  variant="subtitle1"
                  noWrap
                  style={{ flexGrow: 1 }}
                  title={file.name} // Tooltip for truncated names
                >
                  {file.name}
                </Typography>

                <Tooltip title="Delete File" placement="left">
                  <IconButton size="small" onClick={() => onFileRemove(file)}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </Tooltip>
              </Box>

              {/* File Details */}
              <Typography variant="body2" color="textSecondary">
                {/*by You · {`${numeral(file.size).format("0.00a")}B`}*/}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {/*{moment(file.modifiedDate).format("DD-MMM-YYYY, HH:mm")}*/}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

FilesGrid.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
      modifiedDate: PropTypes.string.isRequired,
    })
  ).isRequired,
  onFileRemove: PropTypes.func.isRequired,
};

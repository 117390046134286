import React from "react";
import { Button, Grid, Stack } from "@mui/material";
import MDTypography from "../MDTypography";
import MDBox from "../MDBox";
import PropTypes from "prop-types";
import InputComponent from "../inputsComponent/inputsComponent";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

const SubmitForm = ({
  handleChange,
  submit,
  user,
  inputs,
  reset,
  children,
  inputProps,
  // to detect were to render the children
  isAfter,
  ...rest
}) => {
  return (
    <Grid>
      <Grid item xs={12}>
        <Box p={2}>
          <form onSubmit={submit}>
            {!isAfter && (
              <Grid container alignItems={"center"}>
                {children}
              </Grid>
            )}
            {inputs &&
              inputs?.map((group, index) => (
                <React.Fragment key={index}>
                  <Grid item alignItems="center" {...rest}>
                    <MDTypography variant="h6" fontWeight="regular" color={"info"}>
                      {group.name}
                    </MDTypography>
                    <Divider
                      sx={{ width: "100%", margin: 0 }}
                      color={"red"}
                      light={false}
                      textAlign={"center"}
                    />

                    {/* generate inputs */}
                    <Grid container alignItems="center" flexDirection={"row"}>
                      {group.fields.map((field) => (
                        <Grid key={field.name} item xs={12} lg={6} {...inputProps}>
                          <InputComponent e={field} user={user} handleChange={handleChange} />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
            {isAfter && (
              <Grid container alignItems={"center"}>
                {children}
              </Grid>
            )}
            <MDBox pt={3}>
              <Stack direction={"row"} spacing={1}>
                <Button type={"submit"} variant={"contained"}>
                  <MDTypography variant="h7" fontWeight="medium" color={"white"}>
                    Submit
                  </MDTypography>
                </Button>
                {reset && (
                  <Button variant={"contained"} sx={{ backgroundColor: "darkred" }} onClick={reset}>
                    <MDTypography variant="h7" fontWeight="medium" color={"white"}>
                      Reset
                    </MDTypography>
                  </Button>
                )}
              </Stack>
            </MDBox>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};

SubmitForm.propTypes = {
  handleChange: PropTypes.any,
  submit: PropTypes.any,
  reset: PropTypes.func,
  user: PropTypes.object,
  inputs: PropTypes.array,
  children: PropTypes.node,
  inputProps: PropTypes.object,
  isAfter: PropTypes.bool,
};

export default React.memo(SubmitForm);

import React, { useCallback, useEffect } from "react";
import SubmitForm from "../../../../components/forms/submitForm";
import DropZone from "../../../../components/DropZone";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import useProductHooks from "../hooks/useProductHooks";
import CategoriesComboBox from "../../components/categoriesComboBox";
import Card from "@mui/material/Card";

const AddProduct = () => {
  const { namesInputs, handleChange, submit, data, setFiles, isLoading, progress } =
    useProductHooks();
  function s() {
    return (
      <Modal
        open={false}
        // onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <MDBox
          // color={color === "light" ? "dark" : "white"}
          // coloredShadow={color}
          sx={style}
        >
          <Box
            sx={{
              position: "relative",
              display: "inline-flex",
              alignItems: "center",
              userSelect: "none",
            }}
          >
            <CircularProgress variant="determinate" value={progress} />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MDTypography variant="h6" color={"white"}>
                {`${Math.round(progress)}%`}
              </MDTypography>
            </Box>
          </Box>
        </MDBox>

        {/*<Box  borderRadius="xl">*/}

        {/*</Box>*/}
      </Modal>
    );
  }
  const style = {
    all: "unset",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 380,
    height: "auto",
    // border: "2px solid #000",
    userSelect: "none",
    // pt: 2,
    // px: 4,
    // pb: 3,
  };

  const PCom = useCallback(s, [progress]);
  const memoizedSetFiles = useCallback(
    (files) => {
      setFiles(files);
    },
    [setFiles]
  );
  let input = {
    onChange: "handleChange",
    name: "fk_category_id",
    label: "Parent category",
    value: "fk_category_id",
    required: true,
    inputType: "combobox",
    options: "categories",
  };
  if (isLoading) return null;
  return (
    <Card sx={{ marginTop: 2 }}>
      <SubmitForm
        inputs={namesInputs}
        handleChange={handleChange}
        submit={submit}
        user={data}
        isAfter={true}
      >
        <PCom />
        <CategoriesComboBox
          inputProps={input}
          handleChange={handleChange}
          data={data}
          isProduct={true}
        />
        <DropZone uploadFiles={memoizedSetFiles} />
      </SubmitForm>
    </Card>
  );
};
export default AddProduct;
AddProduct.propTypes = {
  dataToUpdate: PropTypes.object,
};

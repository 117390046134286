import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home";

const Index = (props) => {
  return (
    <Routes>
      <Route index element={<Home />} />
    </Routes>
  );
};
export default Index;

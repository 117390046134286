import React from "react";

import { Navigate, Route, Routes } from "react-router-dom";
import UsersContextProvider from "../../context/usersContext";
import LazyLoading from "../../components/lazyLoading";

const AddUser = React.lazy(async () => {
  return import("./addUser");
});
const Edit = React.lazy(() => import("./edit"));
const Index = () => {
  return (
    <UsersContextProvider>
      <LazyLoading>
        <Routes>
          <Route index element={<AddUser />} />
          <Route path="edit" element={<Edit />} />
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </LazyLoading>
    </UsersContextProvider>
  );
};

export default Index;

import React, { useState } from "react";

const EditProductHooksState = () => {
  const [cat, setCat] = useState(null);

  const [categoryStatus, setCategoryStatus] = useState({});
  const [status, setStatus] = useState({});
  const [images, setImages] = useState([]);
  const [fields, setFields] = useState([]);
  const [fieldsData, setFieldsData] = useState({});
  const [files, setFiles] = useState();

  return {
    cat,
    setCat,
    status,
    setStatus,
    images,
    setImages,
    fields,
    setFields,
    fieldsData,
    setFieldsData,
    files,
    setFiles,
  };
};

export default EditProductHooksState;

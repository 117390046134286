import axiosInstance from "../axiosInstance";
import { prefix } from "./prefixsVariables";

export const brandAPI = {
  createOrUpdate: async (data) => {
    return await axiosInstance.post(`${prefix.brand}/create`, data);
  },
  getManagersBrand: async () => {
    return await axiosInstance.get(`${prefix.brand}/get-managers-brand`);
  },
  getDataAPI: async () => {
    return await axiosInstance.get(`${prefix.brand}/get`);
  },
  setBrandManager: async (data) => {
    return await axiosInstance.post(`${prefix.brand}/set-brand-manager`, data);
  },
  deleteTransaction: async (id) => {
    return await axiosInstance.post(`${prefix.brand}/delete-transaction/${id}`);
  },
  getBrandWithManager: async () => {
    return await axiosInstance.get(`${prefix.brand}/get-brandWith-manager`);
  },
  changeStatus: async (data, id) => {
    return await axiosInstance.post(`${prefix.brand}/set_brand_status${id}`, data);
  },
  setSortedList: async (data) => {
    return await axiosInstance.post(`${prefix.brand}/set_sorted_list`, data);
  },
  delete: async (id) => {
    return await axiosInstance.post(`${prefix.brand}/delete/${id}`);
  },
};

import React from "react";
import LayoutHeader from "../../../../../components/layoutHeader/layoutHeader";
import MdContainer from "../../../../../components/Container/mdContainer";
import AddOffer from "../../components/addOffer";
import ViewOffers from "../../components/viewOffers";

const Index = (props) => {
  return (
    <MdContainer>
      <LayoutHeader
        title={"Add Category"}
        subtitle={"Quickly register new category and optimize your setup."}
      />
      <AddOffer />
      <ViewOffers />
    </MdContainer>
  );
};

export default Index;

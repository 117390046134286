/* eslint-disable */
import React, { useEffect, useState } from "react";
import generateWeekDays, {
  GenerateMonthDays,
} from "../../../../../components/generateWeekDays/generateWeekDays";
import moment from "moment";
import MDBox from "../../../../../components/MDBox";
import Grid from "@mui/material/Grid";
import MDTypography from "../../../../../components/MDTypography";
import { roomsAPI } from "../../../../../assets/apiFunctions";

const Calendar = (props) => {
  const today = moment();
  const month = today.month() + 1;
  const { allDays, startOfWeek, endOfWeek } = GenerateMonthDays(month);
  const [roomRate, setRoomRate] = useState(null);
  useEffect(() => {
    roomsAPI
      .getRatesAvailability({ dates: { from: "01/01/2023", to: "01/06/2024" } })
      .then((res) => setRoomRate(res.data[0]));
  }, []);

  return (
    <MDBox>
      <MDBox
        flex
        flexDirection={"row"}
        sx={{ display: "flex", flexDirection: "row", flexWrap: "no-wrap", overflow: "scroll" }}
      >
        {roomRate &&
          roomRate.with_price.map((e, index) => (
            <MDBox key={index}>
              <DayComponent data={e} />
            </MDBox>
          ))}
      </MDBox>
    </MDBox>
  );
};
export default Calendar;

//eslint-disable-next-line
export const DayComponent = ({ data }) => {
    const room = Object.entries(data).map(([day, price]) => ({ day, price }))[0];
    const is_closed=room?.price?.is_closed
  return (
    <MDBox variant={"gradient"} flexDirection={"column"} border={0.2} p={1}>
      <MDBox>
        <MDBox>
          <MDTypography
            textTransform={"capitalize"}
            variant="caption"
            fontWeight="bold"
            color="text"
            gutterBottom
          >
              {moment(room.day).format("DD")}
          </MDTypography>
        </MDBox>
        <MDBox>
          <MDTypography
            textTransform={"capitalize"}
            variant="caption"
            fontWeight="bold"
            color="text"
            gutterBottom
          >
              {moment(room.day).format("dd")}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox mt={6}>
        <MDTypography
          textTransform={"uppercase"}
          variant="caption"
          fontWeight="bold"
          color="success"
          gutterBottom
        >
            {is_closed ? <MDTypography color={"error"}>Closed</MDTypography>: room?.price?.price+"$"}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
};

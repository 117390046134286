/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";

// Material Dashboard 2 React context
import { useMaterialUIController } from "../../../../../context";
import Actions from "../actions";
import { useState } from "react";

function BrandCard({ users, name, manager, email, vat, noGutter, brandId, isDisabled }) {
  const [defaultValues, setDefaultValues] = useState({
    users,
    name,
    manager,
    email,
    vat,
    noGutter,
    isDisabled,
    brandId,
  });
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  //this function to update the state
  function onSuccess(update) {
    setDefaultValues({ ...defaultValues, [update.name]: update.value });
  }
  return (
    <MDBox
      component="li"
      display="flex"
      flexDirection={{ xs: "column", md: "row" }}
      justifyContent="space-between"
      gap={{ xs: 2, md: 0 }}
      alignItems="flex-start"
      bgcolor={!defaultValues?.manager ? "rgba(255,132,81,0.42)" : "grey-100"}
      borderRadius="lg"
      p={3}
      mt={2}
    >
      <MDBox display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={"flex-center"}
          flexDirection={"row"}
          mb={2}
        >
          <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
            {defaultValues.name}
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Manager Name:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
              {defaultValues.manager || "not set"}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Transactions enabled:&nbsp;&nbsp;&nbsp;
            {defaultValues.isDisabled === 0 || defaultValues.isDisabled === false ? (
              <MDTypography variant="caption" fontWeight="medium" color={"success"}>
                yes
              </MDTypography>
            ) : (
              <MDTypography variant="caption" fontWeight="medium" color={"error"}>
                no
              </MDTypography>
            )}
          </MDTypography>
        </MDBox>
        {/*<MDTypography variant="caption" color="text">*/}
        {/*  VAT Number:&nbsp;&nbsp;&nbsp;*/}
        {/*  <MDTypography variant="caption" fontWeight="medium">*/}
        {/*    {vat}*/}
        {/*  </MDTypography>*/}
        {/*</MDTypography>*/}
      </MDBox>
      <MDBox>
        {users && (
          <Actions users={users} brandId={brandId} onSuccess={onSuccess} isDisabled={isDisabled} />
        )}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of BrandCard
BrandCard.defaultProps = {
  noGutter: false,
};

// Typechecking props for the BrandCard
BrandCard.propTypes = {
  name: PropTypes.string.isRequired,
  manager: PropTypes.string,
  email: PropTypes.string.isRequired,
  vat: PropTypes.string,
  noGutter: PropTypes.bool,
  users: PropTypes.array,
  brandId: PropTypes.string,
  //sometimes get 0 or 1
  isDisabled: PropTypes.any,
};

export default BrandCard;

import React, { useCallback, useEffect, useState } from "react";
import ProductsMedia from "../../../product/components/productsMedia";
import ParentCategory from "../../components/parentCategory";
import CategoryStatus from "../../components/categoryStatus";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Stack } from "@mui/material";
import NoDataFound from "../../../../../components/NoDataFound";
import { contentLoader } from "../../../../../components/loader/loader";
import editCategoryHooks from "../../hooks/editCategoryHooks";
import SubmitForm from "../../../../../components/forms/submitForm";
import DropZone from "../../../../../components/DropZone";
import DeleteCategory from "../../../components/deleteItem";
import LayoutHeader from "../../../../../components/layoutHeader/layoutHeader";
import MdContainer from "../../../../../components/Container/mdContainer";
import CategoryMedia from "../../components/categoryMedia";
const Edit = () => {
  const {
    isLoading,
    cat,
    categoryStatus,
    images,
    fields,
    fieldsData,
    submit,
    handleChange,
    files,
    setFiles,
  } = editCategoryHooks();
  const memoizedSetFiles = useCallback(
    (files) => {
      setFiles(files);
    },
    [files]
  );
  if (isLoading) return contentLoader();
  if (cat === null || cat === undefined) return <NoDataFound />;
  return (
    <MdContainer>
      <LayoutHeader
        title={"Edit Category"}
        subtitle={"Quickly register new category and optimize your setup."}
      />
      <Grid container direction={{ xs: "column-reverse", sm: "row" }}>
        <Grid item xs={12} md={8}>
          <SubmitForm
            isAfter={true}
            inputs={fields}
            user={fieldsData}
            handleChange={handleChange}
            submit={submit}
            inputProps={{ md: 12, lg: 12, xl: 6 }}
          >
            <DropZone uploadFiles={memoizedSetFiles} />
            <CategoryMedia images={images} />
          </SubmitForm>
        </Grid>
        <Grid item xs={12} md={4} gap={1}>
          <Stack gap={1}>
            <CategoryStatus status={categoryStatus} />
            <ParentCategory parent={cat} />
            <DeleteCategory id={cat?.id} itemType={"category"} />
          </Stack>
        </Grid>
      </Grid>
    </MdContainer>
  );
};

export default Edit;

import React from "react";
import Icon from "@mui/material/Icon";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";

const Index = () => {
  return (
    <MDBox
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Icon fontSize={"large"}>source</Icon>
      <MDTypography variant={"h6"} fontWeight={"medium"}>
        No data
      </MDTypography>
    </MDBox>
  );
};
export default Index;

import _Index from "./_index";
import ProfileContextProvider from "../../context/profileContext";

function Overview() {
  return (
    <ProfileContextProvider>
      <_Index />
    </ProfileContextProvider>
  );
}

export default Overview;

import React, { useState } from "react";
import { ImageListItem, Typography } from "@mui/material";
import MDBox from "../../../../components/MDBox";
import ImagesActionMenu from "./imagesActionMenu";
import { SERVER, SERVER_IMAGES } from "../../../../assets/axiosInstance";
import PropTypes from "prop-types";
import MDTypography from "../../../../components/MDTypography";
import { MediaSizes } from "../../../../assets/mediaSizes";
const ImageContainer = ({ img, onDelete, setPrimary, isPrimary, forceClose }) => {
  return (
    <ImageListItem cols={img?.cols || 1} rows={img?.rows || 1} sx={{ position: "relative" }}>
      <MDBox
        sx={{ position: "absolute", right: "0", top: "0" }}
        width={"100%"}
        display={"flex"}
        justifyContent={"space-between"}
      >
        {isPrimary ? (
          <MDTypography variant="h6" gutterBottom color={"warning"}>
            Primary
          </MDTypography>
        ) : null}

        <ImagesActionMenu
          onDelete={onDelete}
          setPrimary={setPrimary}
          forceClose={forceClose}
          id={img.id}
        />
      </MDBox>
      <ImageC img_url={img.image_url} />
    </ImageListItem>
  );
};

export default ImageContainer;
ImageContainer.propTypes = {
  img: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  setPrimary: PropTypes.func.isRequired,
  isPrimary: PropTypes.bool,
  forceClose: PropTypes.bool,
};
//eslint-disable-next-line
export const ImageC = ({ img_url }) => {
  let server = `${SERVER_IMAGES}/${MediaSizes.small}`;
  // Function to check if the URL is a video
  const isVideo = (url) => {
    const videoExtensions = ["mp4", "mkv", "avi", "mov", "flv", "wmv", "webm"];
    const extension = url.split(".").pop().toLowerCase();
    return videoExtensions.includes(extension);
  };

  const mediaUrl = `${server}/${img_url}`;

  return isVideo(img_url) ? (
    <video src={mediaUrl} controls loading="lazy" style={{ maxWidth: "100%" }}>
      Your browser does not support the video tag.
    </video>
  ) : (
    <img src={mediaUrl} alt={"img.title"} loading="lazy" style={{ maxWidth: "100%" }} />
  );
};

import React from "react";
import RenderTransactionComp from "./RenderTransactionComp";
import MDBox from "../../../../components/MDBox";
import PropTypes from "prop-types";

const RenderTransaction = ({ transactions, isAdmin }) => {
  return (
    <MDBox display="flex" flexDirection="column" width={"100%"} gap={1}>
      {transactions &&
        transactions
          // .sort((s, f) => s.day.localeCompare(f.day))
          .sort((x, s) => x.sort_index - s.sort_index)
          .map((tr) => (
            <RenderTransactionComp
              transaction={tr}
              key={tr.id || tr.working_hour_id}
              isAdmin={isAdmin}
            />
          ))}
    </MDBox>
  );
};
export default RenderTransaction;

RenderTransaction.propTypes = {
  transactions: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

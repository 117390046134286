import React from "react";
import { Route, Routes } from "react-router-dom";
import Add from "./pages/add";
import LazyLoading from "../../../components/lazyLoading";

const Index = (props) => {
  return (
    <LazyLoading>
      <Routes>
        <Route index element={<Add />} />
      </Routes>
    </LazyLoading>
  );
};
export default Index;

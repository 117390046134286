import React, { useEffect, useState } from "react";
import MDTypography from "../../../../components/MDTypography";
import PropTypes from "prop-types";
import SideCard from "../../../../components/SideCard";
import MDBox from "../../../../components/MDBox";
import NestedModal from "../../../../components/MDModal/modal";
import MDButton from "../../../../components/MDButton";

import { FormControlLabel, FormGroup } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import ParentCategoryHooks from "../hooks/parentCategoryHooks";
import CategoriesComboBox from "../../components/categoriesComboBox";

const ParentCategory = ({ parent }) => {
  const { text, newParent, isParent, checkBoxChange, changeParent, forceClose, input, f } =
    ParentCategoryHooks(parent);
  return (
    <SideCard>
      <MDTypography variant="h6" color="dark" fontWeight="bold">
        Product Parent
      </MDTypography>
      <MDBox display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <MDTypography color={"text"} variant={"h6"} fontWeight={"bold"}>
          {text || "-"}
        </MDTypography>
        <NestedModal forceClose={forceClose} buttonTitle={"change"}>
          <MDBox m={3}>
            <CategoriesComboBox
              inputProps={input}
              handleChange={f}
              data={newParent}
              disabled={isParent}
              isProduct={true}
            />
            <MDButton variant={"gradient"} color={"info"} onClick={changeParent}>
              save
            </MDButton>
          </MDBox>
        </NestedModal>
      </MDBox>
    </SideCard>
  );
};

export default ParentCategory;
ParentCategory.propTypes = {
  parent: PropTypes.object,
};

import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect } from "react";
import { otherAPI } from "../../assets/apiFunctions";
import { Stack } from "@mui/material";
import axios from "axios";
import PropTypes from "prop-types";

export default function ComboBox({
  handleChange,
  value,
  options,
  setStr,
  name,
  textFieldLabel,
  disabled,
  required,
}) {
  async function cry(data) {
    return data;
  }
  // async function fetchData(n) {
  //   axios
  //     .get(
  //       `https://openplzapi.org/de/Streets?postalCode=33102&locality=paderborn&page=${n}&pageSize=10`
  //     )
  //     .then((res) => {
  //       if (res) {
  //         cry(res.data).then((data) => {
  //           const formData = new FormData();
  //           formData.append("data", JSON.stringify(data));
  //           otherAPI.setPlacesToDatabase(formData).then((res) => {
  //             if (c < 30) {
  //               setC(c + 1);
  //             }
  //           });
  //         });
  //       }
  //     });
  // }

  return (
    <Autocomplete
      disabled={disabled ? disabled : false}
      isOptionEqualToValue={(option, value) => {
        // Use filter to find matching option
        const match = options.filter((opt) => opt.label === value);
        return match.length > 0; // Return true if a match exists
      }}
      autoHighlight={true}
      freeSolo={false}
      sx={{ minWidth: "100%" }}
      value={value}
      onChange={(e, selectedValue) => handleChange(name, selectedValue)}
      options={options}
      renderInput={(params) => (
        <TextField
          required={required}
          label={textFieldLabel}
          // name={inputProps.name}
          {...params}
          onChange={(e) => {
            setStr(e.target.value);
            // handleChange(e);
          }}
        />
      )}
    />
  );
}
ComboBox.propTypes = {
  handleChange: PropTypes.func,
  textFieldLabel: PropTypes.string,
  name: PropTypes.string,
  setStr: PropTypes.func,
  value: PropTypes.any,
  options: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

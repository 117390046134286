import axiosInstance from "../axiosInstance";
import { prefix } from "./prefixsVariables";

export const transactionsAPI = {
  addTransaction: async (data) => {
    return await axiosInstance.post(`${prefix.transactions}/set_transaction`, data);
  },
  userTransactions: async (data) => {
    return await axiosInstance.post(`${prefix.transactions}/get_user_transaction/v1`, data);
  },
  managerTransactions: async (data) => {
    return await axiosInstance.post(`${prefix.transactions}/get_manager_transaction`, data);
  },
  getYesterdayTransaction: async (data) => {
    return await axiosInstance.post(`${prefix.transactions}/get_yesterday_transaction`, data);
  },
};

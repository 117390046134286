/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/**
 All of the routes for the Material Dashboard 2 React are added here,
 You can home a new route, customize the routes and delete the routes here.

 Once you home a new route on this file it will be visible automatically on
 the Sidenav.

 For adding a new route you can follow the existing routes in the routes array.
 1. The `type` key with the `collapse` value is used for a route.
 2. The `type` key with the `title` value is used for a title inside the Sidenav.
 3. The `type` key with the `divider` value is used for a divider between Sidenav items.
 3. The `type` hide to prevent render the route inside side nav.
 4. The `name` key is used for the name of the route on the Sidenav.
 5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
 6. The `icon` key is used for the icon of the route on the Sidenav, you have to home a node.
 7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 8. The `route` key is used to store the route location which is used for the react router.
 9. The `href` key is used to store the external links location.
 10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
 10. The `component` key is used to store the component of its route.
 */

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import WorkHours from "layouts/workHours";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import Users from "layouts/users";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import ResetCover from "layouts/authentication/reset-password/cover";
import Rooms from "layouts/rooms";
import RoomTypes from "layouts/rooms/pages/roomTypes";
import Brands from "layouts/brands";
import RoomRates from "./layouts/rooms/pages/roomTypes/ratesAvailability";
import Edit from "./layouts/rooms/pages/roomTypes/edit";
import BrandSettings from "layouts/brands/brandSetting";
import Payment from "layouts/payments";

// @mui icons
import Icon from "@mui/material/Icon";
import NewPassword from "./layouts/authentication/reset-password/confirmCode";
import WorkHoursReport from "./layouts/reports/workHoursReport/workHoursReport";
import React from "react";
import ShiftPlanner from "./layouts/shiftPlanner/shiftPlanner";
import ChangePassword from "./layouts/changePassword/changePassword";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import Reservations from "./layouts/reservations";

import Jobs from "./layouts/jobApplications";
import AddJob from "./layouts/jobApplications/components/addJob";
import Transactions from "./layouts/transactions";
import RevenueReport from "./layouts/reports/revenueReport";
// import AppSettings from "./layouts/appSettings";
import Feedback from "./layouts/feedback";
import Commerce from "./layouts/commerce/categories";
import Subscription from "./layouts/subscription";
import Distributors from "./layouts/distributors";
import Product from "./layouts/commerce/product";
import ContactusInfo from "./layouts/contactusInfo";
import Offers from "./layouts/commerce/offers";
import GuestMessages from "./layouts/clientMessages";

const routes = [
  {
    type: "collapse",
    auth: [1000, 3000, 2000],
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    href: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    auth: [6000],
    name: "Work Hours",
    key: "work-hours",
    icon: <Icon fontSize="small">schedule</Icon>,
    route: "/work-hours/*",
    href: "/work-hours",
    component: <WorkHours />,
    // children: [
    //   {
    //     link: "check",
    //     type: "link",
    //     name: "check",
    //     auth: [1000, 3000],
    //     key: "check",
    //     icon: <Icon fontSize="small">login</Icon>,
    //   },
    // ],
  },
  // {
  //   type: "divider",
  //   key: "divider",
  // },
  // {
  //   type: "hide",
  //   name: "editUsers",
  //   auth: [1000, 3000],
  //   key: "editusers",
  //   icon: <Icon fontSize="small">group</Icon>,
  //   route: "/edit_user",
  //   component: <EditUser />,
  // },

  // {
  //   type: "collapse",
  //   name: "department",
  //   auth: [3000],
  //   key: "department",
  //   icon: <Icon fontSize="small">group</Icon>,
  //   route: "/department",
  //   href: "/department",
  //   component: <Department />,
  // },

  {
    type: "collapse",
    name: "payment",
    auth: [1000],
    key: "payment",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/payment",
    href: "/payment",
    component: <Payment />,
  },
  // {
  //   type: "divider",
  //   key: "divider2",
  // },
  // {
  //   type: "collapse",
  //   name: "patients",
  //   auth: [1000, 3000],
  //   key: "patients",
  //   icon: <Icon fontSize="small">elderly</Icon>,
  //   route: "/patients/*",
  //   component: <Patients />,
  //   href: "/patients",
  //   children: [
  //     {
  //       link: "home",
  //       key: "p",
  //       type: "link",
  //     },
  //   ],
  // },
  // {
  //   type: "hide",
  //   name: "Tables",
  //   auth: [1000, 3000],
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  // {
  //   type: "hide",
  //   name: "Billing",
  //   auth: [2000],
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "hide",
  //   name: "RTL",
  //   auth: [2000],
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  // {
  //   type: "collapse",
  //   name: "Cars",
  //   auth: [1000, 3000],
  //   key: "car",
  //   public: false,
  //   icon: <Icon fontSize="small">directions_car</Icon>,
  //   route: "/car",
  //   component: <Cars />,
  // },
  // {
  //   type: "collapse",
  //   name: "shitfs",
  //   auth: [3000],
  //   key: "shitfs",
  //   public: false,
  //   icon: <Icon fontSize="small">directions_car</Icon>,
  //   route: "/shitfs",
  //   component: <Shift />,
  // },
  // {
  //   type: "hide",
  //   name: "Notifications",
  //   key: "notifications",
  //   auth: [2000],
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },

  // {
  //   type: "hide",
  //   name: "edit_patient",
  //   key: "edit_patient",
  //   auth: [1000, 3000],
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/edit_patient",
  //   component: <EditPatient />,
  // },
  // {
  //   type: "collapse",
  //   name: "Uhr kontrolle",
  //   key: "check",
  //   public: false,
  //   auth: [3000],
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/check",
  //   component: <CheckUsersWorkingHours />,
  // },
  {
    type: "hide",
    name: "Change password",
    key: "change_password",
    public: false,
    auth: [],
    icon: <Icon fontSize="small">person</Icon>,
    route: "/change_password",
    component: <ChangePassword />,
  },
  {
    type: "collapse",
    name: "shift_planner",
    key: "shift_planner",
    public: false,
    auth: [6000],
    icon: <Icon fontSize="small">person</Icon>,
    route: "/shift_planner",
    href: "/shift_planner",
    component: <ShiftPlanner />,
  },
  {
    type: "collapse",
    name: "Rooms",
    auth: [6000],
    key: "rooms",
    icon: <Icon fontSize="small">room</Icon>,
    route: "/rooms/*",
    component: <Rooms />,
    public: false,
    href: "/rooms",
    children: [
      {
        link: "add",
        type: "link",
        name: "add",
        auth: [3000],
        key: "career",
        icon: <Icon fontSize="small">login</Icon>,
      },
      {
        link: "room-types",
        type: "room-types",
        name: "Room types",
        auth: [3000],
        key: "room-types",
        icon: <Icon fontSize="small">login</Icon>,
      },
      // {
      //   link: "room-rate",
      //   type: "room-rate",
      //   name: "Room Rate",
      //   auth: [3000],
      //   key: "room-rate",
      //   icon: <Icon fontSize="small">login</Icon>,
      // },
    ],
  },
  {
    type: "collapse",
    name: "Room Types",
    auth: [6000],
    key: "roomTypes",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/room-types/*",
    component: <RoomTypes />,
    public: false,
    href: "/room-types",
    children: [
      {
        link: "add",
        type: "link",
        name: "add",
        auth: [1000, 3000],
        key: "career",
        icon: <Icon fontSize="small">login</Icon>,
      },
      {
        link: "rates",
        type: "link",
        name: "rates",
        auth: [6000],
        key: "rates",
        icon: <Icon fontSize="small">login</Icon>,
      },
    ],
  },
  {
    type: "hide",
    name: "Sign In",
    auth: [1000, 3000],
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
    public: true,
  },
  {
    type: "hide",
    name: "Confirm Code",
    key: "confirm-code",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/confirm-code",
    component: <NewPassword />,
    public: true,
  },
  // {
  //   type: "hide",
  //   name: "Sign Up",
  //   auth: [1000, 3000],
  //   key: "sign-up",
  //   public: true,
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
  {
    type: "hide",
    name: "Sign Up",
    key: "reset",
    auth: [1000, 3000],
    public: true,
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/reset",
    component: <ResetCover />,
  },
  {
    type: "collapse",
    name: "reservations",
    key: "reservations",
    auth: [6000],
    public: false,
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/reservations/*",
    href: "/reservations",
    component: <Reservations />,
    // children: [
    //   {
    //     link: "details",
    //     type: "link",
    //     name: "Reservation Details",
    //     auth: [3000],
    //     key: "details",
    //     icon: <Icon fontSize="small">group</Icon>,
    //   },
    // ],
  },
  {
    type: "collapse",
    name: "transactions",
    key: "transactions",
    auth: [2000],
    public: false,
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/transactions/*",
    href: "/transactions",
    component: <Transactions />,
    children: [
      {
        link: "edit",
        type: "link",
        name: "Delete Transaction",
        auth: [3000],
        key: "edit",
        icon: <Icon fontSize="small">group</Icon>,
      },
      {
        link: "add",
        type: "link",
        name: "home Transaction",
        auth: [3000],
        key: "add",
        icon: <Icon fontSize="small">group</Icon>,
      },
      {
        link: "byUser",
        type: "link",
        name: "filter Transaction",
        auth: [6000],
        key: "byUser",
        icon: <Icon fontSize="small">group</Icon>,
      },
    ],
  },
  {
    type: "collapse",
    name: "brands",
    key: "brands",
    auth: [6000],
    public: false,
    icon: <Icon fontSize="small">addLocationAltIcon</Icon>,
    route: "/brands/*",
    href: "/brands",
    component: <Brands />,
    children: [
      {
        link: "user-list",
        type: "link",
        name: "user list",
        auth: [3000],
        key: "user-list",
        icon: <Icon fontSize="small">group</Icon>,
      },
      {
        link: "settings",
        type: "link",
        name: "brand settings",
        auth: [3000],
        key: "settings",
        icon: <Icon fontSize="small">group</Icon>,
      },
    ],
  },
  {
    type: "collapse",
    name: "job applications",
    auth: [6000],
    key: "job-applications",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/jobs/*",
    href: "/jobs",
    component: <Jobs />,
    public: false,
    children: [
      {
        link: "add",
        type: "link",
        name: "add",
        auth: [1000, 3000],
        key: "career",
        icon: <Icon fontSize="small">login</Icon>,
      },
      // {
      //   link: "edit",
      //   type: "link",
      //   name: "edit",
      //   auth: [1000, 3000],
      //   key: "edit",
      //   icon: <Icon fontSize="small">login</Icon>,
      // },
    ],
  },
  {
    type: "divider",
    key: "divider3",
  },
  {
    type: "collapse",
    name: "feedback",
    key: "feedback",
    public: false,
    auth: [6000],
    icon: <Icon fontSize="small">feedbackIcon</Icon>,
    route: "/feedback/*",
    href: "/feedback",
    component: <Feedback />,
  },

  {
    type: "collapse",
    name: "Category",
    auth: [3000],
    key: "commerce",
    icon: <Icon fontSize="small">categoryIcon</Icon>,
    route: "/category/*",
    component: <Commerce />,
    public: false,
    href: "/category",
    children: [
      // {
      //   link: "add",
      //   type: "link",
      //   name: "add",
      //   auth: [3000],
      //   key: "career",
      //   icon: <Icon fontSize="small">login</Icon>,
      // },
      {
        link: "view",
        type: "view",
        name: "View",
        auth: [3000],
        key: "view",
        icon: <Icon fontSize="small">login</Icon>,
      },
      // {
      //   link: "room-rate",
      //   type: "room-rate",
      //   name: "Room Rate",
      //   auth: [3000],
      //   key: "room-rate",
      //   icon: <Icon fontSize="small">login</Icon>,
      // },
    ],
  },

  {
    type: "collapse",
    name: "Product",
    auth: [3000],
    key: "Product",
    icon: <Inventory2Icon />,
    route: "/product/*",
    component: <Product />,
    public: false,
    href: "/product",
    children: [
      // {
      //   link: "add",
      //   type: "link",
      //   name: "add",
      //   auth: [3000],
      //   key: "career",
      //   icon: <Icon fontSize="small">login</Icon>,
      // },
      {
        link: "view",
        type: "view",
        name: "View",
        auth: [3000],
        key: "view",
        icon: <Icon fontSize="small">login</Icon>,
      },
      // {
      //   link: "room-rate",
      //   type: "room-rate",
      //   name: "Room Rate",
      //   auth: [3000],
      //   key: "room-rate",
      //   icon: <Icon fontSize="small">login</Icon>,
      // },
    ],
  },
  {
    type: "collapse",
    name: "Offers",
    auth: [3000],
    key: "offers",
    icon: <Icon fontSize="small">starBorderIcon</Icon>,
    route: "/offers/*",
    component: <Offers />,
    public: false,
    href: "/offers",
    children: [],
  },
  {
    type: "divider",
    key: "divider5",
  },
  {
    type: "collapse",
    name: "subscription",
    auth: [3000],
    key: "subscription",
    icon: <Icon fontSize="small">subscriptionsIcon</Icon>,
    route: "/subscription/*",
    component: <Subscription />,
    public: false,
    href: "/subscription",
    // children: [
    //   {
    //     link: "home",
    //     type: "link",
    //     name: "home",
    //     auth: [3000],
    //     key: "career",
    //     icon: <Icon fontSize="small">login</Icon>,
    //   },
    //   {
    //     link: "view",
    //     type: "view",
    //     name: "View",
    //     auth: [3000],
    //     key: "view",
    //     icon: <Icon fontSize="small">login</Icon>,
    //   },
    // ],
  },
  {
    type: "collapse",
    name: "distributors",
    auth: [3000],
    key: "distributors",
    icon: <Icon fontSize="small">groups3Icon</Icon>,
    route: "/distributors/*",
    component: <Distributors />,
    public: false,
    href: "/distributors",
    // children: [
    //   {
    //     link: "home",
    //     type: "link",
    //     name: "home",
    //     auth: [3000],
    //     key: "career",
    //     icon: <Icon fontSize="small">login</Icon>,
    //   },
    //   {
    //     link: "view",
    //     type: "view",
    //     name: "View",
    //     auth: [3000],
    //     key: "view",
    //     icon: <Icon fontSize="small">login</Icon>,
    //   },
    // ],
  },
  {
    type: "collapse",
    name: "contactusInfo",
    auth: [3000],
    key: "contactusInfo",
    icon: <Icon fontSize="small">groups3Icon</Icon>,
    route: "/contactusInfo/*",
    component: <ContactusInfo />,
    public: false,
    href: "/contactusInfo",
    // children: [
    //   {
    //     link: "home",
    //     type: "link",
    //     name: "home",
    //     auth: [3000],
    //     key: "career",
    //     icon: <Icon fontSize="small">login</Icon>,
    //   },
    //   {
    //     link: "view",
    //     type: "view",
    //     name: "View",
    //     auth: [3000],
    //     key: "view",
    //     icon: <Icon fontSize="small">login</Icon>,
    //   },
    // ],
  },
  {
    type: "collapse",
    name: "clientMessages",
    key: "clientMessages",
    public: false,
    auth: [3000],
    icon: <Icon fontSize="small">feedbackIcon</Icon>,
    route: "/clientMessages/*",
    href: "/clientMessages",
    component: <GuestMessages />,
  },
  {
    type: "divider",
    key: "divider4",
  },

  {
    type: "collapse",
    name: "Users",
    auth: [3000],
    key: "users",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/users/*",
    href: "/users/",
    component: <Users />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    auth: [1000, 3000, 2000],
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",

    href: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Reports",
    auth: [6000],
    key: "work-hours-report",
    icon: <Icon fontSize="small">assessment</Icon>,
    route: "/work-hours-report",
    href: "/work-hours-report",
    component: <WorkHoursReport />,
    public: false,
  },
  // {
  //   type: "collapse",
  //   name: "Revenue Report",
  //   auth: [3000],
  //   key: "revenue-report",
  //   icon: <Icon fontSize="small">assessment</Icon>,
  //   route: "/revenue-report",
  //   href: "/revenue-report",
  //   component: <RevenueReport />,
  //   public: false,
  // },
  // {
  //   type: "collapse",
  //   name: "App Settings",
  //   auth: [3000],
  //   key: "appSettings",
  //   icon: <Icon fontSize="small">settings</Icon>,
  //   route: "/app-settings",
  //   href: "/app-settings",
  //   component: <AppSettings />,
  //   public: false,
  // },
];

export default routes;

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React, { useEffect } from "react";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import moment from "moment";
import { getAllMaterialVariables } from "../../../context";
function PrintableData({ rows, columns, printRef, date, totalHours, customValue }) {
  const { userName } = getAllMaterialVariables();
  return (
    <div style={{ width: "100%" }} ref={printRef} className={"printable-container-component"}>
      <MDBox mx={2} mt={-3} py={3} px={2}>
        <MDBox mt={3}>
          <MDTypography variant="h2" color="error" style={{ textAlign: "center" }}>
            Sipan Group
          </MDTypography>{" "}
        </MDBox>
        <MDTypography variant="h6" color="dark" textTransform={"capitalize"}>
          User: {userName}
        </MDTypography>{" "}
        <MDTypography variant="h6" color="dark">
          From:{moment(date?.startDate).format("YYYY-MM-DD")}
        </MDTypography>{" "}
        <MDTypography variant="h6" color="dark">
          To:{moment(date?.endDate).format("YYYY-MM-DD")}
        </MDTypography>{" "}
        <MDTypography variant="h6" color="dark">
          Date:{moment().format("YYYY-MM-DD HH:mm:ss")}
        </MDTypography>
      </MDBox>
      <MDBox py={3} px={2}>
        <table style={{ marginTop: "20px" }}>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index}>{column.Header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column, columnIndex) => (
                  <td key={columnIndex}>{renderCell(row, column)}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <MDBox py={2}>{customValue}</MDBox>
      </MDBox>
    </div>
  );

  // Render a cell based on the row and column data
  function renderCell(row, column) {
    if (column.accessor === "brandName") {
      return <MDTypography p={1}>{row.brandName}</MDTypography>;
    } else if (column.accessor === "dollar") {
      return <MDTypography p={1}>{row.dollar}</MDTypography>;
    } else if (column.accessor === "dinar") {
      return <MDTypography p={1}>{row.dinar}</MDTypography>;
    } else if (column.accessor === "action") {
      return <MDTypography p={1}>{row.action}</MDTypography>;
    } else {
      return "";
    }
  }
}

export default PrintableData;

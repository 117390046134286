import React from "react";
import MDBox from "../../components/MDBox";
import { BranchesContextProvider } from "../../context/brancheContext";
import { Route, Routes } from "react-router-dom";
import LazyLoading from "../../components/lazyLoading";

// Lazy loading components
const _Index = React.lazy(() => import("./_index"));
const BrandSetting = React.lazy(() => import("./brandSetting"));
const UsersBrand = React.lazy(() => import("./userBrand"));

//Branches Layout
const Index = (props) => {
  return (
    <LazyLoading>
      <BranchesContextProvider>
        <Routes>
          <Route index element={<_Index />} />
          <Route path="settings" element={<BrandSetting />} />
          <Route path="user-list" element={<UsersBrand />} />
        </Routes>
      </BranchesContextProvider>
    </LazyLoading>
  );
};

export default Index;
